<template>
  <div id="ColumnChartPerc">
    <apexchart type="bar" ref="chart1" width="95%" :height="height"
      :options="chartOptions" :series="series" class="chart-wrapper">
    </apexchart>
  </div>
</template>

<style scoped>
div.chart-wrapper {
  display: flex;
  align-items: center;
  justify-content: center
}
</style>

<script>
export default {
  name: 'ColumnChartPerc',
  props: ['data','categories','colors','tipo_data','nombre_serie','titulo','height','subtitulo'],
  data() {
    return {
      series: [{
        name: '',
        data: []
      }],
      chartOptions: {
        plotOptions:{
          bar: {
            borderRadius: 5,
            distributed: true,
            dataLabels: {
              position: 'top'
            }
          }
        },
        colors: [],
        dataLabels: {
          enabled: true,
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          },
          formatter: function (val, w) {
              var datos = w['w'].globals.series[0];
              var total = 0;
              datos.forEach(function(dato){
                  total += dato;
              })

              var porcentaje = parseFloat(((val/total)*100).toFixed(2));

              if(porcentaje != 0.00){
                  return porcentaje+'%';
              }else{
                  return '0%';
              }
          },
        }
      },
      xaxis: {
        type: 'category',
        categories: [],
        position: 'bottom',
        axisBorder: {
          show: false
        },
        axisTicks: {
          show:false
        },
        labels: {
          style: {
            fontSize: '13px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 400,
          }
        }
      },
      chart: {
        toolbar: {
          show: false, 
          tools: {
            download: true,
            pan: true,
          }
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val ;
          }
        }
      },
      title: {
        text: '',
      },

      grid:{
        show:false
      }
      

    }
  },

  mounted(){
    
  },

  computed: {
    updateData(){
      return this.data;
    }
  },

  watch: {
    updateData(){
      this.$refs.chart1.updateOptions({
        series: [{
          name: this.nombre_serie,
          data: this.data,
        }],
        xaxis: {
          categories: this.categories,
        },
        colors: this.colors,
       
        // tipo_dato: this.tipo_dato,
        // serie: this.serie,
        title: {
          text: this.titulo,
          align: 'left',
          style: {
            fontSize:  '19px',
            fontWeight:  'light',
            fontFamily:  undefined,
            color:  '#263238'
          },
          margin:-15,
          offsetY:5
        },
        subtitle: {
          text:this.subtitulo,
          align: 'left',
          style: {
            fontSize:  '15px',
            fontWeight:  'light',
            fontFamily:  undefined,
            color:  '#263238'
          },
        },
        legend: {
          show:false
      },
      grid:{
        show:false
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: false,
          formatter: function (val) {
            return val ;
          }
        }
      },
      chart: {
        toolbar: {
          show: true, 
          tools: {
            download: true,
            pan: true,
          }
        }
      },
      tooltip: {
        theme: 'dark',
        onDatasetHover: {
          highlightDataSeries: true,
        },
        custom: function({series,seriesIndex,dataPointIndex,w,opt}){
          function formatCL(val){
            var valor = val.toLocaleString();
            var largo_string = valor.length
            var i;
            var stringFormat = ''; 
            for(i of valor){
              if( i === '.' ){
                i = ',';
                stringFormat+=i;
              }
              else if(i ===','){
                i = '.';
                stringFormat+=i;
              }
              else{
                stringFormat+=i;
              }
            }
            return stringFormat;
          };

          function porcentaje(valor,total){
            return parseFloat(((valor / total)*100).toFixed(2));
          }

          var tipo_dato = w.config.tipo_dato;
          var serie = w.config.serie;

          var total = 0;
          var datos = series[0];
          datos.forEach(function(i){
            total += i
          })
          
          var porcentaje = porcentaje(datos[dataPointIndex],total);

        

          var region = w.globals.labels[dataPointIndex];
          var valor = formatCL(datos[dataPointIndex]);
          var tipo;

          if (tipo_dato === 'Dólares' || 
              tipo_dato === 'Miles de millones de pesos' ||
              tipo_dato === 'Miles de dólares FOB' ||
              tipo_dato === 'Millones'){
            valor = '$'+valor;
          }

    
          return (
            '<div style="margin: 0 auto;width: 300px; height: 170px;opacity:1; background-color: #263238;">'+
            '<h2 class="font-weight-light text-left" style="margin-top:10px; margin-left:15px">'+region+'</h2>'+
            '<h4 class="font-weight-light text-left" style="margin-top:10px;margin-left:15px;font-size:16px">'+serie+'</h4>'+
            '<h3 class="font-weight-medium text-left" style="margin-top:2px;margin-left:15px">'+valor+' '+tipo_dato+'</h3>'+
            '<h4 class="font-weight-light text-left"  style="margin-top:5px;margin-left:15px;font-size:16px">% respecto a nivel país</h4>'+
            '<h3 class="font-weight-medium text-left" style="margin-top:2px;margin-left:15px">'+porcentaje+'%</h3>'+
            '</div>'
          );
        },
      },
      tipo_dato: this.tipo_data,
      serie: this.nombre_serie
        
      })
  
    }
  },

  
}
</script>