<template >
	<div id="View1Region">
    <div id="header" >
      <v-app-bar color="light-blue darken-2" class="rounded" flat dark>
        <h2 class="font-weight-medium">
          {{url_api.nombre_data}} Regional
        </h2>
        <v-col cols="2">
          <v-select
          v-model="anio"
          :items ="anios"
          label ="Año"
          hide-no-data
          hide-details
          class="mx-2"
          flat
          solo-inverted
          dense
          v-on:change="cambiarAnio"
          ></v-select>
        </v-col>
        <v-col cols="2" style="margin-left:-20px;">
          <v-select
          v-model="tipo_valor"
          :items ="url_api.tipos_valor"
          label ="Tipo de Valor"
          item-text="text"
          item-value="value"
          hide-no-data
          flat
          solo-inverted
          class="mx-2"
          dense
          hide-details
          v-on:change="cambiarTipoValor"
          ></v-select>
        </v-col>
      <v-spacer/>
        <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
           
            dark
            v-bind="attrs"
            v-on="on"
          >
            mdi-information-outline
          </v-icon>
        </template>
        <span>Fuente: {{url_api.fuente}}</span>
      </v-tooltip>
      </v-app-bar>    
    </div>
    <div id="body" style="margin-top:-5px;">
      <v-row>
        <v-col cols="3">
           <v-card flat >
            <v-card-title class="justify-center">
              <h3 class="font-weight-medium">País</h3>
            </v-card-title>
            <v-card-text class="text-center" style="margin-top:-10px;">
              <h3 class="font-weight-light mb-4" >{{tipo_dato}}</h3>
              <h1 class="font-weight-medium" style="display:inline">
              {{total_pais}}
              </h1>
              <h3 class="font-weight-medium" style="display:inline">{{unidad}}</h3>
            </v-card-text>
          </v-card>
          <v-card flat class="mt-2">
            <v-card-title class="justify-center">
              <h3 class="font-weight-medium">{{nombre_region}}</h3>
            </v-card-title>
            <v-card-text class="text-center" style="margin-top:-10px;">
              <h3 class="font-weight-light mb-4" >{{tipo_dato}}</h3>
              <h1 class="font-weight-medium" style="display:inline">
              {{total_region}}
              </h1>
              <h3 class="font-weight-medium" style="display:inline">{{unidad}}</h3>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="9" >
          <v-card flat height="280" > 
            <v-card-text>
              <column-chart-perc :data="data_graph1" :categories="labels_graph1" :colors="colors"
                :titulo="tipo[0].toUpperCase() + tipo.slice(1)+' Nacional - '+anio" :nombre_serie="tipo" :tipo_data="unidad" :height="240" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row style="margin-top:-16px;">
        <v-col cols="3">
          <v-card flat height="100%">
            <v-card-title class="justify-center">
              <h3 class="font-weight-light">Datos generales</h3>
            </v-card-title>
            <v-card-text v-if="max_tipo_punto.length != 0" style="margin-top:-40px;" class="text-center" >
                  <h3 class=" font-weight-light mt-8">Tipo de punto con mayor {{tipo}} - {{anio}}</h3>
                  <h3 class="font-weight-medium mt-2">{{max_tipo_punto.tipo}}</h3>
                  <h3 class="font-weight-light mt-2 mb-2">Total {{tipo_dato}}</h3>
                  <h2 class="font-weight-medium mt-2" style="display:inline">{{formatCL(max_tipo_punto.valor)}}</h2>
                  <h4 class="font-weight-medium mt-2" style="display:inline"> {{unidad}}</h4>
                  <PieChart :data="data_graph2" class="mt-6" :labels="labels_graph2" :colors="colors_graph2" :serie="url_api.nombre_data" 
                    :tipo_dato="unidad" :pos="'bottom'" :offset="0" :width="380"/>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="9" class="mt-1">
        <v-card flat>
          <Map :data="data_map" :data_puntos="data_puntos_region" :lat='latitud' :lon='longitud' :codregion="cod_region" :unidad="unidad"></Map>
        </v-card>
      </v-col>
    </v-row>
  </div>
<!-- <loadingData :overlay="overlay" style="z-index: 9999 !important; "/>
 --></div>
</template>


<script>
import axios from 'axios';
const API_URL = 'https://api.datari.net/datari/data/';
import ColumnChart from '../../components/charts/ColumnChart.vue';
import PieChart from '../../components/charts/PieChart4.vue';
import Map from '../../components/maps/IEMap1.vue';
import Map3D from '../../components/maps/IEMap2.vue';
import ColumnChartPerc from '../../components/chartsV2/ColumnChartPerc.vue';


export default {
	components: {
		PieChart,
		ColumnChart,
    Map,
    Map3D,
    ColumnChartPerc
	},
	name: 'View1Regional',
	props: ['url_api','region','menulateral'],
	data: () => ({
    overlay: true,
		// colors: [
		// 	"#000099","#FBC02D","#CC3399",'#003333', '#546E7A', '#FF0033', '#999966', '#669933',
		// 	'#0000CC', '#6600CC',"#006633",'#f48024', '#CC0033',"#CCCC00","#66BB6A"
		// ],
    colors: [],
		tab: null,
		anios: [],
		anio: null,
		tipo_valor: null,
		tipo: null,
		total_pais: null,
		total_region: null,
		participacion_region: null,
		tipo_dato: null,
		unidad: null,
		nombre_region: null,
		nombre_serie: null,
    data_graph1: [],
    labels_graph1:[],
    data_graph2: [],
    labels_graph2:[],
    colors_graph2: [],
    latitud:null,
    longitud: null,
    cod_region: null,
    data_map: [],
    headerSize: 82,
    data_puntos_region: [],
    max_tipo_punto:  []
	}),
	created(){
		this.tipo_valor = this.url_api.tipos_valor[0].value;
		this.anios = this.url_api.anios.sort((a, b) => b - a);
		this.anio = this.anios[0];
		this.tipo = this.url_api.tipo;
		this.crearDataRegion();
    this.sleep(5000).then(() => {this.overlay=false;});
   
    document.documentElement.style.overflow = 'hidden';
  
    // document.documentElement.style.overflow = 'hidden';
	},
	computed: {
		update(){
			return this.region,this.menulateral,this.url_api;
		}
	},
	watch: {
		update(newView, oldView){
      if(newView.tipo !== oldView.tipo){
        this.overlay=true;
        this.tipo = newView.tipo; 
        this.crearDataRegion();
        this.sleep(4000).then(() => {this.overlay=false;});
      }else{
        this.crearDataRegion();
      }
      this.HeaderSize();
		}
	},
	methods: {
		cambiarAnio(anio){
			this.anio = anio;
      this.crearDataRegion();
		},
		cambiarTipoValor(tipo){
			this.tipo_valor = tipo;
      this.crearDataRegion();
		},

    HeaderSize(){
      if(this.menulateral){
        this.headerSize = 82;
      }
      else{
        this.headerSize = 96;
      }
    },

    async getDataMap(){
      let response = await axios.get(
        'https://api.datari.net/datari/mapas/importacion_exportacion/'+'?tipo='+this.tipo+'&anio='+this.anio+
				'&tipo_dato='+this.tipo_valor+'&region='+this.region
      );

      this.data_map = response.data.results;
    },

    
		
    async crearDataRegion(){
			let response = await axios.get(
								API_URL+this.url_api.url_api+'?tipo='+this.tipo+'&anio='+this.anio+
								'&tipo_dato='+this.tipo_valor+'&region='+this.region
							);

      let data = response.data;
      

			let data_pais = response.data.data_pais;
			let data_region  = response.data.data_region;
      let data_puntos = response.data.data_region_puntos;

      let data_regiones = response.data.data_regiones;
      this.data_graph1 = this.getGraphicData(data_regiones,2).series;
      this.labels_graph1 = this.getGraphicData(data_regiones,2).categorias;
      this.colors = this.getGraphicData(data_regiones,2).colors;

			this.total_pais = data_pais.total_anual;
			this.tipo_dato = data_pais.unidad;
			this.total_region = data_region.total_anual;
			this.participacion_region = data_region.participacion_anual;
			this.nombre_region = data_region.nombre;
      this.latitud = data_region.lat;
      this.longitud = data_region.lon;
      this.cod_region = data_region.cod_region;
      this.data_graph2 = this.getGraphicData(data_puntos,3).series;
      this.labels_graph2 = this.getGraphicData(data_puntos,3).categorias;
      this.colors_graph2 = this.getGraphicData(data_puntos,3).colors;
      this.data_puntos_region = data_puntos;
      this.max_tipo_punto = data_puntos.reduce((a,b)=>a.valor>b.valor?a:b);
      this.getDataMap();

			if(this.tipo_valor == 'monto'){
				this.unidad = 'Dólares';
				this.total_pais = '$'+this.formatCL(this.total_pais);
				this.total_region = '$'+this.formatCL(this.total_region);
			}else{
				this.unidad = 'Toneladas';
				this.total_pais = this.formatCL(this.total_pais);
				this.total_region = this.formatCL(this.total_region);
			}
		},
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

		formatCL(val){
			var valor = val.toLocaleString();
			var largo_string = valor.length
			var i;
			var stringFormat = ''; 
			for(i of valor){
				if( i === '.' ){
				i = ',';
				stringFormat+=i;
				}
				else if(i ===','){
				i = '.';
				stringFormat+=i;
				}
				else{
				stringFormat+=i;
				}
			}
			return stringFormat;
		},

    getGraphicData(data,labelType){
      // Tipo 1 -> Años, Tipo 2 -> Regiones
      var element;
      var labels = [];
      var series = [];
      var colors = [];

      if(labelType == 1){
        for(element of data){
          if(!labels.includes(element.anio)){
            labels.push(element.anio);
          }
          if(this.tipo === this.url_api.tipos[0]){
            series.push(element.exportaciones_agricola);
          }
          else if(this.tipo === this.url_api.tipos[1]){
            series.push(element.exportaciones_forestal);
          }
          else if(this.tipo === this.url_api.tipos[2]){
            series.push(element.exportaciones_pecuario);
          }
          else if(this.tipo === this.url_api.tipos[3]){
            series.push(element.exportaciones_total);
          }
          
        }
      }
      else if(labelType == 2){
        for(element of data){
          if(!labels.includes(element.region.nombre)){
            if(element.region.nombre.includes('Arica')){
              var temp = ['Arica y','Parinacota']
              labels.push(temp);
            }else{
              labels.push(element.region.nombre);
            }

            if(element.region.id === this.region){
              colors.push('#1A5276');
            }
            else{
              colors.push("#B5B5B5");
            }
            series.push(element.data_region.total_anual);
          }
        }
      }

      else if(labelType == 3){
        for(element of data){
         if(!labels.includes(element.tipo)){
          labels.push(element.tipo);
          colors.push(element.color);
         }
         series.push(element.valor);
         
        }
      }
     
      return {
              'categorias': labels,
              'series': series,
              'colors': colors
             }
    },
	}
}
</script>