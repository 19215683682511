<template>
	<div id="LineChart-punto">
		<apexchart width="100%"  ref="chart1" type="line"  height="550" :options="options" :series="series" class="chart-wrapper"></apexchart>
		<br>
	</div>
</template>

<!-- Centrar Chart --->
<style scoped>
div.chart-wrapper {
	display: flex;
	align-items: center;
	justify-content: center
}
</style>

<script>
	export default {
		name: 'LineChart',
		props: ['data','labels','serie','titulo','subtitulo'],
		data() {
			return {
				
				options: {
					chart: {
						id: 'lineChart',
						toolbar:{
							show: true,
							tools: {
                download: true,
                selection: false,
                zoom: false,
                zoomin: false,
                zoomout: false,
                pan: false,
                reset:false
              }
						}
					},
					xaxis: {
						type: "category",
						categories: [0],
						style: {
							colors: '#fff',
							fontSize: '12px'
						},
						labels: {
							formatter: function(val){
								return val+' m2'
							}
						}

					},
					yaxis:{
            axisBorder: {
              show: true
            },
            axisTicks: {
              show: true,
            },
						labels: {
							show: true,
							offsetX: 0,
							formatter: function (val) {
								if(Number.isInteger(val)){
									return (val.toLocaleString()).replace(',','.')
								}
								else if(this.serie === 'Carga (T)'){
									return val.toLocaleString()
								}
								else{
									return val.toLocaleString()
								}
								
							}
						}
					},
					dataLabels: {
						enabled: false
					},
					stroke: {
						curve: 'smooth'
					},
					colors:['#F57C00'],
					tooltip: {
						theme: 'dark',
						enabled: true,
						y:{
							title:{
								formatter: function(val){
									return val;
								}
							}
						}
					},
          title:{
            text:'',
            align: 'left',
            style: {
              fontSize:  '22px',
              fontWeight:  'light',
              fontFamily:  undefined,
              color:  '#263238'
            },
          },
          subtitle:{
            text:'',
            align: 'left',
            style: {
              fontSize:  '18px',
              fontWeight:  'light',
              fontFamily:  undefined,
              color:  '#263238'
            },
          },
				},
			
				series: [{
					name: 'serie-1',
					data: []
				}],
				valores: [],
			
			}
		},
		mounted() {
			

		},

		computed: {
			item(){
				

				return this.data
			}
		},

		watch: {
			item(){

				this.cargar_categorias(this.labels);
				this.$refs.chart1.updateSeries([
				{
					data: this.data,
					name: this.serie
				}
						]);

        this.$refs.chart1.updateOptions({
          title: {
            text:this.titulo
          },
          subtitle: {
            text:this.subtitulo
          },
          yaxis:{
            axisBorder: {
              show: true
            },
            axisTicks: {
              show: true,
            },
          }
        })


			

				
				
			}
		},
		methods: {


			cargar_categorias(categorias){
			    var region;
			    var categoriesChart = this.options.xaxis.categories;

			    if(categoriesChart[0] == 0){
			    	console.log('s');
			    	categoriesChart.splice(0,1);
			    }
			    
			    for(region of categorias){
			        if(!categoriesChart.includes(region)){
			            categoriesChart.push(region);
			        }

			    }
			    
			    
			},
			crearData(){
				

				this.max = Math.max(...this.labels);
				this.min = Math.min(...this.labels);

				console.log(this.labels)
			

				if(this.labels.length < this.options.xaxis.categories.length){
					for(var d in this.options.xaxis.categories){
						if(!this.labels.includes(this.options.xaxis.categories[d])){
							this.options.xaxis.categories.splice(d,1);
						}
					}
					this.options.xaxis.categories.splice(0,1);
				}
				
				

				var i;
				for(i = this.min ; i <=  this.max; i++ ){
					if(!this.options.xaxis.categories.includes(i)){
						this.options.xaxis.categories.push(i);
					}
				}

				this.options.xaxis.categories.sort();
				

				console.log(this.options.xaxis.categories)
				this.$refs.chart1.updateSeries([{
						data: (this.data),
						name: this.serie
				}]);
			}
		}

		





		
	};
</script>
