<template>
	<div id="treeMap">
		<div id="chart">
      <apexchart type="treemap" ref="chart" width="100%" height="675" :options="chartOptions" :series="series" class="chart-wrapper"></apexchart>
      </div>
    </div>
</template>

<!-- Centrar Chart --->
<style scoped>
div.chart-wrapper {
display: flex;
align-items: center;
justify-content: center
}
</style>

<script>
import axios from 'axios';
const API_URL = 'https://api.datari.net/datari/data/';

export default {
	components: {

	},
	name: 'TreeMap',
	props: ['data'],
	data: () => ({
		series: [
            {
              data: [
                {x:'',y:''}
              ]
            }
          ],
          chartOptions: {
            legend: {
              show: false
            },
            chart: {
              height: 350,
              type: 'treemap',
              toolbar: {
                show:false
              }
            },
            // title: {
            //   text: 'Distibuted Treemap (different color for each cell)',
            //   align: 'center'
            // },
            colors: ["#000099","#FBC02D","#CC3399",'#003333', '#546E7A', '#FF0033', '#999966', '#669933', '#0000CC', '#6600CC',"#006633",
              '#f48024', '#CC0033',"#CCCC00","#66BB6A"
            ],
            
            plotOptions: {
              treemap: {
                distributed: true,
                enableShades: false
              }
            },
            dataLabels:{
              enabled:true,
              style:{
                  colors: ["#ECEFF1"],
                  fontSize: '19px',
                  fontFamily: 'Helvetica, sans-serif',
                  fontWeight: 545,
                }
            },
            labels: [],
            yaxis: {
              labels: {
                show: true,
              }
            },
            tooltip: {
              theme: 'dark',
              enableShades:false,
              custom: function({series,seriesIndex,dataPointIndex,w,opt}){
                var valor = series[seriesIndex][dataPointIndex];
                var total = 0;
                var datos = series[0];
                datos.forEach(function(i){
                  total += i
                })
                var porcentaje  = parseFloat(((valor / total)*100).toFixed(2));

                var tipo='';
                if(w.globals.categoryLabels[dataPointIndex] === 'Metropolitana'){
                  tipo = 'Region ';
                }else if(w.globals.categoryLabels[dataPointIndex] === 'Biobío' || w.globals.categoryLabels[dataPointIndex] === 'Maule' ){
                  tipo = 'Region del '
                }else{
                  tipo = 'Region de '
                }
                return (
                  '<div style="margin: 0 auto;width: 300px; height: 150px;opacity:1; background-color: #263238;">'+
                  '<h2 class="font-weight-light text-left" style="margin-top:10px; margin-left:15px">'+tipo + w.globals.categoryLabels[dataPointIndex]+'</h2>'+
                  '<h4 class="font-weight-light text-left" style="margin-top:10px;margin-left:15px;font-size:16px">Gasto en I+D</h4>'+
                  '<h3 class="font-weight-medium text-left" style="margin-top:2px;margin-left:15px">$'+(series[seriesIndex][dataPointIndex].toLocaleString()).replace(',','.')+' Millones</h3>'+
                  '<h4 class="font-weight-light text-left"  style="margin-top:5px;margin-left:15px;font-size:16px">% Gasto regional a nivel país</h4>'+
                        '<h3 class="font-weight-medium text-left" style="margin-top:2px;margin-left:15px">'+porcentaje+'%</h3>'+
                  '</div>'
                );
              },


              x: {
                show: false,
                
              },
              y: {
                show:true,
                title: {
                  formatter: function (val) {
                    return 'Gasto en I+D Región \n'+val
                  }
                }
              },
              
            },
            
          },
          totalAnual: 0,
          
	}),
	mounted(){
		console.log(this.data);
    
	},
	computed: {
		item(){
			return this.data;
		}
	},
	watch: {
		item(){

			this.series[0].data = this.procesar_data(this.data);

			this.$refs.chart.updateSeries([
			{
				data: this.procesar_data(this.data)
			}
			]);

		}
	},
	methods: {
		procesar_data(data){
			var region;
			var lista = [];
			for(region of data){
				var valor = {x: region.region.nombre, y: region.gasto}
        if(!this.chartOptions.labels.includes(region.region.nombre)){
            this.chartOptions.labels.push(region.region.nombre)
        }
				lista.push(valor);
			}
			
			lista.sort(function(a,b){
				if(a.y < b.y){
					return 1;
				}
				if(a.y > b.y){
					return -1;
				}
				return 0
			});
			return lista;

			
		},

		
	}
}

</script>