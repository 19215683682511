<template>
  <div id="FuenteDatoRegionView">
    <div v-if="url_api.tipo_vista_region !== null">
      <div v-if="url_api.tipo_vista_region === 'View1'">
        <View1Region :url_api="url_api" :region="region" :menulateral="ml" />
      </div>
      <div v-else-if="url_api.tipo_vista_region === 'View2'">
        <View2Region :url_api="url_api" :region="region" :regiones="regiones"/>
      </div>
      <div v-else-if="url_api.tipo_vista_region === 'View3'"> 
        <View3Region :url_api="url_api" :region="region"/>
      </div>
      <div v-else-if="url_api.tipo_vista_region === 'View4'">
        <View4Region :url_api="url_api" :region="region"/>
      </div>
      <div v-else-if="url_api.tipo_vista_region=== 'View5'">
        <View5Region :url_api="url_api" :region="region"/>
      </div>
      <div v-else-if="url_api.tipo_vista_region=== 'View6'">  
        <View6Region :url_api="url_api" :region="region"/>
      </div>
      <div v-else-if="url_api.tipo_vista_region=== 'View7'">
        <View7Region :url_api="url_api" :region="region"/>
      </div>
      <div v-else-if="url_api.tipo_vista_region=== 'View8'">
        <View8Region :url_api="url_api" :region="region"/>
      </div>
      <div v-else-if="url_api.tipo_vista_region=== 'View9'">
        <View9Region :url_api="url_api" :region="region"/>
      </div>
      <div v-else-if="url_api.tipo_vista_region=== 'View10'">
        <View10Region :url_api="url_api" :region="region"/>
      </div>
      <div v-else-if="url_api.tipo_vista_region=== 'View11'">
        <View11Region :url_api="url_api" :region="region"/>
      </div>
      <div v-else-if="url_api.tipo_vista_region=== 'View12'">
        <View12Region :url_api="url_api" :region="region"/>
      </div>
      <div v-else-if="url_api.tipo_vista_region=== 'View13'">
        <View13Region :url_api="url_api" :region="region"/>
      </div>
      <div v-else-if="url_api.tipo_vista_region === 'View14'">
        <View14Region :url_api="url_api" :region="region"/>
      </div>
      <div v-else-if="url_api.tipo_vista_region === 'View15'">
        <View15Region :url_api="url_api" :region="region"/>
      </div>
      <div v-else-if="url_api.tipo_vista_region === 'View16'">
        <View16Region :url_api="url_api" :region="region"/>
      </div>
       <div v-else-if="url_api.tipo_vista_region === 'View17'">
        <View17Region :url_api="url_api" :region="region"/>
      </div>
      <div v-else-if="url_api.tipo_vista_region === 'View18'">
        <View18Region :url_api="url_api" :region="region"/>
      </div>
       <div v-else-if="url_api.tipo_vista_region === 'View19'">
        <View19Region :url_api="url_api" :region="region"/>
      </div>
       <div v-else-if="url_api.tipo_vista_region === 'View20'">
        <View20Region :url_api="url_api" :region="region"/>
      </div>
      <div v-else-if="url_api.tipo_vista_region === 'View21'">
        <View21Region :url_api="url_api" :region="region"/>
      </div>
    </div>
    <div v-else>
       <h3 class="text-center font-weight-medium">
        Sin datos Regionales.
        Seleccione una comuna.
      </h3>
    </div>
    <loadingData :overlay="overlay" style="z-index: 9999"/>
  </div>
</template>

<script>

import View1Region from '../Region/View1';
import View2Region from '../Region/View2';
import View3Region from '../Region/View3';
import View4Region from '../Region/View4';
import View5Region from '../Region/View5';
import View6Region from '../Region/View6';
import View7Region from '../Region/View7';
import View8Region from '../Region/View8';
import View9Region from '../Region/View9';
import View10Region from '../Region/View10';
import View11Region from '../Region/View11';
import View12Region from '../Region/View12';
import View13Region from '../Region/View13';
import View14Region from '../Region/View14';
import View15Region from '../Region/View15';
import View16Region from '../Region/View16';
import View17Region from '../Region/View17';
import View18Region from '../Region/View18';
import View19Region from '../Region/View19.vue';
import View20Region from '../Region/View20.vue';
import View21Region from '../Region/View21.vue';
import loadingData from '../../components/view_template/loadingData.vue';


export default {
  components: {
    View1Region,
    View2Region,
    View3Region,
    View4Region,
    View5Region,
    View6Region,
    View7Region,
    View8Region,
    View9Region,
    View10Region,
    View11Region,
    View12Region,
    View13Region,
    View14Region,
    View15Region,
    View16Region,
    View17Region,
    View18Region,
    View19Region,
    View20Region,
    View21Region,
    loadingData
  },
  name: 'RegionFuenteDato',
  props: ['region','url_api','fuente','ml','regiones'],
  data: () => ({overlay:true}),
  created() {
     this.sleep(2500).then(() => {this.overlay=false;});
  },
  computed : {
    item(){ 
      return this.region,this.url_api
    }
  }, 
  watch: {
    item(){
      this.overlay = true;
      this.sleep(2500).then(() => {this.overlay=false;});
    }

  },
  methods: {
   sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
      },
}

}
</script>