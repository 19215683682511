<template>
	<div id="View19">
		<div id="header">
			<v-app-bar color="light-blue darken-2" class="rounded" flat dark>
        <h2 class="font-weight-medium">{{url_api.nombre_data}}</h2>
        <v-col cols=2>
          <v-select
            v-model="anio"
            :items ="url_api.anios"
            label ="Año"
            hide-no-data
            hide-details
            class="mx-2"
            flat
            solo-inverted
            dense
            v-on:change="cambiarAnio"
            >
          </v-select>
        </v-col>
        <v-spacer/>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
             
              dark
              v-bind="attrs"
              v-on="on"
            >
              mdi-information-outline
            </v-icon>
          </template>
          <span>Fuente: {{url_api.fuente}}</span>
        </v-tooltip>
      </v-app-bar>
		</div>
    <div id="body" style="margin-top:-5px;">
      <v-row>
        <v-col cols=5>
          <v-row style="margin-top:-10px;">
            <v-col>
              <v-card flat  height="105">
                <v-card-title >
                  <h4 class="font-weight-meidum">{{nombre_region}}</h4>
                </v-card-title>
                <v-card-text  style="margin-top: -15px;">
                  
                  <h1 class="font-weight-medium mt-4">
                    {{total_region}} <small>Vehículos</small>
                  </h1>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col>
              <v-card flat  height="105">
                <v-card-title >
                  <h4 class="font-weight-meidum">País</h4>
                </v-card-title>
                <v-card-text  style="margin-top: -15px;">
                  
                  <h1 class="font-weight-medium mt-4">
                    {{total_pais}} <small>Vehículos</small>
                  </h1>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-card flat style="margin-top:-5px;">
            <v-card-title>
              <h4 class="font-weight-medium ml-2">Cantidad de Vehículos según motor</h4>
            </v-card-title>
            <v-card-text class="text-center">
              <ArcPie 
              :labels="labels_graph2" 
              :data="data_graph2"
              :unidad="'Vehículos'"
              :colors="colors_graph2"
              :height="250"
              />
            </v-card-text>
          </v-card>
           <v-card flat class="mt-2">
            <v-card-text class="text-center">
              <MultiColumnChart :data="data_graph1" :data2="data2_graph1"
              :labels="labels_graph1" :tipo_data="'Cantidad de Vehículos'"
              :nombre_serie1="'Cataliticos'" :nombre_serie2="'No Cataliticos'" :unidad="'Vehículos'"
              :titulo="'Variación anual'"
              :subtitulo="'Vehículos Cataliticos y No Cataliticos'"
              />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols=7>
          
           <v-card flat >
            <v-card-text class="text-center">
              <Sankey style="margin-top: -10px;"
              :nodes="nodes" :links="links" :tipo_data="'Vehículos'"
              />
              
              
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
	</div>
</template>

<script>
import axios from 'axios';
const API_URL = 'https://api.datari.net/datari/data/';
import MultiColumnChart from '../../components/chartsV2/MultiColumnChart.vue';
import ArcPie from '../../components/chartsV2/ArcPie.vue';
import Sankey from '../../components/chartsV2/Sankey.vue';

export default {

  name: 'View19',
  props: [
    'url_api','region'
  ],
  components: {
    MultiColumnChart,
    ArcPie,
    Sankey
  },

  data () {
    return {
      anio: this.url_api.anios[0],
      total_region:0,
      nombre_region:'',
      total_pais: 0,
      data_graph1: [],
      data2_graph1:[],
      labels_graph1:['Bencina','Diesel','Gas','Eléctrico'],
      data_graph2:[],
      labels_graph2:[],
      colors_graph2:[],
      nodes: [],
      links: []
    }
  },
  mounted(){
    document.documentElement.style.overflow = 'hidden';
    this.get_data_region_segun_motor();
    this.get_data_region_cat_nocat();
    this.get_data_pais();

  },
  computed: {
    updateRegion(){
      return this.region;
    }
  },  
  watch: {
    updateRegion(){
      this.get_data_region_segun_motor();
      this.get_data_region_cat_nocat();
    }
  },
  methods: {

    async get_data_region_segun_motor(){
      let response = await axios.get(
        API_URL+this.url_api.url_apis[0]+'?region='+this.region+'&anio_data='+this.anio
      );

      let data = response.data.results[0];

      let data_region = data.region;
      this.total_region = this.formatCL(data.total);
      this.nombre_region = data_region.nombre;
      let data_grafico = this.getGraphicData(data,1);
      this.data_graph2 = data_grafico.series;
      this.labels_graph2 = data_grafico.categorias;
      this.colors_graph2 = data_grafico.colors;

      // this.nodes = response.data.nodes_sankey;
      // this.links = response.data.links_sankey;


      

            // let tipos_motor = [
      //   'bencina','diesel','gas','electrico'
      // ];


    },

    async get_data_region_cat_nocat(){
      let response = await axios.get(
        API_URL+this.url_api.url_apis[2]+'?region='+this.region
      );

      let data = response.data.results;
      let data_grafico = this.getGraphicData(data,2);
      this.data_graph1 = data_grafico.series;
      this.data2_graph1 = data_grafico.series2;
      this.labels_graph1 = data_grafico.categorias;
    },

    async get_data_pais(){
      let response = await axios.get(
        API_URL+this.url_api.url_apis[0]+'?anio_data='+this.anio
      );

      let data = response.data.total_pais;
      this.total_pais = this.formatCL(data);
      let data_pais = response.data;
      console.log(data_pais)
      this.nodes = data_pais.nodes_sankey;
      this.links = data_pais.links_sankey;
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    formatCL(val){
      var valor = val.toLocaleString();
      var largo_string = valor.length
      var i;
      var stringFormat = ''; 
      for(i of valor){
        if( i === '.' ){
          i = ',';
          stringFormat+=i;
        }
        else if(i ===','){
          i = '.';
          stringFormat+=i;
        }
        else{
          stringFormat+=i;
        }
      }
      return stringFormat;
    },

    getGraphicData(data,labelType){
      // Tipo 1 -> Años, Tipo 2 -> Regiones
      var element;
      var labels = [];
      var series = [];
      var series2= [];
      var colors = [];

      //Extraer data de una región
      if(labelType == 1){

        labels = ['Bencina','Diesel','Gas','Eléctrico'];
        series = [data.bencina,data.diesel,data.gas,data.electrico];
        colors = ["#138D75",'#616A6B','#FBC02D','#304FFE'];
      }

      else if(labelType == 2){
        for(element of data){
          let catalitico = element.catalitico;
          let no_catalitico = element.no_catalitico;
          let anio = element.anio_data;
          series.push(catalitico);
          series2.push(no_catalitico);
          labels.push(anio);
        }

      }

        // for(element of data){
        //   console.log(element);
          // if(!labels.includes(element.region)){

          //   if(element.region.includes('Arica')){
          //     var temp = ['Arica y','Parinacota']
          //     labels.push(temp);
          //   }else{
          //     labels.push(element.region);
          //   }
            
          // }
          // if(element.region === this.nombre_region){
          //   colors.push('#1A5276');
          // }
          // else{
          //   colors.push("#B5B5B5");
          // }

          // series.push(element.cantidad);
        // }
      

      
     
      return {
              'categorias': labels,
              'series': series,
              'colors': colors,
              'series2':series2
             }
    },



    cambiarAnio(anio){
      this.anio = anio;
      this.get_data_pais();
      this.get_data_region_segun_motor();
    }
  }
}
</script>

<style lang="css" scoped>
</style>