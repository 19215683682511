<template>
  <div id="View5Regional">
    <div id="header">
      <v-app-bar color="light-blue darken-2" class="rounded" flat dark>
        <h2 class="font-weight-medium">{{url_api.nombre_data}} Regional</h2>
        <v-col cols="1" class="ml-4">
          <v-select
          v-model="anio"
          :items ="anios"
          label ="Año"
          item-text="nombre"
          hide-no-data
          hide-details
          dense
          flat
          solo-inverted
          v-on:change="cambiarAnio"
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-select
          v-model="tipo"
          :items ="url_api.tipos"
          label ="Tipo"
          item-text="nombre"
          hide-no-data
          hide-details
          solo-inverted
          flat
          dense
          v-on:change="cambiarTipo"
          ></v-select>
        </v-col>
        <v-col cols="2">
          <v-select
          v-model="tipo_valor"
          :items ="url_api.tipos_valor"
          :label ="tipo_data"
          item-text="nombre"
          hide-no-data
          hide-details
          dense
          solo-inverted
          flat
          v-on:change="cambiarTipoValor"
          ></v-select>
        </v-col>
        <v-spacer/>
        <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
           
            dark
            v-bind="attrs"
            v-on="on"
          >
            mdi-information-outline
          </v-icon>
        </template>
        <span>Fuente: {{url_api.fuente}}</span>
      </v-tooltip>
      </v-app-bar>
    </div>
    <div id="body" style="margin-top:-5px;">
      <div v-if="data_fuente_region.length != 0">
        <v-row>
          <v-col cols="4">
            <v-card flat height="145">
              <v-card-title class="justify-center">
                <h3 class="font-weight-medium">País</h3>
              </v-card-title>
              <v-card-text class="text-center">
                <h3 v-if="tipo_valor === 'carga en toneladas'" class="mb-2 font-weight-light">Total {{tipo[0].toUpperCase() + tipo.slice(1)}} de Carga</h3>
                <h3 v-else class="font-weight-light mb-4">Total {{tipo[0].toUpperCase() + tipo.slice(1)}} de vehículos {{tipo_valor}} </h3>
                <h1 class="font-weight-medium" style="display:inline;">{{totalPaisAnual}}</h1>
                <h3 v-if="tipo_valor === 'carga en toneladas'" class="ml-1 font-weight-medium" style="display:inline;">Toneladas</h3>
                <h3 class="font-weight-medium ml-1" v-else style="display:inline;">Vehículos</h3>
              </v-card-text>
            </v-card>
            <v-card flat class="mt-2" height="145">
              <v-card-title class="justify-center">
                <h3 class="font-weight-medium">{{data_region.nombre}}</h3>
              </v-card-title>
              <v-card-text class="text-center">
                <h3 v-if="tipo_valor === 'carga en toneladas'" class="mb-2 font-weight-light">Total {{tipo[0].toUpperCase() + tipo.slice(1)}} de Carga</h3>
                <h3 v-else class="font-weight-light mb-4">Total {{tipo[0].toUpperCase() + tipo.slice(1)}} de vehículos {{tipo_valor}} </h3>
                <h1 class="font-weight-medium" style="display:inline;">{{totalAnualRegion}}</h1>
                <h3 v-if="tipo_valor === 'carga en toneladas'" class=" ml-1 font-weight-medium" style="display:inline;">Toneladas</h3>
                <h3 class="font-weight-medium ml-1" v-else style="display:inline;">Vehículos</h3>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card flat height="300"> 
              <v-card-text>
                <div v-if="tipo_valor === 'carga en toneladas'">
                  <column-chart-perc :data="data_grafico" :categories="regiones" :nombre_serie="'Total Carga '+tipo+' '+anio+' (toneladas)'"
                  :colors="colors_graph" :tipo_data="'Toneladas'" :height="270" :titulo="'Tráfico terrestre nacional - '+anio" />
                </div>
                <div v-else>
                  <column-chart-perc :data="data_grafico" :categories="regiones" :colors="colors_graph"
                  :titulo="'Tráfico terrestre nacional - '+anio" :nombre_serie="tipo+' vehículos ' +tipo_valor+' '+anio" 
                  :tipo_data="'Vehículos'" :height="270"/>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row style="margin-top:-15px;">
          <v-col cols="3">
            <v-card flat height="490">
              <v-card-title class="justify-center">
                <h3 class="font-weight-light">Pasos Fronterizos</h3>
              </v-card-title>
              <v-card-text class="text-center" style="margin-top:-10px;">
                <h3 v-if="tipo_valor === 'carga en toneladas'" class="font-weight-light">Mayor {{tipo}} {{tipo_valor}}</h3>
                <h3 v-else class="font-weight-light">Mayor {{tipo}} Vehículos {{tipo_valor}}</h3>
                <h2 class="font-weight-medium mt-2 mb-3">{{paso_max}}</h2>
                <h2 v-if="tipo_valor === 'carga en toneladas'" class=" ml-1 font-weight-light" >{{valor_max}} <small>Toneladas</small></h2>
                <h2 class="font-weight-light ml-1" v-else >{{valor_max}} <small>Vehículos</small></h2>

                <PieChart :data="data_graph2" class="mt-5" :labels="labels_graph2" :colors="colors_graph2" :serie="tipo" 
                    :tipo_dato="tipo_valor" :pos="'bottom'" :offset="0" :width="400"/>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="9">
            <v-card flat height="490">
              <Mapa  :data="data_mapa" :tipo_valor="tipo_valor" :cod_region="data_region.numero_region" :lat="data_region.lat" :lon="data_region.lon" />
            </v-card>
          </v-col>
        </v-row>
      </div>
      <div v-else class="text-center">
        No existen datos disponibles para la selección realizada.
      </div>
    </div>  
  </div>
</template>
<script>
import axios from 'axios';
const API_URL = 'https://api.datari.net/datari/data/';
import ColumnChartPerc from '../../components/chartsV2/ColumnChartPerc.vue';
import PieChart from '../../components/charts/PieChart4.vue';
import Mapa from '../../components/maps/MapTransporteTerritorial';

export default {
  components: {
    //semiCircleChart,
    ColumnChartPerc,
    PieChart,
    Mapa
  },
  name: 'View5Regional',
  props: ['url_api','region'],
  data: () => ({
    colors: [
      "#000099","#FBC02D","#CC3399",'#003333', '#546E7A', '#FF0033', '#999966', '#669933',
      '#0000CC', '#6600CC',"#006633",'#f48024', '#CC0033',"#CCCC00","#66BB6A"
    ],
    tab: null,
    anio: null,
    tipo_valor: null,
    tipo: null,
    data_fuente_region: [],
    data_region: [],
    data_fuente_regiones: [],
    data_grafico: [],
    colors_graph:[],
    regiones: [],
    totalesRegion: [],
    porcentajesRegion: [],
    totalPaisAnual: null,
    totalTraficoAnual: null,
    totalTraficoAnualRegion: null,
    totalAnualRegion: null,
    porcentajeAnualRegion: null,
    nombre_serie: null,
    anios: [],
    tipo_data: null,
    data_graph2: [],
    labels_graph2: [],
    colors_graph2: [],
    paso_max:null,
    valor_max:null,
    data_mapa: []

  }),
  created(){
    document.documentElement.style.overflow = 'hidden';
    this.anios = this.url_api.anios.sort((a, b) => b - a);
    this.anio = this.anios[0];
    this.tipo_valor = this.url_api.tipos_valor[0];
    this.tipo = this.url_api.tipos[0];

    if(this.tipo_valor === 'Carga en toneladas'){
      this.tipo_data = 'Carga';
    }else{
      this.tipo_data = 'Vehiculos';
    }
    
    this.crearDataRegion();
    this.crearDataAnualRegiones();
    this.getDataMap();

  },
  computed: {
    
    update(){
      return this.region;
    }
  },
  watch: {
    update(){
      this.crearDataAnualRegiones();
      this.crearDataRegion();
      this.getDataMap();
    }
  },
  methods: {
    cambiarAnio(anio){
      this.anio = anio;
      this.crearDataRegion();
      this.crearDataAnualRegiones();
      this.getDataMap();
    },
    cambiarTipoValor(tipo){
      this.tipo_valor = tipo;

      this.data_grafico = this.crearData(this.data_fuente_regiones);
      
      this.crearDataRegion();
      this.getDataMap();
    },

    cambiarTipo(tipo){
      this.tipo = tipo
      this.crearDataRegion();
      this.crearDataAnualRegiones();
      this.getDataMap();
      
    },

    async getDataMap(){
      let response = await axios.get(
        'https://api.datari.net/datari/mapas/transporte_territorial/?region='+this.region+
        '&anio='+this.anio+'&tipo='+this.tipo
      );
      this.data_mapa = response.data.results;
      console.log(this.data_mapa);
      
    },

    crearData(data){
  
      var dato_region;
      var lista = [];
      var labels = [];
      var colors = [];
      for(dato_region of data){
        var total_region = dato_region.datos_anuales_region.totales;
        if(!labels.includes(dato_region.region)){
           if(dato_region.region.includes('Arica')){
              var temp = ['Arica y','Parinacota']
              labels.push(temp);
            }else{
              labels.push(dato_region.region);
            }
        }

        if(dato_region.region === this.data_region.nombre){
          colors.push('#1A5276');
        }
        else{
          colors.push("#B5B5B5");
        }
        var valor;

        if(this.tipo_valor === 'particulares'){
          valor = total_region.cantidadVehParticulares
          if(valor == null){
            valor = 0;
          }
        }
        else if(this.tipo_valor === 'de pasajeros'){
          valor = total_region.cantidadVehPasajeros;
          if(valor == null){
            valor = 0;
          }
        }
        else if(this.tipo_valor === 'de carga'){
          valor = total_region.cantidadVehCarga;
          if(valor == null){
            valor = 0;
          } 
        }
        else if(this.tipo_valor === 'carga en toneladas'){
          valor = total_region.cargaRegion;
          
          if(valor == null){
            valor = 0;
          }else{
            var temp = parseFloat(valor.toFixed(2));
            valor = temp;
          }
        }
        lista.push(valor);
      }

      this.regiones = labels;
      this.colors_graph = colors;
      return lista;
    },

    crearDataAnualRegiones(){
      axios.get(API_URL+this.url_api.url_api+'?tipo='+this.tipo+'&anio='+this.anio).then(response => {
          this.data_fuente_regiones = response.data.datos_anuales_regiones;
          this.data_grafico = this.crearData(this.data_fuente_regiones);
      })
    },

    crearDataRegion(){

      axios.get(API_URL+this.url_api.url_api+'?region='+this.region+'&tipo='+this.tipo+'&anio='+this.anio).
      then(response => {
        if(response.data.count != 0){
          this.data_fuente_region = response.data.datos_anuales_region;
          let data_puntos = response.data.results;

          let data_graph2 = this.getGraphicData(data_puntos);
          
          this.data_graph2 = data_graph2.series;
          this.labels_graph2 = data_graph2.labels;
          this.colors_graph2 = data_graph2.colors;
          
          
          this.totalesPais = response.data.datos_anuales;
          this.totalesRegion = response.data.datos_anuales_region[0].totales;
          this.porcentajesRegion = response.data.datos_anuales_region[0].porcentajes;
          


          let max_paso;

          if(this.tipo_valor === 'particulares'){
            this.totalPaisAnual = this.formatearCL(this.totalesPais.totalAnual_v_particulares.toLocaleString());
            this.totalAnualRegion = this.formatearCL(this.totalesRegion.totalAnual_region_v_particulares.toLocaleString());
            this.porcentajeAnualRegion = this.porcentajesRegion.participacion_veh_particulares;
            max_paso = data_puntos.reduce(function(prev, current) {
              return (prev.v_particular > current.v_particular) ? prev : current
            });
            this.valor_max = this.formatearCL(max_paso.v_particular); 
          }
          else if(this.tipo_valor === 'de pasajeros'){
            this.totalPaisAnual = this.formatearCL(this.totalesPais.totalAnual_v_pasajeros.toLocaleString());
            this.totalAnualRegion = this.formatearCL(this.totalesRegion.totalAnual_region_v_pasajeros.toLocaleString());
            this.porcentajeAnualRegion = this.porcentajesRegion.participacion_veh_pasajeros;
            max_paso = data_puntos.reduce(function(prev, current) {
              return (prev.v_pasajero > current.v_pasajero) ? prev : current
            });
            this.valor_max = this.formatearCL(max_paso.v_pasajero); 
          
          }
          else if(this.tipo_valor === 'de carga'){
            this.totalPaisAnual = this.formatearCL(this.totalesPais.totalAnual_v_carga.toLocaleString());
            this.totalAnualRegion = this.formatearCL(this.totalesRegion.totalAnual__region_v_carga.toLocaleString());
            this.porcentajeAnualRegion = this.porcentajesRegion.participacion_veh_carga;
            max_paso = data_puntos.reduce(function(prev, current) {
              return (prev.v_carga > current.v_carga) ? prev : current
            });
             this.valor_max = this.formatearCL(max_paso.v_carga); 
           

          }
          else if(this.tipo_valor === 'carga en toneladas'){
            this.totalPaisAnual = this.formatearCL(this.totalesPais.totalAnual_carga.toLocaleString());
            this.totalAnualRegion = this.formatearCL(this.totalesRegion.totalAnual__region_carga.toLocaleString());
            this.porcentajeAnualRegion = this.porcentajesRegion.participacion_carga;
            max_paso = data_puntos.reduce(function(prev, current) {
              return (prev.carga > current.carga) ? prev : current
            });
            this.valor_max = this.formatearCL(max_paso.carga); 
          }
          
          this.paso_max = max_paso.punto_ingreso_salida.nombre;
        }else{
          this.data_fuente_region = [];
        }
        
      });

      axios.get(API_URL+'regiones/'+this.region+'/')
      .then(response =>{
        this.data_region = response.data;
      })
    },

    getGraphicData(data){
      let series = [];
      let labels = [];
      let colors =  [
        '#191970','#088F8F','#CD7F32','#8A9A5B','#E4D00A',
        '#FFBF00','#C04000','#E30B5C','	#BDB5D5'];
      let paso;

      for (paso of data){
        if(!labels.includes(paso.punto_ingreso_salida.nombre)){
          labels.push(paso.punto_ingreso_salida.nombre);

          if(this.tipo_valor === 'particulares'){
           series.push(paso.v_particular);
          }
          else if(this.tipo_valor === 'de pasajeros'){
            series.push(paso.v_pasajero);
          }
          else if(this.tipo_valor === 'de carga'){
            series.push(paso.v_carga);   
          }
          else if(this.tipo_valor === 'carga en toneladas'){
           series.push(paso.carga);
          }
          
        }
      }

      let data_final = {
        'series': series,
        'labels': labels,
        'colors': colors

      };
      return data_final;







    },



    formatearCL(valor1){
      var i;
      var valor = valor1.toLocaleString();
      var stringFormat = ''; 
      for(i of valor){
        if( i === '.' ){
          i = ',';
          stringFormat+=i;
        }
        else if(i ===','){
          i = '.';
          stringFormat+=i;
        }
        else{
          stringFormat+=i;
        }
      }
      
      return stringFormat;
    },
    
  }
}
</script>