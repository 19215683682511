<template>
  <div id="chart">
    <apexchart
      type="treemap"
      height="420"
      width="90%"
      :options="chartOptions"
      :series="series"
    	ref="chart1"
      class="chart-wrapper"
    ></apexchart>
  </div>
</template>

<style scoped>
div.chart-wrapper {
    display: flex;
    align-items: center;
    justify-content: center
}
</style>


<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: ["mydata","title","subtitle"],
  data() {
    return {
      series: [
        {
          data: [
            {
              x: " ",
              y: 0,
            },
            {
              x: "",
              y: 0,
            },
            {
              x: "",
              y: 0,
            },
            {
              x: "",
              y: 0,
            },
          ],
        },
      ],
      chartOptions: {
        tooltip: {
          onDatasetHover: {
            highlightDataSeries: false,
          },
          style: {
            fontSize: "15px",
            fontFamily: undefined,
          },
          y: {
            formatter: function (val) {
              return val + "%";
            },
          },
        },
        dataLabels: {
          style: {
            fontSize: "25px",
						fontFamily: 'Helvetica, sans-serif',
            fontWeight: 545,
          },
          textAnchor: "middle",
          offsetX: 0,
          offsetY: -5,
        },
        legend: {
          show: false,
        },
        chart: {
          height: 350,
          type: "treemap",
        },
        title: {
          text: "",
          align: "center",
          style: {
            fontSize: "14px",
            fontWeight: "bold",
            fontFamily: undefined,
            color: "#263238",
          },
        },
        plotOptions: {
          treemap: {
            distributed: true,
            enableShades: false,
          },
        },
        colors: ["#000099","#FBC02D","#CC3399",'#003333'],
      },
    };
  },

  computed: {
    Item() {
      return this.mydata;
    },
  },	
  watch: {
    Item() {
      // this.series = [
      //   {
      //     data: this.mydata,
      //   },
      // ];

			this.$refs.chart1.updateOptions({
        series: [
					{
						data: this.mydata
					}
				],
        title: {
          text: this.title,
          align: 'left',
          style: {
            fontSize:  '22px',
            fontWeight:  'light',
            fontFamily:  undefined,
            color:  '#263238'
          },
        },
        subtitle: {
          text: this.subtitle,
          align: 'left',
          style: {
            fontSize:  '17px',
            fontWeight:  'light',
            fontFamily:  undefined,
            color:  '#263238'
          },
        }
      })

    },
		// updateOptions(){
		// 	this.$refs.chart1.updateOptions({
        
  //       title: {
  //         text: this.title,
  //         align: 'left',
  //         style: {
  //           fontSize:  '22px',
  //           fontWeight:  'light',
  //           fontFamily:  undefined,
  //           color:  '#263238'
  //         },
  //       },
  //       subtitle: {
  //         text: this.subtitle,
  //         align: 'left',
  //         style: {
  //           fontSize:  '17px',
  //           fontWeight:  'light',
  //           fontFamily:  undefined,
  //           color:  '#263238'
  //         },
  //       }
  //     })
		// }
  }
  
};
</script>