<template>
    <div id="PieChart">
      <apexchart type="pie" width="550" ref="chart1" :options="options" :series="series" class="chart-wrapper"></apexchart>
    </div>
</template>

<!-- Centrar Chart --->
<style scoped>
div.chart-wrapper {
    display: flex;
    align-items: center;
    justify-content: center
}
</style>

<script>
    export default {
        name: 'PieChart',
        props: ['data','labels','serie','colors','tipo_dato'],
        data() {
            return {
                
                options: {
                    chart: {
                        id: 'pieChart',
                        toolbar:{
                            show: false
                        }
                    },
                    labels: [],
                    responsive: [{
                      breakpoint: 480,
                      options: {
                        chart: {
                          width: 300
                        },
                        legend: {
                          position: 'top'
                        }
                      }
                    }],
                    colors: [
                            "#E53935",'#33b2df', '#546E7A', '#d4526e', '#13d8aa',
                            '#A5978B', '#2b908f', '#f9a3a4', '#F39C12',"#BBDEFB",'#f48024',
                            '#69d2e7',"#4E342E","#66BB6A"
                    ],
                    tooltip: {
                        theme: 'dark',
                        enabled: true,
                        enableShades:false,
                        custom: function({series,seriesIndex,dataPointIndex,w,opt}){
                          var tipo_dato = w.config.tipo_dato;
                          var serie = w.config.serie;
                          function formatCL(val){
                            var valor = val.toLocaleString();
                            var largo_string = valor.length
                            var i;
                            var stringFormat = ''; 
                            for(i of valor){
                              if( i === '.' ){
                                i = ',';
                                stringFormat+=i;
                              }
                              else if(i ===','){
                                i = '.';
                                stringFormat+=i;
                              }
                              else{
                                stringFormat+=i;
                              }
                            }
                            return stringFormat;
                          };

                          function porcentaje(valor,total){
                            return parseFloat(((valor / total)*100).toFixed(2));
                          }

                          var total = 0;
                          var datos = series;
                          datos.forEach(function(i){
                            total += i
                          })
                          var porcentaje = porcentaje(series[seriesIndex],total);

                          total = formatCL(total);

                          var region = w.globals.labels[seriesIndex];
                          var valor = formatCL(series[seriesIndex]);
                          var tipo;
                          
                          if(w.globals.labels[seriesIndex] === 'Metropolitana'){
                            tipo = 'Región ';
                          }else if(w.globals.labels[seriesIndex] === 'Biobío' || w.globals.labels[seriesIndex]=== 'Maule' ){
                            tipo = 'Región del '
                          }else{
                            tipo = 'Región de '
                          }
                          return (
                            '<div style="margin: 0 auto;width: 300px; height: 150px;opacity:1; background-color: #263238;">'+
                            '<h2 class="font-weight-light text-left" style="margin-top:10px; margin-left:15px">'+region+'</h2>'+
                            '<h4 class="font-weight-light text-left" style="margin-top:10px;margin-left:15px;font-size:16px">'+serie+'</h4>'+
                            '<h3 class="font-weight-medium text-left" style="margin-top:2px;margin-left:15px">'+valor+' '+tipo_dato+'</h3>'+
                            '<h4 class="font-weight-light text-left"  style="margin-top:5px;margin-left:15px;font-size:16px">% respecto a nivel región</h4>'+
                            '<h3 class="font-weight-medium text-left" style="margin-top:2px;margin-left:15px">'+porcentaje+'%</h3>'+
                            '</div>'
                          );
                        },
                    },
                    legend: {
                      position: 'right'
                    },
                    tipo_dato: '',
                    serie: ''
                },
            
                series:  [],
            
            }
        },
        mounted() {
          this.$refs.chart1.updateOptions({
              series: this.data,
              labels: this.labels,
              tipo_dato: this.tipo_dato,
              serie: this.serie
            })
        },

        computed: {
            item(){
              return this.data
            }
        },

        watch: {
          item(){
            this.$refs.chart1.updateOptions({
              series: this.data,
              labels: this.labels,
              tipo_dato: this.tipo_dato,
              serie: this.serie
            })
          
          } 
      }  
    };
</script>
