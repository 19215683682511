<template>
  <div>
    <v-chip v-for="tipo in tipos" dark :key="tipo.tipo"  class="mr-2 mb-4 mt-2" :color="tipo.color" @click="hideTipo(tipo.tipo)" >{{tipo.tipo}}</v-chip>
    <div id="mapContainer" class="basemap"></div>
  </div>
</template>

<style scoped>
.basemap {
  width: 100%;
  height: 400px;
}
</style>

<style>
.mapboxgl-popup {
max-width: 400px;
font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
}
</style>

<script src='https://npmcdn.com/@turf/turf/turf.min.js'></script>

<script>
import mapboxgl from "mapbox-gl";
export default {
    props: ['data','lat','lon','codregion','unidad','data_puntos'],
    name: "IEMap3D",
    data() {
      return {
        accessToken: 'pk.eyJ1Ijoiam9zZW1jaGF2ZXpnIiwiYSI6ImNranloODJlZzAzeW4yd2xxbjQ2cTJ2aWEifQ.WQZWm73tdhtI79GUZTY-hQ',
        layers: [],
        map: null,
        tipos: [],
        tipos_puntos: []
      }
    },
    mounted(){
      this.tipos = this.data_puntos;
      mapboxgl.accessToken = this.accessToken;
      new mapboxgl.Map({
        container: "mapContainer",
        style: "mapbox://styles/mapbox/streets-v11",
        center: [0,0],
        zoom: 3,
     });
    },
    computed: {
      updateItem(){
        return this.data;
      }
    },
    watch: {
      updateItem(){
        var temp = this.data_puntos;
        this.tipos = temp;
        mapboxgl.accessToken = this.accessToken;
        var zoom_region;
        if(this.codregion === 15){
          zoom_region = 7.3;
        }
        else{
          zoom_region = 5;
        }

        this.map = new mapboxgl.Map({
          container: "mapContainer",
          style: "mapbox://styles/mapbox/light-v10",
          center: [this.lon,this.lat],
          zoom: zoom_region,
        });
        if(this.data.length != 0){
          this.regionLayer(this.map,this.data);
        }

      }
    },
    methods: {

      selectTipo(tipo){
        for(var dato in this.tipos){
          if(this.tipos[dato].tipo == tipo){
            return dato;
          }
        }
      },

      hideTipo(tipo){
        var visibility = this.map.getLayoutProperty(tipo, 'visibility');
        var indexTipo = this.selectTipo(tipo);
        if (visibility === 'visible') {
          this.map.setLayoutProperty(tipo, 'visibility', 'none');
          this.tipos[indexTipo].color = '#B5B5B5';
        } else {
          this.map.setLayoutProperty(tipo, 'visibility', 'visible');
          console.log(this.map.getPaintProperty(tipo,'circle-color'));
          this.tipos[indexTipo].color = this.map.getPaintProperty(tipo,'circle-color');
        }
      },
      regionLayer(mapa,data){
        function isVisible(mapa,layer) {
          return  mapa.getLayoutProperty(layer, 'visibility');
        }

        var cod_region = this.normalizarCodigo(this.codregion);
        mapa.on('load',function(){

          mapa.addSource('map_regiones', {
            type: 'vector',
            url: 'mapbox://josemchavezg.ckluzc93b32jp28nq05ua9e3e-12lxz',
          },
          'country-label',
          'state-label'
          );

          mapa.addSource('map_puntos', {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: data
            }
          }
          
          );
          
          if(this.codregion != 12){
            mapa.addLayer({
              id:'map_region',
              source: 'map_regiones',
              'source-layer': 'region01',
              type: 'fill',
              paint: {
                'fill-color': '#5D6D7E',
                'fill-opacity': 0.4,
              },
              "filter": ["==", "codigo_region", cod_region]
            });

            mapa.addLayer({
              id:'PASO FRONTERIZO',
              source:'map_puntos',
              type: 'circle',
              layout: {
                'visibility':'visible'
              },
              paint: {
                'circle-radius': [
                  'step',
                  ['get','participacion'],
                  5,
                  23,
                  15,
                  40,
                  22
                ],
                'circle-color':  '#E53935',
                'circle-stroke-width': 1,
              },
              "filter": ['all',["==", "tipo_punto", 'PASO FRONTERIZO']]
            });

            mapa.addLayer({
              id:'AEROPUERTO',
              source:'map_puntos',
              type: 'circle',
              layout: {
                'visibility':'visible'
              },
              paint: {
                'circle-radius': [
                  'step',
                  ['get','participacion'],
                  5,
                  23,
                  15,
                  40,
                  20
                ],
                'circle-color':  '#F1C40F',
                'circle-stroke-width': 1,
              },
              "filter": ["==", "tipo_punto", 'AEROPUERTO']
            });

            mapa.addLayer({
              id:'PUERTO',
              source:'map_puntos',
              type: 'circle',
              layout: {
                'visibility':'visible'
              },
              paint: {
                'circle-radius': [
                  'step',
                  ['get','participacion'],
                  5,
                  23,
                  15,
                  40,
                  20
                ],
                'circle-color': '#42A5F5',
                'circle-stroke-width': 0.8,
              },
              "filter": ["==", "tipo_punto", 'PUERTO']
            });

            mapa.addLayer({
              id:'TREN',
              source:'map_puntos',
              type: 'circle',
              filter: ['all',["==", "tipo_punto", 'TREN']],
              layout: {
                'visibility': 'visible'
              },
              paint: {
                'circle-radius': [
                  'step',
                  ['get','participacion'],
                  5,
                  23,
                  15,
                  53,
                  20
                ],
                'circle-color': '#27AE60',
                'circle-stroke-width': 1,
              },
              
            });

            mapa.on('mouseenter', 'Paso Fronterizo', function () {
              mapa.getCanvas().style.cursor = 'pointer';
            });
            
            // Change it back to a pointer when it leaves.
            mapa.on('mouseleave', 'mapa_puntos', function () {
              mapa.getCanvas().style.cursor = '';  
            });
          }

          mapa.scrollZoom.disable();

          mapa.addControl(new mapboxgl.NavigationControl());
          mapa.addControl(new mapboxgl.FullscreenControl());


          var toggleableLayerIds = ['PASO FRONTERIZO','AEROPUERTO','PUERTO','TREN']; 
          var clickedLayer = toggleableLayerIds[0];
         
          
          for (var i = 0; i < toggleableLayerIds.length; i++) {
            var layers = document.getElementById('menu');
            var id = toggleableLayerIds[i];
            
            mapa.on('click', id, function (e) {
              var data_descrp = '<h3 class="mb-2">'+e.features[0].properties.nombre+'</h3>'+
                '<div class="text-left">'+e.features[0].properties.tipo_dato+': '+e.features[0].properties.valor+'</div>'+
                '<div class="text-left">'+e.features[0].properties.tipo_valor+'</div>'+
                '<div class="text-left">Año : '+e.features[0].properties.año+'</div>'+
                '<div class="text-left">Tipo: '+e.features[0].properties.tipo_punto+'</div>'+
                '<div class="text-left">Comuna: '+e.features[0].properties.comuna+'</div>'+
                '<div class="text-left">Región: '+e.features[0].properties.region+'</div>';
                
              new mapboxgl.Popup()
              .setLngLat(e.lngLat)
              .setHTML(data_descrp)
              .addTo(mapa);
            });

            mapa.on('mouseenter',id, function () {
              mapa.getCanvas().style.cursor = 'pointer';
            });
            
            // Change it back to a pointer when it leaves.
            mapa.on('mouseleave',id, function () {
              mapa.getCanvas().style.cursor = '';  
            });


            // var link = document.createElement('a');
            // link.href = '#';
            // link.className = 'active';
            // link.textContent = id;

            
            // link.onclick = function (e) {
            //   var clickedLayer = this.textContent;
            //   e.preventDefault();
            //   e.stopPropagation();
              
            //   var visibility = mapa.getLayoutProperty(clickedLayer, 'visibility');
              
            //   // toggle layer visibility by changing the layout object's visibility property
            //   if (visibility === 'visible') {
            //   mapa.setLayoutProperty(clickedLayer, 'visibility', 'none');
            //     this.className = '';
            //   } else {
            //     this.className = 'active';
            //   mapa.setLayoutProperty(clickedLayer, 'visibility', 'visible');
            //   }
            // };

            // layers.appendChild(link);
            
            
            
          }
        });

        
       
        

      },

      normalizarCodigo(cod){
        var temp = String(cod).length;
        var cod_region;

        if(temp == 1){
          if(cod == 8){
            cod_region = '08';
          }
          else{
            cod_region = '0000'+String(cod);
          }
        }
        else if(temp == 2){
          if(cod == 16){
            cod_region = String(cod);
          }
          else{
            cod_region = '000'+String(cod);
          }
        }
        return cod_region;
      },

    },

  
  

}
</script>