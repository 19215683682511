<template>
    <div>
      <div id="header">
       <v-app-bar color="light-blue darken-2" class="rounded" flat dark>
        <h2 class="font-weight-medium">{{url_api.nombre_data}}</h2>
          <v-col cols="2">
          <v-select
          v-model="anio"
          :items ="url_api.anios"
          label ="Año"
          hide-no-data
          hide-details
          class="mx-2 my-2"
          solo-inverted
          flat
          dense
          v-on:change="cambiarAnio"
          ></v-select>
        </v-col>
        <v-spacer/>
        <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
           
            dark
            v-bind="attrs"
            v-on="on"
          >
            mdi-information-outline
          </v-icon>
        </template>
        <span>Fuente: {{url_api.fuente}}</span>
      </v-tooltip>
    </v-app-bar>
      </div>
      <div style="margin-top:-5px;" id="body">
        <v-row>
          <v-col cols=4>
            <v-card flat >
              <v-card-title class="justify-center">
                <h3 class="font-weight-medium">País</h3>
              </v-card-title>
              <v-card-text class="text-center" style="margin-top:-10px;">
                <h3 class="font-weight-light">Cantidad de personas en situación de pobreza</h3>
                <h1 class="font-weight-medium mt-4">{{cantidad_personas_pais}} <small>Personas</small></h1>
              </v-card-text>
            </v-card>

            <v-card flat class="mt-2" >
              <v-card-title class="justify-center">
                <h3 class="font-weight-medium">{{data_region.nombre}}</h3>
              </v-card-title>
              <v-card-text class="text-center" style="margin-top:-10px;">
                <h3 class="font-weight-light">Cantidad de personas en situación de pobreza</h3>
                <h1 class="font-weight-medium mt-4">{{cantidad_personas_region}} <small>Personas</small></h1>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card flat height=272>
              <v-card-text>
                <column-chart-perc :data="data_graph1" :categories="labels_graph1" :colors="colors_graph1"
                :titulo="'Cantidad de personas en situación de pobreza'" :subtitulo="'Regional - '+anio" :nombre_serie="'Cantidad'" :tipo_data="'personas'" :height="230" />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row style="margin-top:-15px">
          <v-col cols=12>
            <v-card flat height=510>
              <v-card-text>
                <v-row>
                  <v-col>
                    <TreeMap :data="data_graph2" :colors="colors_graph2" :height="470"
                      :titulo="'Comparación Comunal - Índice multidimensional'" :unidad="'Personas'" :serie="'Índice de personas en situación de pobreza'"/>
                  </v-col>
                  <v-col cols=4 class="text-center">
                    <div style="display:flex; justify-content:center; align-items:center;margin-top:15%">
                    <div >
                      <h3 class="font-weight-light">Cantidad de personas en situación de pobreza</h3>
                      <v-row class="mt-2">
                        <v-col>
                          <h3 class="font-weight-medium">Mayor cantidad</h3>
                          <h2 class="font-weight-light mt-2">{{comuna_max_personas_region}}</h2>
                          <h2 class="font-weight-medium mt-2">{{cantidad_max_personas_region}}</h2>
                        </v-col>
                        <v-col>
                          <h3 class="font-weight-medium">Menor cantidad</h3>
                          <h2 class="font-weight-light mt-2">{{comuna_min_personas_region}}</h2>
                          <h2 class="font-weight-medium mt-2">{{cantidad_min_personas_region}}</h2>
                        </v-col>
                      </v-row>
                      <h3 class="font-weight-light mt-8">Índice de personas en situación de pobreza</h3>
                      <v-row class="mt-2">
                        <v-col>
                          <h3 class="font-weight-medium">Mayor índice</h3>
                          <h2 class="font-weight-light mt-2">{{comuna_max_indice_region}}</h2>
                          <h2 class="font-weight-medium mt-2">{{indice_max_region}}%</h2>
                        </v-col>
                        <v-col>
                          <h3 class="font-weight-medium">Menor índice</h3>
                          <h2 class="font-weight-light mt-2">{{comuna_min_indice_region}}</h2>
                          <h2 class="font-weight-medium mt-2">{{indice_min_region}}%</h2>
                        </v-col>
                      </v-row>
                    </div>
                    </div>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </div>
</template>

<script>
const API_URL = 'https://api.datari.net/datari/data/';
import axios from 'axios';
import ColumnChartPerc from '../../components/chartsV2/ColumnChartPerc.vue';
import TreeMap from '../../components/chartsV2/TreeMap.vue';



export default {
    components:{
      ColumnChartPerc,
      TreeMap
    },
    name: 'View16',
    props: ['url_api','region'],
    data: () => ({
      data_region: [],
      anio: 2017,
      cantidad_personas_pais: 0,
      cantidad_personas_region:0,
      cantidad_max_personas_region:0,
      comuna_max_personas_region:'',
      cantidad_min_personas_region:0,
      comuna_min_personas_region:'',
      indice_max_region:0,
      comuna_max_indice_region:'',
      indice_min_region:0,
      comuna_min_indice_region:'',
      data_graph1: [],
      labels_graph1:[],
      colors_graph1:[],
      data_graph2: [],
      colors_graph2:[],
      colors_chart8: ["#1565C0", "#FF9800", "#E53935"],
      yaxis_title_chart8: "Porcentaje",
      tooltip_y_chart8: "",
      series: [],
      model_comparacion: 0,
      txt_ranking : [],
      val_ranking: []
    }),

    mounted() {
      this.getRegion();
      this.getDataBox();
      // this.getDataGraph1();
      // this.getDataRanking();
      // this.getDataRegion()
    },
    computed :{
      updateRegion() {
        return this.region;
      },
      updateView(){
        return this.url_api;
      }
    },
    watch: {
      updateRegion(){
        this.getRegion();
        this.getDataBox();
        // this.getDataGraph1();
        // this.getDataRanking();
      },
      updateView(){
        this.model_comparacion =0 ;
        this.getDataBox();
        // this.getDataGraph1();
        // this.getDataRanking();
      }
    },
    methods: {
      async getRegion(){
        const response = await axios.get(API_URL+'regiones/'+this.region+'/');
        let data = response.data;
        this.data_region = data;
      },

      async getDataBox(){
        const response = await axios.get(API_URL+this.url_api.url_api+'?region='+this.region+'&anio='+this.anio);
        let data_region = response.data.data_region;

        let data = response.data.results;
        let data_graph_comunas = this.getGraphicData(data,data_region.nombre,1);

        this.data_graph2 = data_graph_comunas.series;
        this.colors_graph2 = data_graph_comunas.colors;

        this.comuna_max_personas_region  = data_region.comuna_max_cantidad.nombre;
        this.cantidad_max_personas_region = this.formatCL(data_region.comuna_max_cantidad.cantidad_personas);
        this.comuna_min_personas_region = data_region.comuna_min_cantidad.nombre;
        this.cantidad_min_personas_region = this.formatCL(data_region.comuna_min_cantidad.cantidad_personas);
        this.indice_max_region = data_region.comuna_max_porcentaje.indice;
        this.comuna_max_indice_region = data_region.comuna_max_porcentaje.nombre;
        this.indice_min_region = data_region.comuna_min_porcentaje.indice;
        this.comuna_min_indice_region = data_region.comuna_min_porcentaje.nombre;



        let data_pais = response.data.data_pais;
        let data_graph_regiones = this.getGraphicData(data_pais.regiones,data_region.nombre,2);
        this.data_graph1 = data_graph_regiones.series;
        this.labels_graph1 = data_graph_regiones.categorias;
        this.colors_graph1 = data_graph_regiones.colors;
        this.cantidad_personas_region = this.formatCL(data_region.cantidad_personas);
        this.cantidad_personas_pais = this.formatCL(data_pais.cantidad_personas);
      },


      formatCL(val){
        var valor = val.toLocaleString();
        var largo_string = valor.length
        var i;
        var stringFormat = ''; 
        for(i of valor){
          if( i === '.' ){
          i = ',';
          stringFormat+=i;
          }
          else if(i ===','){
          i = '.';
          stringFormat+=i;
          }
          else{
          stringFormat+=i;
          }
        }
          return stringFormat;
      },

      getGraphicData(data,nombre_region,labelType){
        // Tipo 1 -> Años, Tipo 2 -> Regiones
        var element;
        var labels = [];
        var series = [];
        var colors = [];

        if(labelType == 1){
          for(element of data){
            let value = {
              x: element.comuna.nombre,
              y: element.porcentaje_pobreza
            }
            series.push(value);  
          }

          series.sort(function(a,b){
            if(a.y < b.y){
                  return 1;
              }
              if(a.y > b.y){
                  return -1;
              }
              return 0
          });

          colors = ['#0092CD'];
        }
        else if(labelType == 2){
          for(element of data){
            if(!labels.includes(element.nombre)){
              if(element.nombre.includes('Arica')){
                var temp = ['Arica y','Parinacota']
                labels.push(temp);
              }else{
                labels.push(element.nombre);
              }



              if(element.nombre === nombre_region){
                colors.push('#1A5276');
              }
              else{
                colors.push("#B5B5B5");
              }
              series.push(element.cantidad_personas);
            }
          }
        }

       
        return {
                'categorias': labels,
                'series': series,
                'colors': colors
               }
      },

  
      cambiarAnio(anio){
        this.anio = anio;
        this.getDataBox();
      },

      cambiarCategoria(categoria){
        if (categoria === undefined) {
          this.model_comparacion = 0;
        }
        else {
          this.model_comparacion = categoria;
          
        }
        
        this.getDataRanking();
      }
    }

    
}
</script>