<template>
  <div id="RankingDoble">
    <div id="chart">
      <apexchart type="bar" ref="chart1" :height="height" width="100%" :options="chartOptions" :series="series" class="chart-wrapper"></apexchart>
    </div>
  </div>
</template>

<style scoped>
div.chart-wrapper {
  display: flex;
  align-items: center;
  justify-content: center
}
</style>

<script>
export default {

  name: 'RankingDobleSerie',
  props: ['data','categorias','colors','serie1','serie2','titulo','subtitulo','height','unidad'],
  data () {
    return {
      series: [{
        data: []
      }],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 430
        },
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: 'top',
            },
            barHeight: '80%',
          }
        },
        dataLabels: {
          enabled: false,
          offsetX: -6,
          style: {
            fontSize: '12px',
            colors: ['#fff']
          }
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['#fff']
        },
        tooltip: {
          shared: true,
          intersect: false
        },
        xaxis: {
          categories: [],
        },
      },  
    }
  },
  mounted(){ 

      // console.log(this.data)
     this.$refs.chart1.updateOptions({
      series: [{
        data: this.data
      }],
      xaxis:{
        categories: this.categorias,
        axisBorder: {
          show: true
        },
        axisTicks: {
          show: false,
        },
        labels: {
          formatter: (value) => { 
            function formatCL(val){
              var valor = val.toLocaleString();
              var largo_string = valor.length
              var i;
              var stringFormat = ''; 
              for(i of valor){
                if( i === '.' ){
                  i = ',';
                  stringFormat+=i;
                }
                else if(i ===','){
                  i = '.';
                  stringFormat+=i;
                }
                else{
                  stringFormat+=i;
                }
              }
              return stringFormat;
            };
            return formatCL(value) 
          }
        }
        
      },
      yaxis:{
        labels: {
          show: false,
          align: 'left',
          style: {
              colors: [],
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-yaxis-label',
          },
          offsetX: 0,
          offsetY: 0,
          rotate: 0,
          formatter: (value) => { return value },
        },
      },
      title: {
        text: this.titulo,
        align: 'left',
        style: {
          fontSize:  '19px',
          fontWeight:  'light',
          fontFamily:  undefined,
          color:  '#263238'
        },
        margin:-15,
        offsetY:5
      },
    });
  },
  computed: {
    updateData(){
      return this.data;
    }

  },
  watch:{
    updateData(){
      console.log(this.data)
     this.$refs.chart1.updateOptions({
      series: [{
        name: this.serie1,
        data: this.data
      }],
      xaxis:{
        categories: this.categorias,
        axisBorder: {
          show: true
        },
        axisTicks: {
          show:  true,
        },
        labels: {
          formatter: (value) => { 
            function formatCL(val){
              var valor = val.toLocaleString();
              var largo_string = valor.length
              var i;
              var stringFormat = ''; 
              for(i of valor){
                if( i === '.' ){
                  i = ',';
                  stringFormat+=i;
                }
                else if(i ===','){
                  i = '.';
                  stringFormat+=i;
                }
                else{
                  stringFormat+=i;
                }
              }
              return stringFormat;
            };
            return formatCL(value) 
          }
        }
      },
      yaxis:{
        labels: {
          show: true,
          style: {
              colors: [],
              fontSize: '14px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-yaxis-label',
          },
          offsetX: 0,
          offsetY: 0,
          rotate: 0,
        },
      },
      title: {
        text: this.titulo,
        align: 'left',
        style: {
          fontSize:  '19px',
          fontWeight:  'light',
          fontFamily:  undefined,
          color:  '#263238'
        },
        margin:-15,
        offsetY:5
      },
      tooltip:{
        theme: 'dark',
        y: {
          formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
            function formatCL(val){
              var valor = val.toLocaleString();
              var largo_string = valor.length
              var i;
              var stringFormat = ''; 
              for(i of valor){
                if( i === '.' ){
                  i = ',';
                  stringFormat+=i;
                }
                else if(i ===','){
                  i = '.';
                  stringFormat+=i;
                }
                else{
                  stringFormat+=i;
                }
              }
              return stringFormat;
            };

            return '$'+formatCL(value);
          }
        }
      }
    });
    }
  }
}
</script>

<style lang="css" scoped>
</style>