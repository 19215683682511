<template>
  <div id="View12Region">
    <div id="header">
      <v-app-bar color="light-blue darken-2" class="rounded" dense flat dark>
        
        <h2 class="font-weight-medium">{{url_api.nombre_data}}</h2>

        <v-spacer/>
        <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
           
            dark
            v-bind="attrs"
            v-on="on"
          >
            mdi-information-outline
          </v-icon>
        </template>
        <span>Fuente: {{url_api.fuente}}</span>
      </v-tooltip>
      </v-app-bar>
    </div>
    <div id="filtros" class="mt-1">
      <v-card flat height="60" class="rounded">
        <v-card-text>
          <v-row style="margin-top:-15px;">
            <v-col cols="2" >
              <v-select
              v-model="anio"
              :items ="url_api.anios"
              label ="Año"
              item-text="id"
              dense
              outlined
              v-on:change="cambiarAnio">
              </v-select>
            </v-col>
            <v-col >
              <v-select
              v-model="tipo"
              :items ="tipos"
              item-text="tipo"
              item-value="id"
              dense
              outlined
              :label ="url_api.tipo"
              v-on:change="cambiarTipo">
              </v-select>
            </v-col>
            <v-col cols="3">
              <v-select
                v-model="select"
                :items ="url_api.filtros"
                item-text="tipo"
                item-value="id"
                dense
                outlined
                label="Tipo de dato"
                v-on:change="cambiarSelect">
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        
      </v-card>
    </div>
    <div id="body" style="margin-top:-5px;">
      <v-row>
          <v-col cols="4">
            <v-card flat>
              <v-card-title class="justify-center">
                <h3 class="font-weight-medium">País</h3>
              </v-card-title>
              <v-card-text class="text-center" style="margin-top:-10px;">
                
                <div v-if="select === 'Empresas'">
                   
                  <h3 class="font-weight-light">
                    Cantidad de Empresas - {{data_tipo.tipo}}
                  </h3>
                  <h3 class="font-weight-medium mb-3 ">
                    {{anio}}
                  </h3>

                 
                  <h1 class="font-weight-medium" style="display:inline">
                    {{total_pais}}
                  </h1>
                  <h3 class="font-weight-medium" style="display:inline">
                    {{tipo_dato}}
                  </h3>
                  
                </div>
                <div v-else>
                   <h3 class="font-weight-light">
                    Ventas Empresas - {{data_tipo.tipo}}
                  </h3>
                  <h3 class="font-weight-medium mb-3">
                    {{anio}}
                  </h3>
                  <h1 class="font-weight-medium " style="display:inline">
                    ${{total_pais}}
                  </h1>
                  <h3 class="font-weight-medium" style="display:inline">
                    {{tipo_dato}}
                  </h3>
                  
                </div>
                  
              </v-card-text>
            </v-card>
            <v-card flat class="mt-2" >
              <v-card-title class="justify-center">
                <h3 class="font-weight-medium">{{data_region.nombre}}</h3>
              </v-card-title>
              <v-card-text class="text-center" style="margin-top:-15px;">
                <v-row>
                  <v-col>
                    <h3 v-if="select === 'Empresas'" class="font-weight-light">
                      Cantidad de Empresas - {{data_tipo.tipo}}
                    </h3>

                     <h3 v-else class="font-weight-light ">
                      Ventas Empresas - {{data_tipo.tipo}}
                    </h3>
                    <h3 class="font-weight-medium mb-3">
                      {{anio}}
                    </h3>

                     <h1 v-if="select === 'Empresas'" class="font-weight-medium mt-2" style="display:inline">
                      {{total_region}}
                    </h1>
                    <h1 v-else class="font-weight-medium mt-2" style="display:inline">
                      ${{total_region}}
                    </h1>
                    <h3 class="font-weight-medium mt-2" style="display:inline">
                      {{tipo_dato}}
                    </h3>
                  </v-col>
                 </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card flat height="333">
              <v-card-text>
                <column-chart-perc :data="data_graph3" :categories="labels_graph3" :nombre_serie="select"
                :tipo_data="tipo_dato" :colors="colors_graph3" :height="280" :titulo="'Comparación Regional '+anio" 
                :subtitulo="titulo_graph2" />
              </v-card-text>
            </v-card>
          </v-col>
      </v-row>
      <v-row style="margin-top:-15px">
        <v-col>
          <v-card height="410" flat>
            <v-card-text >
              <PieChart  class="mt-6 mb-10" :data="data_graph1" :colors="colors" :labels="labels_graph1" 
              :serie="select" :tipo_dato="tipo_dato" :pos="'left'" :width="480" :titulo="'Distribución Tramos - '+anio"/>
              
              <div v-if="select === 'Ventas'" class="text-center" >
                <h3 class="font-weight-light" >
                  Total Ventas {{anio}}
                </h3> 
                <h2 class="font-weight-medium mt-2">
                  ${{total_anual_region}} {{tipo_dato}}
                </h2>
              </div>
              <div v-else class="text-center">
                <h3 class="font-weight-light" >
                  Total Empresas {{anio}}
                </h3> 
                <h2 class="font-weight-medium mt-2">
                  {{total_anual_region}} {{tipo_dato}}
                </h2>
              </div>
              
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card flat height="410">
            <v-card-text>
              <lineChart 
                :data="data_graph2" 
                :labels="labels_graph2" 
                :serie="nombre_serie2" 
                :height="350"
                :titulo="titulo_graph2" :subtitulo="data_region.nombre" 
                :tipo_serie="tipo_data2"/>  
              
              
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
const API_URL = 'https://api.datari.net/datari/data/';
import PieChart from '../../components/charts/PieChart3.vue';
import lineChart from '../../components/chartsV2/LineChart.vue';
import ColumnChart from '../../components/charts/ColumnChart.vue';
import ColumnChartPerc from '../../components/chartsV2/ColumnChartPerc.vue';
  


export default {
  components: {
    PieChart,
    lineChart,
    ColumnChart,
    ColumnChartPerc
  },
  name: 'View12Region',
  props: ['url_api','region'],
  data: () => ({
    select: null,
    anio: null,
    tipo: null,
    tipos: [],
    data_anual_region: [],
    data_anual_tipo_region: [],
    data_anios_region: [],
    data_anual_tipo_pais: [],
    data_anual_pais: [],
    data_regiones: [],
    tipo_dato: null,
    total_pais: null,
    total_region: null,
    total_anual_region: null,
    porcentaje_region: null,
    data_tipo: [],
    titulo_graph2: '',
    data_region: [],
    data_graph1: [],
    data_graph2: [],
    tipo_data2:null,
    nombre_serie2: null,
    data_graph3: [],
    labels_graph1: [],
    labels_graph2: [],
    labels_graph3:[],
    colors: [],
    colors_graph3: [],
    colors2: ["#000099","#FBC02D","#CC3399",'#003333', '#546E7A', '#FF0033', '#999966', '#669933', '#0000CC', '#6600CC',"#006633",'#f48024', '#CC0033',"#CCCC00","#66BB6A"]
  }),
  created(){
    document.documentElement.style.overflow = 'hidden';
    this.anio = this.url_api.anios[0];
    this.select = this.url_api.filtros[0];
    this.tipo_dato = this.url_api.tipos_datos[0];
    this.cargarTipos();
    this.tipo = 3;

    axios.get(API_URL+this.url_api.url_tipo+this.tipo+'/')
    .then(response => this.data_tipo = response.data);

    axios.get(API_URL+'regiones/'+this.region+'/')
    .then(response => this.data_region = response.data);

    // Cargar Tramo o Rubro segun Corresponda.
    this.sleep(1000).then(() => {
      this.crearDataTramo();
      this.crearDataAnualTramo();
      this.crearDataAnual();
    });
    
  },
  computed: {
    updateItem(){

      return this.region;
    }
  },
  watch: {
    updateItem(){
      axios.get(API_URL+'regiones/'+this.region+'/')
      .then(response => this.data_region = response.data);
      this.crearDataAnualTramo();
      this.crearDataAnual();
      this.crearDataTramo();
    }
  },
  methods: {

    cargarTipos(){
        axios.get(API_URL+this.url_api.url_tipo)
        .then(response => {
          if(this.url_api.tipo === 'tramo'){
            let data_final = [];
            let data = response.data.results;
            let dato;
            for (dato of data){
              if (dato.id !== 6) {
                data_final.push(dato)
              }
            }
            this.tipos = data_final;
          }
          else{
            this.tipos = response.data.results;
          }
        });

    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    crearDataTramo(){
      axios.get(API_URL+this.url_api.url_api+'?region='+this.region+'&tramo='+this.tipo)
      .then(response => {
        this.data_anios_region = response.data.data_tramo_anios;
        const graphic_data =this.getGraphicData(this.data_anios_region,1) 
        this.data_graph2 = graphic_data.series;
        this.labels_graph2 = graphic_data.categorias;

        console.log(graphic_data)


        if (this.select === 'Ventas'){
          this.nombre_serie2 = 'Ventas';
          this.tipo_data2="UF"
          this.titulo_graph2 = 'Ventas Empresas '+this.data_tipo.tipo
        }
        else{
          this.nombre_serie2 = 'Empresas'
          this.tipo_data2 = "empresas"
          this.titulo_graph2 = 'Cantidad Empresas '+this.data_tipo.tipo;
        }
        console.log(this.titulo_graph2)
      })
    },

    crearDataAnual(){
      //Region
      axios.get(API_URL+this.url_api.url_api+'?region='+this.region+'&anio='+this.anio)
      .then(response => {
        // Región
        this.data_anual_region = response.data.data_region.data_region;
        // País
        this.data_anual_pais = response.data.data_region.data_pais;

        var data_tramos = this.data_anual_region.data_tramos;
        let data_graph = this.getGraphicData(data_tramos,3);
        this.data_graph1 = data_graph.series;
        this.labels_graph1 = data_graph.categorias;
        this.colors = data_graph.colors;
        if(this.select === 'Ventas') {
          this.total_anual_region = this.formatCL(this.data_anual_region.ventas_region);
        }
        else{
          this.total_anual_region = this.formatCL(this.data_anual_region.empresas_region);
        }
         
      });

      axios.get(API_URL+this.url_api.url_api+'?anio='+this.anio+'&tramo='+this.tipo)
      .then(response => {
        this.data_regiones = response.data.data_anual_regiones;
        this.data_graph3 = this.getGraphicData(this.data_regiones,2).series;
        this.labels_graph3 = this.getGraphicData(this.data_regiones,2).categorias;
        this.colors_graph3 = this.getGraphicData(this.data_regiones,2).colors;
      });
    },

    crearDataAnualTramo(){
      if(this.tipo != null){
        console.log('flag')
        axios.get(API_URL+this.url_api.url_api+'?region='+this.region+'&anio='+this.anio+'&tramo='+this.tipo)
        .then(response => {
          // Región
          this.data_anual_tipo_region = response.data.data_region.data_region;
          // País
          this.data_anual_tipo_pais = response.data.data_region.data_pais;

          if(this.select === 'Ventas'){
            this.total_region = this.formatCL(this.data_anual_tipo_region.ventas_region);
            this.total_pais = this.formatCL(this.data_anual_tipo_pais.ventas_pais);
            this.tipo_dato = this.url_api.tipos_datos[1];
          }
          else{
            this.total_region = this.formatCL(this.data_anual_tipo_region.empresas_region);
            this.total_pais = this.formatCL(this.data_anual_tipo_pais.empresas_pais);
            this.tipo_dato = this.url_api.tipos_datos[0];
          }
          
        });
      }
      
    },


    cambiarAnio(anio){
      this.anio = anio;
      this.crearDataAnual();
      this.crearDataAnualTramo();
    },
    cambiarTipo(tipo){
      this.tipo = tipo;
      axios.get(API_URL+this.url_api.url_tipo+this.tipo)
      .then(response => this.data_tipo = response.data);
      this.crearDataAnualTramo();
      this.crearDataTramo();
      this.crearDataAnual();
      
    },

  //asda
    cambiarSelect(select){
      this.select = select;
      this.crearDataAnualTramo();
      this.crearDataTramo();
      this.crearDataAnual();
    },

    formatCL(val){
      var valor = val.toLocaleString();
      var largo_string = valor.length
      var i;
      var stringFormat = ''; 
      for(i of valor){
        if( i === '.' ){
          i = ',';
          stringFormat+=i;
        }
        else if(i ===','){
          i = '.';
          stringFormat+=i;
        }
        else{
          stringFormat+=i;
        }
      }
      return stringFormat;
    },
    getGraphicData(data,labelType){
      // Tipo 1 -> Años, Tipo 2 -> Regiones
      var element;
      var labels = [];
      var series = [];
      var colors = [];

      if(labelType == 1){
        for(element of data){
          if(!labels.includes(element.anio)){
            labels.push(element.anio);
          }
          if(this.select === 'Ventas'){
            series.push(element.total_ventas_tramo);
          }
          else{
            series.push(element.total_empresas_tramo);
          }
        }
      }
      else if(labelType == 2){
        for(element of data){
          if(!labels.includes(element.region)){
           if(element.region.includes('Arica')){
              var temp = ['Arica y','Parinacota']
              labels.push(temp);
            }else{
              labels.push(element.region);
            }
            if(element.region === this.data_region.nombre){
              colors.push('#1A5276');
            }
            else{
              colors.push("#B5B5B5");
            }
          }
          if(this.select === 'Ventas'){
            series.push(element.total_ventas);
          }
          else{
            series.push(element.total_empresas);
          }
        }
      }

      else if(labelType == 3){
        for(element of data){
          if(!labels.includes(element.tramo)){
            labels.push(element.tramo);

            if(element.id_tramo === this.tipo){
              colors.push('#33b2df');
            }
            else{
              colors.push('#546E7A');
            }
          }
          if(this.select === 'Ventas'){
            series.push(element.ventas_empresas_tramo);
          }
          else{
            series.push(element.cantidad_empresas_tramo);
          }
        }
      }
     
      return {
              'categorias': labels,
              'series': series,
              'colors': colors
             }
    },
  }
}
    
</script>