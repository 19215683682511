<template>
    <div id="View11Region">
      <div id="header">
        <v-app-bar color="light-blue darken-2" class="rounded" flat dark>
          <h2 class="font-weight-medium">{{url_api.nombre_data}}</h2>
          <v-col style="margin-left: 25px" cols="2">
            <v-select
              v-model="tipo"
              :items ="url_api.tipos"
              label ="Año"
              item-text="id"
              flat
              solo-inverted
              dense
              hide-no-data
              class="mx-2"
              hide-details
              v-on:change="cambiarTipo">
            </v-select>
          </v-col>
          <v-spacer/>
            <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
               
                dark
                v-bind="attrs"
                v-on="on"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <span>Fuente: {{url_api.fuente}}</span>
          </v-tooltip>
        </v-app-bar>
      </div>
      <div id="body" style="margin-top:-5px;">
        <v-row>
          <v-col cols="3">
             <v-card flat>
              <v-card-title class="justify-center">
                 <h2 class="font-weight-medium"><small>País</small></h2>
              </v-card-title>
              <v-card-text class="text-center">
                <h2 class="font-weight-light mb-3">Superficie {{tipo}}</h2>
                <h2 style="display:inline" class="display-1 ">{{superficie_pais}}</h2>
                <h3 style="display:inline" class=" ml-2">{{tipo_dato}}</h3>
              </v-card-text>
            </v-card>
            <v-card flat class="mt-2">
              <v-card-title class="justify-center">
                 <h2 class="font-weight-medium"><small>{{nombre_region}}</small></h2>
              </v-card-title>
              <v-card-text class="text-center">
                
                  <h2 class="font-weight-light mb-3">Superficie {{tipo}}</h2>
                  <h2 style="display:inline" class="display-1 ">{{superficie_region}}</h2>
                  <h3 style="display:inline" class=" ml-2">{{tipo_dato}}</h3>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="9">
            <v-card flat height="320">
              <v-card-text>
                <!-- <column-chart-perc :categories="labels_graph2" :data="data_graph2"
                  :nombre_serie="'Predios '+tipo" :tipo_data="'Predios'" :colors="colors"
                  :titulo="'ss'" :height="300"/> -->
                <ColumnChartPerc :data="data_graph2" :categories="labels_graph2"  :nombre_serie="'Superficie '+tipo " 
                   :colors="colors" :tipo_data="'Hectareas'" :height="280"
                   :titulo="'Comparación Regional'" :subtitulo="'Superficie '+tipo"
                />
              </v-card-text>
             </v-card>
          </v-col>
        </v-row>
      </div>
    <!-- Titulo Fuente de dato -->
    
    <!-- Datos Región-Comuna Fuente de dato  -->
    <!--  -->

    <!-- Gráficos -->
    <v-row style="margin-top:-15px;">
      <v-col>
        <v-card flat height="470">
          <v-card-title class="justify-center font-weight-light">
            Distribución superficie por grupo de cultivo
          </v-card-title>
          <v-card-text>
            <PieChart :data="data_graph1" :labels="labels_graph1" :serie="'Superficie'" :tipo_dato="'Hectareas'"/>
          </v-card-text>
        </v-card>
      </v-col>
      <!-- <v-col>
        <v-card flat height="600">
          <v-card-title class="justify-center font-weight-light">
            Comparación Regional Superficie {{tipo}}
          </v-card-title>
          <v-card-text>
          
            <ColumnChart :data="data_graph2" :categorias="labels_graph2" :nombre_serie="'Predios '+tipo " 
                  :tipo="1" :colors="colors" :tipo_dato="'Predios'"/>
            
          </v-card-text>
        </v-card>
      </v-col> -->
    </v-row>
    </div>
</template>

<script>
import axios from 'axios';
const API_URL = 'https://api.datari.net/datari/data/';
import ColumnChart from '../../components/charts/ColumnChart.vue';
import PieChart from '../../components/charts/PieChart2.vue';
import ColumnChartPerc from '../../components/chartsV2/ColumnChartPerc.vue';

export default {
  components: {
    ColumnChart,
    PieChart,
    ColumnChartPerc
  },
  name: 'View11Region',
  props: ['url_api','region'],
  data: () => ({
    tipo: null,
    anio: null,
    tipo_dato: null,
    data_region: [],
    data_pais: [],
    data_regiones:[],
    nombre_region: null,
    superficie_pais:null,
    superficie_region: null,
    porcentaje_region: null,
    data_graph1: [],
    labels_graph1:[],
    data_graph2: [],
    labels_graph2: [],
    colors: [],
  }),
  created(){
    document.documentElement.style.overflow = 'hidden';
    this.tipo = this.url_api.tipos[0];
    this.anio = this.url_api.anios[0];
    this.crearData();
  },
  computed: {
    updateItem(){
      return this.region;
    }
  },
  watch: {
    updateItem(){
      this.crearData();
    }
  },
  methods: {
    cambiarAnio(anio){
      this.anio = anio;
      this.crearDataRegion();
      this.crearDataAnual();
    },

    cambiarTipo(tipo){
      this.tipo = tipo;
      this.superficie_pais = this.formatCL(this.selectData(this.data_pais).superficie);
      this.superficie_region = this.formatCL(this.selectData(this.data_region).superficie);
      this.porcentaje_region = this.selectData(this.data_region).porcentaje;
      this.data_graph2 = this.getGraphicData(this.data_regiones,2).series;
      this.labels_graph2 = this.getGraphicData(this.data_regiones,2).categorias;
      this.colors = this.getGraphicData(this.data_regiones,2).colors;


    },


    crearData(){
      axios.get(API_URL+this.url_api.url_api+'?region='+this.region)
      .then(response => {
        this.data_region = response.data.results[0];
        this.superficie_region = this.formatCL(this.selectData(this.data_region).superficie);
        this.porcentaje_region = this.selectData(this.data_region).porcentaje;
        this.nombre_region = this.data_region.region.nombre;
        this.data_graph1 = this.getGraphicData(this.data_region,3).series;
        this.labels_graph1 = this.getGraphicData(this.data_region,3).categorias;
       
      });

      axios.get(API_URL+this.url_api.url_api+'?region=18')
      .then(response => {
        this.data_pais = response.data.results[0];
        this.superficie_pais = this.formatCL(this.selectData(this.data_pais).superficie);
        this.tipo_dato = this.data_pais.tipo_dato;
        console.log(this.superficie_pais)
      });

      axios.get(API_URL+this.url_api.url_api)
      .then(response => {
        this.data_regiones = response.data.results;
        this.data_graph2 = this.getGraphicData(this.data_regiones,2).series;
        this.labels_graph2 = this.getGraphicData(this.data_regiones,2).categorias;
        this.colors = this.getGraphicData(this.data_regiones,2).colors;

      });




    },

    selectData(data){
      var superficie;
      var porcentaje;

      if(this.tipo === this.url_api.tipos[0]){
        superficie = data.superficie_total;
        porcentaje = data.porcentajes.porcentaje_total;
      }
      else if(this.tipo === this.url_api.tipos[1]){
        superficie = data.superficie_cereales;
        porcentaje = data.porcentajes.porcentaje_cereales;
      }
      else if(this.tipo === this.url_api.tipos[2]){
        superficie = data.superficie_leguminosas;
        porcentaje = data.porcentajes.porcentaje_leguminosas;
      }
      else if(this.tipo === this.url_api.tipos[3]){
        superficie = data.superficie_industriales;
        porcentaje = data.porcentajes.porcentaje_industriales;
      }
      else if(this.tipo === this.url_api.tipos[4]){
        superficie = data.superficie_hortalizas;
        porcentaje = data.porcentajes.porcentaje_hortalizas;
      }
      else if(this.tipo === this.url_api.tipos[5]){
        superficie = data.superficie_flores;
        porcentaje = data.porcentajes.porcentaje_flores;
      }
      else if(this.tipo === this.url_api.tipos[6]){
        superficie = data.superficie_forrajeras;
        porcentaje = data.porcentajes.porcentaje_forrajeras;
      }
      else if(this.tipo === this.url_api.tipos[7]){
        superficie = data.superficie_frutales;
        porcentaje = data.porcentajes.porcentaje_frutales;
      }
      else if(this.tipo === this.url_api.tipos[8]){
        superficie = data.superficie_vinas;
        porcentaje = data.porcentajes.porcentaje_vinas;
      }
      else if(this.tipo === this.url_api.tipos[9]){
        superficie = data.superficie_viveros;
        porcentaje = data.porcentajes.porcentaje_viveros;
      }
      else if(this.tipo === this.url_api.tipos[10]){
        superficie = data.superficie_semilleros;
        porcentaje = data.porcentajes.porcentaje_semilleros;
      }
      else if(this.tipo === this.url_api.tipos[11]){
        superficie = data.superficie_forestales;
        porcentaje = data.porcentajes.porcentaje_forestales;
      }
      

      return {
        'superficie': superficie,
        'porcentaje': porcentaje
      }
    },

    formatCL(val){
      var valor = val.toLocaleString();
      var largo_string = valor.length
      var i;
      var stringFormat = ''; 
      for(i of valor){
        if( i === '.' ){
          i = ',';
          stringFormat+=i;
        }
        else if(i ===','){
          i = '.';
          stringFormat+=i;
        }
        else{
          stringFormat+=i;
        }
      }
      return stringFormat;
    },
    getGraphicData(data,labelType){
      // Tipo 1 -> Años, Tipo 2 -> Regiones
      var element;
      var labels = [];
      var series = [];
      var colors = [];

      if(labelType == 1){
        for(element of data){
          if(!labels.includes(element.anio)){
            labels.push(element.anio);
          }
          series.push(element.superficie);
        }
      }
      else if(labelType == 2){
        for(element of data){
          if(element.region.nombre != 'Nacional'){
            if(!labels.includes(element.region.nombre)){
              var temp = ['Arica y','Parinacota']
              if(element.region.nombre.includes('Arica')){
                labels.push(temp);
              }else{
                labels.push(element.region.nombre);
              }

              if(element.region.nombre === this.nombre_region){
                colors.push('#1A5276');
              }
              else{
                colors.push("#B5B5B5");
              }
              // colors.push(element.region.color);
            }

            if(this.tipo === this.url_api.tipos[0]){
              series.push(element.superficie_total);
            }

            else if(this.tipo === this.url_api.tipos[1]){
              series.push(element.superficie_cereales);
            }
            else if(this.tipo === this.url_api.tipos[2]){
              series.push(element.superficie_leguminosas);
             
            }
            else if(this.tipo === this.url_api.tipos[3]){
              series.push(element.superficie_industriales);
              
            }
            else if(this.tipo === this.url_api.tipos[4]){
              series.push(element.superficie_hortalizas);
            }
            else if(this.tipo === this.url_api.tipos[5]){
              series.push(element.superficie_flores);
            }
            else if(this.tipo === this.url_api.tipos[6]){
              series.push(element.superficie_forrajeras);
              
            }
            else if(this.tipo === this.url_api.tipos[7]){
              series.push(element.superficie_frutales);
              
            }
            else if(this.tipo === this.url_api.tipos[8]){
              series.push(element.superficie_vinas);
            }
            else if(this.tipo === this.url_api.tipos[9]){
              series.push(element.superficie_viveros);
            }
            else if(this.tipo === this.url_api.tipos[10]){
             series.push(element.superficie_semilleros);
            }
            else if(this.tipo === this.url_api.tipos[11]){
              series.push(element.superficie_forestales);
            }
            
          }
        }
      }
      else if(labelType == 3){

        var lista = [
          data.superficie_cereales,data.superficie_leguminosas,data.superficie_industriales,data.superficie_hortalizas, 
          data.superficie_flores,data.superficie_forrajeras,data.superficie_frutales,
          data.superficie_vinas, data.superficie_viveros, data.superficie_semilleros, data.superficie_forestales
        ];
        
        
        var l = [
          this.url_api.tipos[1],this.url_api.tipos[2],this.url_api.tipos[3],
          this.url_api.tipos[4], this.url_api.tipos[5],this.url_api.tipos[6], this.url_api.tipos[7],
          this.url_api.tipos[8],this.url_api.tipos[9],this.url_api.tipos[10],this.url_api.tipos[11]
        ];

        series = lista;
        labels = l;

      }
     
      return {
              'categorias': labels,
              'series': series,
              'colors':colors
             }
    },
  }
}
    
</script>