<template>
	<div id="View15Region">
    <!-- Titulo Fuente de dato -->
    <div id="header">
     <v-app-bar color="light-blue darken-2" class="rounded" flat dark>
      <h2 class="font-weight-medium">{{url_api.nombre_data}}</h2>

        <v-col cols="2">
          <v-select
          v-model="anio"
          :items ="anios"
          item-text="text"
          item-value="value"
          label ="Año"
          hide-no-data
          hide-details
          class="mx-2"
          flat
          solo-inverted
          dense
          v-on:change="cambiarAnio"
          ></v-select>
        </v-col>
        <v-spacer/>
        <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
           
            dark
            v-bind="attrs"
            v-on="on"
          >
            mdi-information-outline
          </v-icon>
        </template>
        <span>Fuente: {{url_api.fuente}}</span>
      </v-tooltip>
     </v-app-bar>
    </div>
    <!-- Datos Región-Comuna Fuente de dato  -->
    <div id="body" style="margin-top:-5px;">
      <v-row>
        <v-col cols="4">
          
              <v-card height="145" flat>
                <v-card-title class="justify-center">
                  <h3 class="font-weight-medium">País</h3>
                </v-card-title>
                <v-card-text class="text-center">
                 
                  <h1 style="display:inline" class="font-weight-medium display-1" >
                    {{total_pais}}
                  </h1>
                  <h2 style="display:inline" class="font-weight-medium text-muted">
                    {{tipo_dato}}
                  </h2>
                  
                </v-card-text>
              </v-card>
           
              <v-card height="145" flat class="mt-2">
                <v-card-title class="justify-center">
                  <h3 class="font-weight-medium">{{nombre_region}}</h3>
                </v-card-title>
                <v-card-text class="text-center">
                  <h1 style="display:inline" class="font-weight-medium display-1" >
                    {{total_region}}
                  </h1>
                  <h2 style="display:inline" class="font-weight-medium text-muted">
                    {{tipo_dato}}
                  </h2>
                </v-card-text>
              </v-card>
            
        </v-col>
        <v-col cols="8">
          <v-card flat height="100%">
            <v-card-text>
              <column-chart :data="data_graph1" :categories="labels_graph1" :colors="colors_graph1" :tipo_data="tipo_dato" :nombre_serie="url_api.nombre_data" :titulo="titulo"/>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row style="margin-top:-15px;">
        <v-col>
          <v-card flat height="490">
            <v-card-text>
              <donut-chart class="mt-4" :titulo="'Patentes registradas por tipo'"
              :data="data_graph2" :labels="labels_graph2" :colors="colors_graph2"/>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card flat height="490">
            <v-card-title>
              <v-col cols="5" class="mx-auto">
                <v-select
                v-model="tipo"
                :items ="tipos"
                item-text="text"
                item-value="value"
                label ="Tipo"
                hide-no-data
                hide-details
                class="mx-2"
                flat
                dense
                outlined
                v-on:change="cambiarTipo"
                ></v-select>
              </v-col>
            </v-card-title>
            <v-card-text>
              <area-chart :data="data_graph3" :labels="labels_graph3" :titulo="titulo_graph3"
              :subtitulo="subtitulo_graph3" :colors="colors_graph3" :nombre_serie="url_api.nombre_data" :height="350"/>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
	</div>
</template>

<script>
import axios from 'axios';
import ColumnChart from '../../components/chartsV2/ColumnChartValue.vue';
import DonutChart from '../../components/chartsV2/DonutChart.vue';
import AreaChart from '../../components/chartsV2/AreaChart.vue';

const API_URL = 'https://api.datari.net/datari/data/';

export default {
  components: {
    ColumnChart,
    DonutChart,
    AreaChart
  },
  name: 'View15Region',
  props: ['url_api','region'],
  data: () => ({
    anio: '',
    tipo: '',
    total_pais: null,
    total_region: null,
    nombre_region: null,
    tipo_dato: null,
    anios: [],
    tipos: [],
    titulo: null,
    data_regiones: [],
    data_graph1: [],
    labels_graph1: [],
    colors_graph1: [],
    data_graph2: [],
    labels_graph2:[],
    colors_graph2: [],
    data_graph3: [],
    labels_graph3: [],
    colors_graph3: [],
    titulo_graph3:null,
    subtitulo_graph3:null,
    loading:true
  }),
  created(){
    this.get_data_region();
    this.get_data_region_anios();
    this.get_data_pais();
    this.sleep(1500).then(() => {
      this.loading = false;
    });
  },
  computed: {
    updateRegion(){
      return this.region;
    }
  },
  watch: {
    updateRegion(){
      this.get_data_region();
      this.get_data_region_anios()
      this.sleep(1000).then(() => {this.get_data_pais();});
      
    }
  },
  methods: {

    async get_data_pais(){

        let response = await axios.get(
          API_URL+this.url_api.url_api+'?anio='+this.anio
        );

        let data_pais = response.data.data;
        let data_fuente = response.data.data_fuente;
        let data_regiones = response.data.data.data_regiones;
        this.total_pais = this.formatCL(data_pais.data_pais);

        this.data_graph1 = this.getGraphicData(data_regiones,2).series;
        this.labels_graph1 = this.getGraphicData(data_regiones,2).categorias;
        this.colors_graph1 = this.getGraphicData(data_regiones,2).colors;

        if(this.tipo_dato === null || this.tipos === [] || this.anios === []){
          this.tipo_dato = data_fuente.tipo_dato;
          this.tipos = data_fuente.tipos;
          this.anios = data_fuente.anios;
        }

        if(this.anio === ''){
          this.titulo = 'Patentes registradas por región '+this.anios[this.anios.length-1].text+'-'+this.anios[1].text;
        }
        else{
          this.titulo = 'Patentes registradas por región '+this.anio;
        }

        
    },

    async get_data_region(){

      let response = await axios.get(
        API_URL+this.url_api.url_api+'?region='+this.region+'&anio='+this.anio
      );

      let data_region = response.data.data;
      let data_por_tipo = data_region.data_por_tipo;

      this.data_graph2 = this.getGraphicData(data_por_tipo,3).series;
      this.labels_graph2 = this.getGraphicData(data_por_tipo,3).categorias;
      this.colors_graph2 = this.getGraphicData(data_por_tipo,3).colors;
      this.nombre_region = data_region.region;
      this.total_region = this.formatCL(data_region.cantidad_patentes_region);

    },

    async get_data_region_anios(){

      let response = await axios.get(
        API_URL+this.url_api.url_api+'?region='+this.region+'&tipo='+this.tipo
      );
      let data_anios = response.data.data.data_por_anio;
      data_anios = data_anios.reverse();
      console.log(data_anios);

      this.data_graph3 = this.getGraphicData(data_anios,1).series;
      this.labels_graph3 = this.getGraphicData(data_anios,1).categorias;
      this.colors_graph3 = this.getGraphicData(data_anios,1).colors;
      this.titulo_graph3 = 'Patentes registradas 2009-2020';

      if(this.tipo === ''){
        this.subtitulo_graph3 = 'Todos los tipos';
      }else{
        this.subtitulo_graph3 = this.tipo;
      }
       

    },
    
    cambiarTipo(tipo){
      this.tipo = tipo;
      this.get_data_region_anios();
    },

    cambiarAnio(anio){

      this.anio = anio;
      this.get_data_region();
      this.get_data_pais();
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    formatCL(val){
      var valor = val.toLocaleString();
      var largo_string = valor.length
      var i;
      var stringFormat = ''; 
      for(i of valor){
        if( i === '.' ){
          i = ',';
          stringFormat+=i;
        }
        else if(i ===','){
          i = '.';
          stringFormat+=i;
        }
        else{
          stringFormat+=i;
        }
      }
      return stringFormat;
    },
    getGraphicData(data,labelType){
      // Tipo 1 -> Años, Tipo 2 -> Regiones
      var element;
      var labels = [];
      var series = [];
      var colors = [];

      if(labelType == 1){
        for(element of data){
          if(!labels.includes(element.anio)){
            labels.push(element.anio);
          }
          series.push(element.cantidad);
        }
        colors = ['#00796B']
      }
      else if(labelType == 2){
        for(element of data){
          if(!labels.includes(element.region)){

            if(element.region.includes('Arica')){
              var temp = ['Arica y','Parinacota']
              labels.push(temp);
            }else{
              labels.push(element.region);
            }
            
          }
          if(element.region === this.nombre_region){
            colors.push('#1A5276');
          }
          else{
            colors.push("#B5B5B5");
          }

          series.push(element.cantidad);
        }
      }

      else if(labelType === 3){
        for(element of data){
          labels.push(element.tipo);
          series.push(element.cantidad);
        }
        colors = ['#1A237E','#1976D2','#90CAF9'];
      }
     
      return {
              'categorias': labels,
              'series': series,
              'colors': colors
             }
    },
  }
}
	
</script>