<template>
  <div id="chart">
    <apexchart
      type="bar"
      height="350"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>
<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: ["myseries", "mycategories", "mycolors", "my_ytitle", "mytooltip_y"],
  data() {
    return {
      series: this.myseries,
      chartOptions: {
        colors: this.mycolors,
        chart: {
          type: "bar",
          height: 350,
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "85%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          categories: this.mycategories,
        },
        yaxis: {
          labels: {
            formatter: function (val) {
              return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            },
          },
          title: {
            text: this.my_ytitle,
            style: {
              fontWeight: "bold",
            },
          },
        },
        fill: {
          opacity: 1,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return (
                "% " + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
              );
            },
          },
        },
      },
    };
  },

  computed: {
    Series() {
      return this.myseries;
    },

    Yaxis() {
      return this.my_ytitle;
    },

    TooltipY() {
      
      return this.mytooltip_y;
    },
  },

  watch: {
    Series() {
      this.series = this.myseries;
    },

    Yaxis() {
      this.chartOptions = {
        colors: this.mycolors,
      };
    },

    TooltipY() {
      console.log(this.mytooltip_y)
      this.chartOptions = {
        tooltip: {
          y: this.mytooltip_y,
        },
      };
    },
  },
};
</script>