<template>
  <div>
    <div id="header">
      <v-app-bar color="light-blue darken-2" class="rounded" flat dark>
        <h2 class="font-weight-medium">{{url_api.nombre_data}}</h2>
        
        <v-col cols="2" class="ml-2">
          <v-select
          v-model="model_comparacion"
          :items ="url_api.tipos"
          label ="Año"
          item-value="id"
          item-text="text"
          hide-no-data
          hide-details
          class="mx-2 my-2"
          flat
          solo-inverted
          dense
          v-on:change="cambiarTipo"
          ></v-select>
        </v-col>
        <v-col cols="2" style="margin-left: -25px;">
          <v-select
          v-model="anio"
          :items ="url_api.anios"
          label ="Año"
          hide-no-data
          hide-details
          class="mx-2 my-2"
          flat
          solo-inverted
          dense
          v-on:change="cambiarAnio"
          ></v-select>
        </v-col>
        <v-spacer/>
        <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
           
            dark
            v-bind="attrs"
            v-on="on"
          >
            mdi-information-outline
          </v-icon>
        </template>
        <span>Fuente: {{url_api.fuente}}</span>
      </v-tooltip>
    </v-app-bar>
    </div>
    <div id="body" style="margin-top:-5px;">
      <v-row>
        <v-col cols="3">
          <v-card flat height="165">
            <v-card-title class="justify-center">
              <h3 class="font-weight-medium">País</h3>
            </v-card-title>
            <v-card-text class="text-center" style="margin-top: -10px;">
              <h3 class="font-weight-light">
                Porcentaje de uso de internet - {{anio}}
              </h3>
              <h3 class="font-weight-medium mt-2">
                {{url_api.tipos[model_comparacion].text}}
              </h3>
              <h1 class="font-weight-medium mt-4">
                {{porcentaje_pais}} %
              </h1>

            </v-card-text>
          </v-card>
          <v-card flat class="mt-3" height="170">
            <v-card-title class="justify-center">
              <h3 class="font-weight-medium">{{nombre_region}}</h3>
            </v-card-title>
            <v-card-text class="text-center"  style="margin-top: -10px;">
              <h3 class="font-weight-light">
                Porcentaje de uso de internet - {{anio}}
              </h3>
              <h3 class="font-weight-medium mt-2">
                {{url_api.tipos[model_comparacion].text}}
              </h3>
              <h1 class="font-weight-medium mt-4">
                {{porcentaje_region}} %
              </h1>
            </v-card-text>
          </v-card>

        </v-col>

         <v-col cols="5">
          <v-card flat>
            <v-card-text>
               <AreaChart :data="data_graph1" :labels="labels_graph1" :colors="colors_graph1"
            :tipo_data="'sad'" :nombre_serie="'Índice de uso'" :titulo="'Uso Internet - '+url_api.tipos[model_comparacion].text" :subtitulo="'Variación anual 2013-2020 - '+nombre_region" :height="300"/>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card flat>
            <v-card-text>
              <BarChart :data="data_graph3" :labels="labels_graph3"
                :subtitulo="url_api.tipos[model_comparacion].tooltip" :titulo="'Uso de Internet - Nacional'"
                :nombre_serie="'Porcentaje de uso'"
              />
            </v-card-text>
          </v-card>
        </v-col>

       </v-row>
       <v-row style="margin-top: -15px;">
        <v-col>
          <v-card flat>
            <v-card-text>
              
              <FillChart :data="data_graph2" :labels="labels_graph2" :colors="colors_graph2"
                :titulo="'Comparación Regional - '+anio" :nombre_serie="'Indice de uso'" :tipo_data="url_api.tipos[model_comparacion].tooltip" :subtitulo="url_api.tipos[model_comparacion].descripcion" :height="440" :region="nombre_region" />
             
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>


    </div>

  </div>
</template>

<script>
const API_URL = 'https://api.datari.net/datari/data/';
import axios from 'axios';
import AreaChart from '../../components/chartsV2/AreaChart';
import FillChart from '../../components/chartsV2/FillChart.vue';

import BarChart from '../../components/chartsV2/BarChart.vue';


export default {

  name: 'View18',
  components: {
    AreaChart,
    FillChart,
    BarChart
  },
  props: ['url_api','region'],
  data () {
    return {
      anio:null,
      model_comparacion: 0,
      data_graph1: [],
      labels_graph1: [],
      colors_graph1: ['#4E8244'],
      nombre_region: null,
      data_graph2: [],
      labels_graph2: [],
      colors_graph2: [],
      data_graph3:[],
      labels_graph3:[],
      porcentaje_region: null,
      porcentaje_pais:null

    }
  },
  mounted(){
    document.documentElement.style.overflow = 'hidden';
    this.anio = this.url_api.anios[0];

    this.get_data_region();
    this.get_data_pais();
    this.get_data_regiones();
  },
  computed: {
    updateRegion(){
      return this.region;
    }
  },
  watch: {
    updateRegion(){
      this.get_data_regiones();
      this.get_data_region();
    }
  },
  methods: {

    async get_data_region() {

      let url = this.url_api.url_apis[this.model_comparacion];
      let response = await axios.get(
        API_URL+url+'?region='+this.region
      );

      let data = response.data.results[0];
      this.nombre_region= data.region.nombre;
      // let index_anio = data.anios.indexOf(this.anio);
      // console.log(index_anio);

      let anios = data.anios;
      let data_anios = data.data_anios;

      let index_anio = anios.indexOf(this.anio);

      this.porcentaje_region = data_anios[index_anio];

      this.data_graph1 = data.data_anios;
      this.labels_graph1 = data.anios;

      
    },

    async get_data_pais() {
      let url = this.url_api.url_apis[this.model_comparacion];
      let response = await axios.get(
        API_URL+url+'?region=18'
      );

      let data = response.data.results[0];
      this.data_graph3 = data.data_anios;
      let anios = data.anios;
      let data_anios = data.data_anios;

      let index_anio = anios.indexOf(this.anio);
      this.porcentaje_pais = data_anios[index_anio];

      this.labels_graph3 = anios.map(anio => {
        return anio.toString();
      });
    },

    async get_data_regiones(){
      let url = this.url_api.url_apis[this.model_comparacion];
      let response = await axios.get(
        API_URL+url
      );

      let data = response.data.results;
      
      let data_grafico = this.getGraphicData(data,2);
      this.data_graph2 = data_grafico.series;
      this.labels_graph2 = data_grafico.categorias;
      this.colors_graph2 = data_grafico.colors;
      
    },

    getGraphicData(data,labelType){
      // Tipo 1 -> Años, Tipo 2 -> Regiones
      var element;
      var labels = [];
      var series = [];
      var colors = [];

      if(labelType == 1){
        for(element of data){
          if(!labels.includes(element.anio)){
            labels.push(element.anio);
          }
          series.push(element.cantidad);
        }
        colors = ['#00796B']
      }
      else if(labelType == 2){
        for(element of data){
          if(element.region.nombre !== 'Nacional'){
            if(!labels.includes(element.region.nombre)){

              if(element.region.nombre.includes('Arica')){
                var temp = ['Arica y','Parinacota']
                labels.push(temp);
              }else{
                labels.push(element.region.nombre);
              }
              
            }

            if(element.region.nombre === this.nombre_region){
              colors.push('#558B2F');
            }
            else{
              colors.push("#000000");
            }

            let index_anio = this.url_api.anios.indexOf(this.anio);

            series.push(element.data_anios[index_anio]);
          }
        }
      }

      else if(labelType === 3){
        for(element of data){
          labels.push(element.tipo);
          series.push(element.cantidad);
        }
        colors = ['#1A237E','#1976D2','#90CAF9'];
      }
     
      return {
              'categorias': labels,
              'series': series,
              'colors': colors
             }
    },

    cambiarAnio(anio){
      this.anio = anio;
      this.get_data_regiones();
      this.get_data_pais();
      this.get_data_region();
    },
    cambiarTipo(tipo){
      this.model_comparacion = tipo;
      this.get_data_region();
      this.get_data_regiones();
      this.get_data_pais();
    }
  }
}
</script>

<style lang="css" scoped>
</style>