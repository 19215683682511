<template>
<div id="View4Regional">
  <div id="head">
    <v-app-bar color="light-blue darken-2" class="rounded" flat dark>
      <h2 class="font-weight-medium">{{url_api.nombre_data}}</h2>
      <v-col cols="2">
        <v-select
        :items="anios"
        v-model="anio"
        :label="'Año'"
        hide-no-data
        hide-details
        dense
        class=" ml-4"
        flat
        solo-inverted
        dark
        v-on:change="cambiarAnio"
        >
        </v-select>
      </v-col>
       <v-col cols="2">
        <v-select
        v-model="mes"
        :items ="url_api.meses"
        label ="Mes"
        item-text="nombre"
        item-value="id"
        flat
        class="mx-auto"
        dense
        solo-inverted
        
        hide-no-data
        hide-details
        v-on:change="cambiarMes"
        ></v-select>
      </v-col>
        <v-spacer/>
        <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
           
            dark
            v-bind="attrs"
            v-on="on"
          >
            mdi-information-outline
          </v-icon>
        </template>
        <span>Fuente: {{url_api.fuente}}</span>
      </v-tooltip>
    </v-app-bar>
  </div>
  <div id="body">
    <v-row style="margin-top:-5px;">
      <v-col cols=7>
        <v-row style="margin-top:-10px;">
          <v-col cols="5">
            <v-card flat>
              <v-card-title class="justify-center">
                <h2 class="font-weight-light mb-3">Marco de Evaluación</h2>
              </v-card-title>
              <v-card-text class="text-center">
                <h1 style="display:inline" >${{marco}}</h1>
                <h3 style="display:inline" class=" ml-2">Millones de pesos</h3>
              </v-card-text>
            </v-card>

            <v-card flat class="mt-2">
              <v-card-title class="justify-center">
                 <h2 class="font-weight-light mb-0">Gasto Acumulado</h2>
              </v-card-title>
              <v-card-text class="text-center">
                
                <h1 style="display:inline" >${{gasto}}</h1>
                <h3 style="display:inline" class=" ml-2 ">Millones de pesos</h3>
                <h2 class="font-weight-light mb-1 mt-3">Presupuesto Ejecutado</h2>
                <v-col cols="7" class="mx-auto">
                  <v-progress-linear v-model="porcentaje" height="25" color="blue-grey">
                    <strong>{{porcentaje}}%</strong>
                  </v-progress-linear>
                </v-col>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="7">
            <v-card flat>
              <v-card-text>
                <AreaChart :data="data_graph1" :labels="labels_graph1" :colors="colors_graph1"
                :tipo_data="'sad'" :nombre_serie="'Presupuesto ejecutado - '+mes" :titulo="'Variación anual de ejecución - '+mes" :subtitulo="subtitulo_1" :height="275"/>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row style="margin-top:-15px;">
          <v-col>
            <v-card flat>
              <v-card-text>
                <StackChart :region="region" :anio="anio" style="margin-top: 30px;" :height="390"/>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols=5>
        <v-card flat>
          <v-card-text>
            <StackChartFill :data="data_fuente" :mes="mes" :anio="anio" />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</div>
</template>
<script>
import axios from 'axios';
const API_URL = 'https://api.datari.net/datari/data/';
import StackChartFill from '../../components/charts/StackChartFill.vue';
import StackChart from '../../components/charts/StackChart.vue';
import AreaChart from '../../components/chartsV2/AreaChart';

export default {
components: {
  StackChartFill,
  StackChart,
  AreaChart
},
name: 'View4Regional',
props: ['url_api','region'],
data: () => ({
  data_fuente: [],
  data_fuente_region: [],
  mes: null,
  anio: null,
  marco: null,
  gasto:  null,
  porcentaje: null,
  anios: [],
  loading:true,
  data_graph1: [],
  labels_graph1:[],
  colors_graph1:[],
  subtitulo_1:null
}),
created(){
  document.documentElement.style.overflow = 'hidden';
  this.mes = this.url_api.meses[0];
  this.anios = this.url_api.anios.sort((a, b) => b - a);
  this.anio = this.anios[0]
  this.obtenerDataFuente(this.anio,this.mes);
  this.obtenerDataFuenteRegion(this.anio,this.mes);
  this.get_data_region_mes();
  this.sleep(1500).then(() => {this.loading=false;});

},
computed: {
  update(){
      return this.region;
  }

},
watch: {
  update(){
    this.obtenerDataFuente(this.anio,this.mes);
    this.obtenerDataFuenteRegion(this.anio,this.mes);
    this.get_data_region_mes();
  }
},
methods: {

     getGraphicData(data,labelType){
      // Tipo 1 -> Años, Tipo 2 -> Regiones
      var element;
      var labels = [];
      var series = [];
      var colors = [];

      if(labelType == 1){
        for(element of data){
          if(!labels.includes(element.anio)){
            labels.push(element.anio);
          }
          series.push(element.cantidad);
        }
        colors = ['#00796B']
      }
      else if(labelType == 2){
        for(element of data){
          if(!labels.includes(element.region)){
            if(element.region.nombre.includes('Arica')){
              var temp = ['Arica y','Parinacota']
              labels.push(temp);
            }else{
              labels.push(element.region.nombre);
            }
            
          }
          if(this.nombre_region.includes(element.region.nombre)){
            colors.push('#1A5276');
          }
          else{
            colors.push("#B5B5B5");
          }

          series.push(element.valor_pib);
        }
      }

      else if(labelType === 3){
        for(element of data){
          labels.push(element.anio);
          series.push(element.ejecucion_presupuestaria);
        }
        colors = ['#1A237E'];
      }
     
      return {
              'categorias': labels,
              'series': series,
              'colors': colors
             }
    },

   sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

  obtenerDataFuente(anio, mes){
      axios.get(API_URL + this.url_api.url_api + '?anio='+anio+'&mes='+mes)
      .then(response => {
          this.data_fuente = response.data.results;
      });
  },


  obtenerDataFuenteRegion(anio,mes){
      axios.get(API_URL + this.url_api.url_api + '?anio='+anio+'&mes='+mes+'&region='+this.region)
      .then(response => {
          this.data_fuente_region = response.data.results;
          if(this.data_fuente_region.length != 0 ){
              this.marco = this.data_fuente_region[0].marco_evaluacion.toLocaleString().replace(',','.');
              this.marco = this.marco.replace(',','.');
              this.gasto = this.data_fuente_region[0].gasto_acumulado.toLocaleString().replace(',','.');
              this.gasto = this.gasto.replace(',','.');
              this.porcentaje = this.data_fuente_region[0].ejecucion_presupuestaria;
          }
          
      });
  },

  get_data_region_mes(){
    axios.get(API_URL+this.url_api.url_api+'?mes='+this.mes+'&region='+this.region)
    .then(response => {

      var data = response.data.results;
      data.sort((a,b) => a.anio - b.anio);
      this.subtitulo_1 = data[0].anio+' - '+data[data.length-1].anio;
      this.data_graph1 = this.getGraphicData(data,3).series;
      this.labels_graph1 = this.getGraphicData(data,3).categorias;
      this.colors_graph1 = this.getGraphicData(data,3).colors;
    });
  },

  cambiarAnio(anio){
      console.log(anio);
      this.anio = anio;
      this.mes = this.url_api.meses[0];
      this.obtenerDataFuente(this.anio,this.mes);
      this.obtenerDataFuenteRegion(this.anio,this.mes);
  },

  cambiarMes(mes){
      this.mes = mes;
      this.obtenerDataFuente(this.anio,this.mes);
      this.obtenerDataFuenteRegion(this.anio,this.mes);
      this.get_data_region_mes();
  }

}
}

</script>