<template>
  <div>
    <div id="header">
      <v-app-bar color="light-blue darken-2" class="rounded" flat dark>
         <h2 class="font-weight-medium">{{url_api.nombre_data}}</h2>
        <v-col cols="2">
        <v-select
        v-model="anio"
        :items ="url_api.anios"
        label ="Año"
        hide-no-data
        hide-details
        class="mx-2 my-2"
        solo-inverted
        flat
        dense
        v-on:change="cambiarAnio"
        ></v-select>
      </v-col>
      <v-spacer/>
        <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
           
            dark
            v-bind="attrs"
            v-on="on"
          >
            mdi-information-outline
          </v-icon>
        </template>
        <span>Fuente: {{url_api.fuente}}</span>
      </v-tooltip>
      </v-app-bar>
    </div>
    <div style="margin-top:-5px" id="body">
      <v-row>
        <v-col cols="3">
           <v-card flat height="100%">
             <v-card-title class="justify-center"><h3 class="font-weight-light">Tipo de hacinamiento</h3></v-card-title>
             <v-card-text>
               <v-list class="mt-4">
              <v-list-item-group v-model="model_comparacion"  v-on:change="cambiarTipo" color="blue darken-2">
                <v-list-item
                  v-for="(item, i) in url_api.categorias"
                  :key="i"
                >
                  <v-list-item-content>
                    <v-list-item-title class="text-center" v-text="item"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
             </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="9">
          <v-card flat height="330">
            <v-card-text>
            <Chart4 :mydata="data_chart4" :mycategories="categories_chart4" :title="'Progresión período 2006-2017 - '+data_region.nombre" 
            :subtitle="'Tipo de hacinamiento - '+url_api.categorias[model_comparacion]" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row style="margin-top:-15px;">
        <!-- <v-col cols="3">
          <v-card flat height="100%">
            <v-card-title class="justify-center"><h3 class="font-weight-light">Año</h3></v-card-title>
            <v-list class="mt-4  ">
              <v-list-item-group v-model="model_anio"  v-on:change="cambiarAnio" color="blue darken-2">
                <v-list-item
                  v-for="(item, i) in url_api.anios"
                  :key="i"
                >
                  <v-list-item-content>
                    <v-list-item-title class="text-center" v-text="item"></v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
        </v-col> -->
        <v-col>
           <v-card flat height="460">
            <v-card-text>
           <Chart5  :mydata="data_chart5" :title="'Estimación porcentual por tipo - '+data_region.nombre" 
           :subtitle="'Año - '+anio" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>


<script>
const API_URL = 'https://api.datari.net/datari/data/';
import axios from 'axios';
import Chart4 from "../../components/chartsV2/ApexChart4";
import Chart5 from "../../components/chartsV2/ApexChart5";

export default {
    components: {
      Chart5,
      Chart4,
    },
    name: 'View17',
    props: ['url_api','region'],
    data: () => ({
      data_region: [],
      anio:2006,
      model_comparacion:0,
      model_anio: 0,
      data_chart4: [],
      categories_chart4: [],
      data_chart5: [],
      data_region:[]
    }),

    mounted(){
      document.documentElement.style.overflow = 'hidden';
      this.getRegion();
      this.getDataChart4();
      this.getDataChart5();
    },

    computed: {
      updateRegion(){
        return this.region;
      }
    },

    watch: {
      updateRegion(){
        this.getRegion();
        this.getDataChart4();
        this.getDataChart5();
      }
    },

    methods: {
      async getRegion(){
        const response = await axios.get(API_URL+'regiones/'+this.region+'/');
        let data = response.data;
        this.data_region = data;
      },


      async getDataChart4(){
        let categoria = this.model_comparacion+1;
        let response = await axios.get(API_URL+this.url_api.url_api+'?region='+this.region+'&categoria='+categoria);
        let data = response.data.results;
        console.log(data);
        let elem;
        let data_final = [];
        for (elem of data) {
          data_final.push(elem.valor.toFixed(2));
        }

        this.data_chart4 = data_final;
        this.categories_chart4 = this.url_api.anios;
        
      },

      async getDataChart5(){

        // let anio = this.url_api.anios[this.model_anio];
        let response = await axios.get(
          API_URL+this.url_api.url_api+'?anio_data='+this.anio+
          '&region='+this.region
        );
        let data = response.data.results;
        console.log(data)
        let elem;
        let data_final = [];
        for (elem of data){
          let datos = {
            x: elem.categoria.tipo,
            y: elem.valor.toFixed(2)
          }
          console.log(datos)
          data_final.push(datos);
        }
        console.log(data_final);
        this.data_chart5 = data_final;
      },

      cambiarAnio(anio){
        this.anio = anio;
        this.getDataChart5();
      },
      cambiarTipo(tipo){
        this.model_comparacion = tipo;
        this.getDataChart4();
      }
    }

}
</script>