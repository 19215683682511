<template>
	<div id="AreaChart_Compare">
	
		<div id="chart">
			<apexchart type="line" ref="chart1" height="350" width="100%"  :options="chartOptions" :series="series" class="chart-wrapper"></apexchart>
		</div>
	
			
	</div>
		
</template>

<!-- Centrar Chart --->
<style scoped>
div.chart-wrapper {
	display: flex;
	align-items: center;
	justify-content: center
}
</style>

<script>
	const API_URL = 'https://api.datari.net/datari/data/'
	import axios from 'axios';

	export default {
		name: "AreaChart_Compare",
		props: ['data_region1','data_region2'],
		data(){
			return {
				
				series: [{
					
					data: [],
					name: ''
				}, {
					
					data: [],
					name: ''
				}],
				chartOptions: {
					chart: {
						
						type: 'area',
						toolbar:{
							show:true,
							tools:{
								download: true,
								selection: false,
								zoom: false,
								zoomin: false,
								zoomout: false,
								pan: false,
								reset: false | '<img src="/static/icons/reset.png" width="20">',
								customIcons: []
							}
						}
					},
					colors: ["#000099","#FFCC00"],
					dataLabels: {
						enabled: false
					},
					stroke: {
						curve: 'smooth'
					},
					xaxis: {
						type: 'category',
						categories: [0]
					},
					yaxis: {
						labels:{
							formatter: function (val) {
								if(val != null){
									return '$'+val.toLocaleString().replace(',','.')
								}
							}
						},
						axisBorder: {
							show:true
						}
					},
					title: {
						text: 'PIB Regional',
						align: 'center',
						style: {
							fontSize:  '23px',
							fontWeight:  'light',
							fontFamily:  undefined,
							color:  '#263238'
							},
					},
					subtitle:{
						text:'2013-2019',
						align: 'center',
						style: {
							fontSize:  '18px',
							fontWeight:  'light',
							fontFamily:  undefined,
							color:  '#263238'
							},
					}
				},

				data1: [],
				data2: [],
				region1: null,
				region2: null

			}
		},
		mounted(){
			
		}, 

		computed:{
			update(){

				return this.data_region2,this.data_region1
			}
		},

		watch: {
			update(){
				this.crear_xaxis(this.data_region1);
				this.crearData();	

			}
		},
		
		methods: {

			crear_data(lista){
				var region;
				var data = [];
				for (region of lista){
					
					var valor = region.valor_pib.toString();
					if(valor.includes('.')){
						var especifico_valor = valor.split('.');
						if(especifico_valor[1].length < 3){
							valor+= '0';
							valor = valor.replace('.','');
							data.push(parseInt(valor));
						}else {
							valor = valor.replace('.','')
							data.push(parseInt(valor));
						}
					}
					else if (valor.length == 1){
						valor += '000';
						data.push(parseInt(valor));
					}
					else{
						data.push(parseInt(valor))
					}

				}
				return data;
			},

			crear_xaxis(lista){
				var i;
				if(this.chartOptions.xaxis.categories.length == 1){
					for(i of lista){
						this.chartOptions.xaxis.categories.push(i.anio);
					}
					this.chartOptions.xaxis.categories.splice(0,1);
				}
				
				return 0

			},



			
			crearData(){
				
				if(this.data_region2.length == 0 && this.data_region1.length != 0){
					this.data1 = this.crear_data(this.data_region1);
					this.region1 = this.data_region1[0].region.nombre;
					this.$refs.chart1.updateSeries([
					{
							data: this.data1,
							name: 'PIB Region de '+ this.region1 +' en $MM'
					},
					{
							data: [],
							name: ''
					}
					]);
				}

				else if(this.data_region2.length == 0 && this.data_region1.length == 0){
					this.$refs.chart1.updateSeries([
					{
							data: [],
							name: ''
					},
					{
							data: [],
							name: ''
					}
					]);
				}
				else if(this.data_region2.length != 0 && this.data_region1.length == 0){
					console.log('22')
					this.data2 = this.crear_data(this.data_region1);
					this.region2 = this.data_region2[0].region.nombre;
					this.$refs.chart1.updateSeries([
					{
							data: [],
							name: ''
					},
					{
							data: this.data2,
							name: 'PIB Region de '+ this.region2 +' en $MM'
					}
					]);
				}
				else{
					this.data1 = this.crear_data(this.data_region1);
					this.data2 = this.crear_data(this.data_region2);
					this.region1 = this.data_region1[0].region.nombre;
					this.region2 = this.data_region2[0].region.nombre;
					this.$refs.chart1.updateSeries([
					{
							data: this.data1,
							name: 'PIB Region de '+ this.region1 +' en $MM'
					},
					{
							data: this.data2,
							name: 'PIB Region de '+ this.region2 +' en $MM'
					}
					]);
					
				}

				

				
			}
			
		}
	}

</script>