<template>
    <div id="LineChart">
        <apexchart width="100%"  ref="chart1" type="line"  :height="height" :options="options" :series="series" class="chart-wrapper"></apexchart>
    </div>
</template>

<!-- Centrar Chart --->
<style scoped>
div.chart-wrapper {
    display: flex;
    align-items: center;
    justify-content: center
}
</style>

<script>
  export default {
    name: 'LineChart',
    props: ['data','labels','serie','titulo','subtitulo','height','tipo_serie'],
    data() {
      return {
        options: {
          chart: {
              id: 'lineChart',
              toolbar:{
                show: true,
                tools: {
                  download: true,
                  selection: false,
                  zoom: false,
                  zoomin: false,
                  zoomout: false,
                  pan: false,
                  reset: false | '<img src="/static/icons/reset.png" width="20">',
                  customIcons: []
                }, 
              }
          },
          xaxis: {
              type: "category",
              categories: [0],
              style: {
                  colors: '#fff',
                  fontSize: '12px'
              },
              labels: {
                  formatter: function(val){
                      return val
                  }
              }
          },
          yaxis:{
            axisBorder:{
              show:false
            },
            axisTicks:{
              show:false
            },
          },
          dataLabels: {
              enabled: false
          },
          stroke: {
              curve: 'straight'
          },
          colors:['#16A085'],
          tooltip: {
              theme: 'dark',
              enabled: true,
              y:{
                formatter: function(val){
                  return val
                }  
              }
          },
        },
    
        series: [{
            name: 'serie-1',
            data: []
        }],
      
        }
    },

    computed: {
      item(){
        return this.data;
      }
    },

    watch: {
      item(){ 
      
        this.$refs.chart1.updateOptions({
          series:[
            {
            data: this.data,
            name: this.serie
            }
          ],
          xaxis:{
            categories: this.labels
          },
          yaxis:{
            axisBorder:{
              show:false
            },
            axisTicks:{
              show:false
            },
            labels: {
              show: false
            }
          },

          title:{
            text: this.titulo,
            align: 'left',
            style: {
              fontSize:  '24px',
              fontWeight:  'light',
              fontFamily:  undefined,
              color:  '#263238'
              },  
          },
          subtitle:{
            text: this.subtitulo,
            align: 'left',
            style: {
              fontSize:  '18px',
              fontWeight:  'light',
              fontFamily:  undefined,
              color:  '#263238'
            },
            offsetY:35  
          },
          tipo_serie: this.tipo_serie,
          tooltip: {
            y: {
              formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
                function formatCL(val){
                  var valor = val.toLocaleString();
                  var largo_string = valor.length
                  var i;
                  var stringFormat = ''; 
                  for(i of valor){
                    if( i === '.' ){
                      i = ',';
                      stringFormat+=i;
                    }
                    else if(i ===','){
                      i = '.';
                      stringFormat+=i;
                    }
                    else{
                      stringFormat+=i;
                    }
                  }
                  return stringFormat;
                };

                let tipo_data = w.config.series[0].name;
                let tipo_serie = w.config.tipo_serie;
                

                if (tipo_data.includes('Ventas en UF')){
                  return '$'+formatCL(value)+' '+tipo_serie
                }
              
                else{
                  return formatCL(value)+' '+tipo_serie
                }

                
                
              }
            }
          }
        });
      }
  },
  methods: {

    formatCL(val){
      var valor = val.toLocaleString();
      var largo_string = valor.length
      var i;
      var stringFormat = ''; 
      for(i of valor){
        if( i === '.' ){
          i = ',';
          stringFormat+=i;
        }
        else if(i ===','){
          i = '.';
          stringFormat+=i;
        }
        else{
          stringFormat+=i;
        }
      }
      return stringFormat;
    },   
  }
      
};
</script>
