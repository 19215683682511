<template>
    <div id="treeMapRegion">
        <div id="chart">
      <apexchart type="treemap" ref="chart1" width="95%" height="475" :options="chartOptions" :series="series" class="chart-wrapper"></apexchart>
      </div>
    </div>
</template>

<!-- Centrar Chart --->
<style scoped>
div.chart-wrapper {
display: flex;
align-items: center;
justify-content: center
}
</style>

<script>
import axios from 'axios';
const API_URL = 'https://api.datari.net/datari/data/';

export default {

  name: 'TreeMapRegion',
  props: ['data','labels','nombre_serie','tipo_dato','colors'] ,
  data: () => ({
      series: [
          {
            data: [
              {x:'',y:''}
            ]
          }
        ],
        chartOptions: {
          legend: {
            show: false
          },
          chart: {
            height: 350,
            type: 'treemap',
            toolbar: {
              show:false
            }
          },
          // title: {
          //   text: 'Distibuted Treemap (different color for each cell)',
          //   align: 'center'
          // },
          colors: ["#000099","#FBC02D","#CC3399",'#003333', '#546E7A', '#FF0033', '#999966', '#669933', '#0000CC', '#6600CC',"#006633",
            '#f48024', '#CC0033',"#CCCC00","#66BB6A"
          ],
          
          plotOptions: {
            treemap: {
              distributed: true,
              enableShades: false
            }
          },
          dataLabels:{
            enabled:true,
            style:{
                colors: ["#fff"],
                fontSize: '18px',
                fontFamily: 'Helvetica, sans-serif',
                fontWeight: 500,
            },
            formatter: function (val, opts) {
             
              var index = opts.dataPointIndex;
              
              var data = (opts.w.globals.series[0]);
              var valor = data[index];
       
              var total = 0;
              data.forEach(function(i){
                total += i
              })

              var porcentaje  = parseFloat(((valor / total)*100).toFixed(2));
              if(porcentaje > 1.0){
                if(val == 'Arica y Parinacota'){
                  return 'Arica'
                }else{
                  return val
                }
              }
              else{
                return ''
              }
            },

          },
          labels: [],
          yaxis: {
            labels: {
              show: true,
            }
          },
          tooltip: {
            fixed: {
                enabled: true,
                position: 'topLeft',
                offsetX: 0,
                offsetY: 0,
            },
            theme: 'dark',
            enableShades:false,
            custom: function({series,seriesIndex,dataPointIndex,w,opt}){
              function formatCL(val){
                var valor = val.toLocaleString();
                var largo_string = valor.length
                var i;
                var stringFormat = ''; 
                for(i of valor){
                  if( i === '.' ){
                    i = ',';
                    stringFormat+=i;
                  }
                  else if(i ===','){
                    i = '.';
                    stringFormat+=i;
                  }
                  else{
                    stringFormat+=i;
                  }
                }
                return stringFormat;
              };
              var valor = series[seriesIndex][dataPointIndex];
              var total = 0;
              var datos = series[0];

              datos.forEach(function(i){
                total += i
              })
              var porcentaje  = parseFloat(((valor / total)*100).toFixed(2));

              var tipo='';
              var valor_data = formatCL(series[seriesIndex][dataPointIndex]);

              var serie = w.config.nombre_serie;
              var tipo_dato = w.config.tipo_dato
              if(serie.includes('Avaluo')){
                valor_data= '$'+formatCL(series[seriesIndex][dataPointIndex]);
              }
              console.log(serie)
              if(w.globals.categoryLabels[dataPointIndex] === 'Metropolitana'){
                tipo = 'Region ';
              }else if(w.globals.categoryLabels[dataPointIndex] === 'Biobío' || w.globals.categoryLabels[dataPointIndex] === 'Maule' ){
                tipo = 'Region del '
              }else{
                tipo = 'Region de '
              }
              return (
                '<div style="margin: 0 auto;width: 300px; height: 200px;opacity:1; background-color: #263238;">'+
                '<h2 class="font-weight-light text-left" style="margin-top:10px; margin-left:15px;">'+tipo+ w.globals.categoryLabels[dataPointIndex]+'</h2>'+
                  '<div class="row">'+
                    '<div class="col-sm-12 ">'+
                      '<h3 class="font-weight-light mt-2 text-left" style="margin-left:15px;font-size:16px">'+serie+'</h3>'+
                      '<h3 class="font-weight-medium text-left" style="margin-top:2px;margin-left:15px">'+valor_data+'</h3>'+
                      '<h4 class="font-weight-medium text-left" style="margin-top:-2px;margin-left:15px">'+tipo_dato+'</h4>'+
                    '</div>'+
                    '<div class="col-sm-12" style="margin-top:-15px">'+
                      '<h4 class="font-weight-light text-left"  style="margin-left:15px;font-size:16px">Participacion regional</h4>'+
                      '<h3 class="font-weight-medium text-left" style="margin-top:2px;margin-left:15px">'+porcentaje+'%</h3>'+
                    '</div>'+
                  '</div>'+
                '</div>'
              );
            },


            x: {
              show: false,
              
            },
            y: {
              show:true,
            },  
          },
          tipo_dato: '',
          nombre_serie: '',

        },        
  }),
  mounted(){
    if(this.data != null){
      if(this.data.length != 0){
        this.$refs.chart1.updateOptions({
          series: [{
            data: this.crearData(this.data,this.labels)
          }],
          labels: this.labels,
          colors: this.crearColores(this.colors,this.data),
          tipo_dato: this.tipo_dato,
          nombre_serie: this.nombre_serie
        })
      }
      else{
        console.log(this.data);
      } 
    }
    
    
  
  },
  computed: {
      item(){
          return this.data;
      }
  },
  watch: {
      item(){
          
          this.$refs.chart1.updateOptions({
              series: [{
                data: this.crearData(this.data,this.labels)
              }],
              labels: this.labels,
              colors: this.crearColores(this.colors,this.data),
              tipo_dato: this.tipo_dato,
              nombre_serie: this.nombre_serie
          })
          // this.series[0].data = this.procesar_data(this.data);

          // this.$refs.chart.updateSeries([
          // {
          //     data: this.procesar_data(this.data)
          // }
          // ]);

      }
  },
  methods: {

    crearColores(colores,data){
      var dato;
      var lista = [];
      var valor;

      for(dato in data){
        valor = {
          color: colores[dato],
          d: data[dato]
        }

        lista.push(valor);      
      } 

      lista.sort(function(a,b){
        if(a.d < b.d){
              return 1;
          }
          if(a.d > b.d){
              return -1;
          }
          return 0
      });
      var temp;
      var i;
      var colores = [];
      for(i of lista){
        temp = i.color;
        colores.push(temp);
      }

      return colores;

  },

    crearData(data,labels){
      var dato;
      var lista = [];
      var valor;

      for(dato in data){
        if(data[dato] != 0){
          valor = {
            x: labels[dato],
            y: data[dato],
          }

          lista.push(valor);
        }
      }

      lista.sort(function(a,b){
        if(a.y < b.y){
            return 1;
        }
        if(a.y > b.y){
            return -1;
        }
        return 0
      });
      console.log(lista)
      return lista;
    }

    
}
}

</script>