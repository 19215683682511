<template>
	<div>
		<div id="header">
			<v-app-bar color="light-blue darken-2" class="rounded" flat dark>
        <h2 class="font-weight-medium">{{url_api.nombre_data}}</h2>
        <v-col cols=2>
          <v-select
            v-model="anio"
            :items ="url_api.anios"
            label ="Año"
            hide-no-data
            hide-details
            class="mx-2"
            flat
            solo-inverted
            dense
            v-on:change="cambiarAnio"
            >
          </v-select>
        </v-col>
        <v-spacer/>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              dark
              v-bind="attrs"
              v-on="on"
            >
              mdi-information-outline
            </v-icon>
          </template>
          <span>Fuente: {{url_api.fuente}}</span>
       </v-tooltip>
      </v-app-bar>
		</div>
		<div id="body" style="margin-top:-5px;">
			<v-row>
				<v-col cols=3>
					<v-card flat>
						<v-card-title class="justify-center">
							<h3 class="font-weight-medium">{{nombre_region}}</h3>
						</v-card-title>
						<v-card-text class="text-center" style="margin-top:-10px;">
							<h3 class="font-weight-light">Comuna con mayores ingresos</h3>
							<h2 class="font-weight-medium mt-2">{{max_comuna.text}}</h2>
							<h2 class="font-weight-medium mt-2">${{
								max_comuna.value
							}} Millones</h2>

						</v-card-text>
					</v-card>
					<v-card flat class="mt-2">
						<v-card-title class="justify-center">
							<h3 class="font-weight-medium">{{nombre_region}}</h3>
						</v-card-title>
						<v-card-text class="text-center" style="margin-top:-10px;">
							<h3 class="font-weight-light">Comuna con menores ingresos</h3>
							<h2 class="font-weight-medium mt-2">{{min_comuna.text}}</h2>
							<h2 class="font-weight-medium mt-2">${{
								min_comuna.value
							}} Millones</h2>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col>
					<v-card flat height=313>
						<v-card-text>
							 <column-chart-perc :data="series_graph1" :categories="labels_graph1" :colors="colors_graph1"
                :titulo="'Ingresos Capitales Regionales'" :subtitulo="anio" :nombre_serie="'Ingresos'" :tipo_data="'Millones'" :height="285" />
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
			<v-row style="margin-top:-15px">
				<v-col cols=7>
					<v-card flat>
						<v-card-text>
							<TreeMap class="ml-8" :data="series_graph3" :height="430"
               	:titulo="'Distribución comunal de ingresos - '+anio" :unidad="'Millones'" :serie="'Ingresos'"/>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols=5>
					<v-card flat>
						<v-card-text>
							<Ranking :height="380" :titulo="ranking_title+' - '+anio" :data="series_graph2" :categorias="labels_graph2" :serie1="'Ingresos'"
								:unidad="'Millones'"
							/>
							<div  style="margin-top:5px; 
								display: flex;
							  align-items: center;
							  justify-content: center">
								<v-slide-group
	                  v-model="boton2"
	                  mandatory
	                  center-active
	                  v-on:change="cambiarTipoGraph2"
	                  dense 
	                  >
	                  <v-slide-item
	                  v-for="(boton) in botones2"
	                  :key="boton"
	                  v-slot="{ active, toggle }"
	                  >
	                      <v-btn
	                      class="ma-2"
								        color="grey lighten-1"
								        small
	                      :input-value="active"
	                      active-class="light-blue darken-4 white--text"
	                      depressed
	                      
	                      @click="toggle"
	                      >
	                      {{boton}}
	                    </v-btn>
	                  </v-slide-item>
	                </v-slide-group>
	              </div>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
const API_URL = 'https://api.datari.net/datari/data/';

import ColumnChartPerc from '../../components/chartsV2/ColumnChartPercComuna.vue';
import Ranking from '../../components/charts/RankingDobleSerie.vue';
import TreeMap from '../../components/chartsV2/TreeMap2.vue';
export default {

  name: 'View21',
  components:{
  	ColumnChartPerc,
  	Ranking,
  	TreeMap
  },
  props:['url_api','region'],
  data () {
    return {
    	nombre_region:null,
    	anio: 2015,
    	max_comuna: {
    		'text':'',
    		'value':0
    	},
    	min_comuna: {
    		'text':'',
    		'value':0
    	},
    	boton2:0,
    	botones2: ['Mayores ingresos','Menores ingresos'],
    	series_graph1: [],
    	labels_graph1:[],
    	colors_graph1:[],
    	series_graph2: [],
    	labels_graph2: [],
    	series_graph3: [],
    	ranking_title:null,
    	data_ranking_max:[],
    	data_ranking_min:[]
    }
  },
  mounted(){
  	this.getDataRegion();
  	this.getDataPais();
  },
  computed:{
  	updateRegion(){
  		return this.region
  	}
  },
  watch:{
  	updateRegion(){
  		this.getDataRegion();
  		this.getDataPais();
  	}
  },
  methods:{
  	async getDataRegion(){
  		let response = await axios.get(
        API_URL+'regiones/'+this.region
      );
      let data = response.data;
      this.nombre_region = data.nombre;

      response = await axios.get(
      	API_URL+this.url_api.url_api+'?region='+this.region+'&anio_data='+this.anio
      )

      data = response.data.results.data_region;

      let data_comunas_region = data.list;

      let data_graph3 = this.getGraphicData(data_comunas_region,this.nombre_region,1);

      this.series_graph3 = data_graph3.series;

      this.max_comuna.text = data.max_ingresos_comuna.comuna.comuna;
      this.max_comuna.value = this.formatCL(data.max_ingresos_comuna.ingresos);

      this.min_comuna.text = data.min_ingresos_comuna.comuna.comuna;
      this.min_comuna.value = this.formatCL(data.min_ingresos_comuna.ingresos);
  	},

  	async getDataPais(){
  		let response = await axios.get(
  			API_URL+this.url_api.url_api+'?anio_data='+this.anio
  		)

  		let data = response.data;

  		let data_capitales = data.data_capitales;

  		let data_graph1 = this.getGraphicData(data_capitales,this.nombre_region,2);

  		this.series_graph1 = data_graph1.series;
  		this.labels_graph1 = data_graph1.categorias;
  		this.colors_graph1 = data_graph1.colors; 

  		this.data_ranking_max = data.data_pais.ranking_mayores_ingresos;
  		this.data_ranking_min = data.data_pais.ranking_menores_ingresos;

  		let data_graph2;
  		if(this.boton2 === 0){
  			data_graph2 = this.getGraphicData(this.data_ranking_max,this.nombre_region,3);
  			this.ranking_title = 'Ranking comunas con mayores ingresos';

  		}
  		else 	if(this.boton2 === 1){
  			data_graph2 = this.getGraphicData(this.data_ranking_min,this.nombre_region,3);
  			this.ranking_title = 'Ranking comunas con menores ingresos';
  		}
  		this.series_graph2 = data_graph2.series;
  		this.labels_graph2 = data_graph2.categorias;
  	 
  	},

  	getGraphicData(data,nombre_region,labelType){
        // Tipo 1 -> Años, Tipo 2 -> Regiones
        var element;
        var labels = [];
        var series = [];
        var colors = [];

        if(labelType == 1){
          for(element of data){
            let value = {
              x: element.comuna.comuna,
              y: element.ingresos
            }
            series.push(value);  
          }

          series.sort(function(a,b){
            if(a.y < b.y){
                  return 1;
              }
              if(a.y > b.y){
                  return -1;
              }
              return 0
          });

          colors = ['#0092CD'];
        }
        else if(labelType == 2){
          for(element of data){
          	
            if(!labels.includes(element.nombre)){
            	labels.push(element.nombre)
              
              if(element.region === nombre_region){
                colors.push('#1A5276');
              }
              else{
                colors.push("#B5B5B5");
              }
              series.push(element.ingresos);
            }
          }
        }

        else if(labelType == 3){
        	for(element of data){
        		if(!labels.includes(element.comuna.comuna)){
              if(element.comuna.comuna === "O'higgins"){
                labels.push("O'Higgins");
              }
              else{
                labels.push(element.comuna.comuna);
              }
        			
        		}
        		series.push(element.ingresos);
        	}
        }

       
        return {
                'categorias': labels,
                'series': series,
                'colors': colors
               }
      },

    cambiarTipoGraph2(value){
    	this.boton2 = value;
    	let data_graph2;
  		if(this.boton2 === 0){
  			data_graph2 = this.getGraphicData(this.data_ranking_max,this.nombre_region,3);
  			this.ranking_title = 'Comunas con mayores ingresos';
  		}
  		else if(this.boton2 === 1){
  			data_graph2 = this.getGraphicData(this.data_ranking_min,this.nombre_region,3);
  			this.ranking_title = 'Comunas con menores ingresos';
  		}
  		this.series_graph2 = data_graph2.series;
  		this.labels_graph2 = data_graph2.categorias;
  	 
    },


    cambiarAnio(anio){
    	this.anio = anio;
    	this.getDataRegion();
    	this.getDataPais();
    },
  	formatCL(val){
      var valor = val.toLocaleString();
      var largo_string = valor.length
      var i;
      var stringFormat = ''; 
      for(i of valor){
        if( i === '.' ){
          i = ',';
          stringFormat+=i;
        }
        else if(i ===','){
          i = '.';
          stringFormat+=i;
        }
        else{
          stringFormat+=i;
        }
      }
      return stringFormat;
    },


  }
}
</script>

<style lang="css" scoped>
</style>