<template>
  <div id="View14Region">
    <div id="header">
      <v-app-bar color="light-blue darken-2" class="rounded" flat dark>
        <h2 class="font-weight-medium">{{url_api.nombre_data}}</h2>
        <v-col style="margin-left: 20px;" cols="2">
          <v-select
              v-model="tipo"
              :items ="url_api.tipos"
              label ="Año"
              item-text="id"
              flat
              solo-inverted
              dense
              hide-no-data
              class="mx-2"
              hide-details
              v-on:change="cambiarTipo">
            </v-select>
        </v-col>
        <v-col style="margin-left: -15px;" cols="2">
           <v-select
              v-model="anio"
              :items ="url_api.anios"
              label ="Año"
              item-text="id"
              flat
              solo-inverted
              dense
              hide-no-data
              class="mx-2"
              hide-details
              v-on:change="cambiarAnio">
            </v-select>
        </v-col>
        <v-spacer/>
        <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
           
            dark
            v-bind="attrs"
            v-on="on"
          >
            mdi-information-outline
          </v-icon>
        </template>
        <span>Fuente: {{url_api.fuente}}</span>
      </v-tooltip>
   </v-app-bar>
 </div>
 <div id="body" style="margin-top:-5px;">

    <v-row class="mt-0">
      <v-col>
        <v-card height="100%" flat>
          <v-card-title class="justify-center">
            <h3 class="font-weight-medium">País</h3>
          </v-card-title>
          <v-card-text class="text-center">
            <h2 class="font-weight-light">Avaluo Predios {{tipo}}</h2>
            <h1 class="font-weight-medium mt-4">${{total_pais}}</h1>
            <h3 class="mt-3 font-weight-medium">{{tipo_dato}}</h3>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card height="100%" flat>
          <v-card-title class="justify-center">
            <h3 class="font-weight-medium">{{nombre_region}}</h3>
          </v-card-title>
          <v-card-text class="text-center">
            <h2 class="font-weight-light">Avaluo Predios {{tipo}}</h2>
            <h1 class="font-weight-medium mt-4">${{total_region}}</h1>
            <h3 class="mt-3 font-weight-medium">{{tipo_dato}}</h3>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card height="100%" flat>
          <v-card-title class="justify-center">
            <h3 class="font-weight-medium">Variación Anual {{anio-1}}-{{anio}} </h3>
          </v-card-title>
          <v-card-text class="text-center">
            <v-progress-circular color="#1A5276" :size="90" :width="9"
              :rotate="-90"  :value="variacion_anual">
                {{variacion_anual}}%       
            </v-progress-circular>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row style="margin-top: -15px;">
      <v-col cols="4">
        <v-card flat height="600">
          <v-card-title class="justify-center">
            <h3 class="font-weight-medium">Avaluo Total</h3>
          </v-card-title>
          <v-card-text class="text-center">
            <v-row class="my-4">
              <v-col cols="12">
                  <h1 class="font-weight-medium">{{nombre_region}}</h1>
                  <h2 class="font-weight-light mt-4">Avaluo Total {{anio}}</h2>
                  <h1 class="font-weight-medium mt-4">${{total_avaluo_region}}</h1>
                  <h3 class="mt-2 font-weight-medium">{{tipo_dato}}</h3>
              </v-col>
              <v-col cols="12" class="mt-4">
                <h1 class="font-weight-medium">País</h1>
                <h2 class="font-weight-light mt-4">Avaluo Total {{anio}}</h2>
                <h1 class="font-weight-medium mt-4">${{total_avaluo_pais}}</h1>
                <h3 class="mt-2 font-weight-medium">{{tipo_dato}}</h3>
                <br>
                <h2 class="font-weight-light mt-4">Variación Predios {{tipo}} {{anio-1}}-{{anio}}</h2>
                <v-progress-circular color="#229954" :size="90" :width="10"
                  :rotate="-90"  class="mt-4" :value="variacion_anual_pais">
                    {{variacion_anual_pais}}%       
                </v-progress-circular>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col col="8">
        <v-card flat height="600">
          <v-card-title class="justify-center">
            <v-row>
              <v-col cols="12">
                <h3 class="font-weight-medium">Comparación Regional</h3>
              </v-col>
              <v-col cols="12" style="margin-top:-25px;">
                <h4 class="font-weight-light subtitle">Avaluo {{tipo}}</h4>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text style="margin-top:-25px;">
          
          <TreeMap :data="data_graph1" :labels="labels_graph1" :nombre_serie="'Avaluo '+tipo"
              :tipo_dato="tipo_dato" :colors="colors_graph1" class="ml-2"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
  </div>
</template>

<style scoped>
div.chart-wrapper {
  display: flex;
  align-items: center;
  justify-content: center
}
</style>

<script>
import axios from 'axios';
import TreeMap from '../../components/charts/TreeMapRegion.vue';
const API_URL = 'https://api.datari.net/datari/data/';

export default {
  components: {
    TreeMap
  },
  name: 'View14Region',
  props: ['url_api','region'],
  data: () => ({
    tipo: null,
    anio: null,
    total_pais: null,
    nombre_region: null,
    data_anual_region: [],
    data_anual_pais: [],
    data_anual: [],
    total_pais: null,
    total_region: null,
    variacion_anual: null,
    variacion_anual_pais: null,
    tipo_dato: null,
    total_avaluo_region: null,
    total_avaluo_pais: null,
    data_graph1: null,
    colors_graph1: null,
    labels_graph1: null
  }),
  created(){
    document.documentElement.style.overflow = 'hidden'; 
    this.tipo = this.url_api.tipos[0];
    this.anio = this.url_api.anios[0];

    this.crearData();
  },
  computed: {
    updateItem(){
      return this.region
    }
  },
  watch: {
    updateItem(){
      this.crearData();
    }
  },
  methods: {

    crearData(){
      //Data Anual Región
      axios.get(API_URL+this.url_api.url_api+'?region='+this.region+'&anio='+this.anio)
      .then(response => {
        this.data_anual_region = response.data.results[0];
        this.nombre_region = this.data_anual_region.region.nombre;

        this.total_region = this.formatCL(
                              this.selectData(this.data_anual_region).avaluo
                            );

        this.variacion_anual = this.selectData(this.data_anual_region).variacion;

        this.total_avaluo_region = this.formatCL(this.totalAvaluo(this.data_anual_region));

        this.tipo_dato = this.data_anual_region.tipo_dato;
      });

      //Data Anual País ID 18
      axios.get(API_URL+this.url_api.url_api+'?region=18&anio='+this.anio)
      .then(response => {
        this.data_anual_pais = response.data.results[0];

        this.total_pais = this.formatCL(
                              this.selectData(this.data_anual_pais).avaluo
                            );

        this.variacion_anual_pais = this.selectData(this.data_anual_pais).variacion;  

        this.total_avaluo_pais = this.formatCL(this.totalAvaluo(this.data_anual_pais));    
      })

      //Data Anual
      axios.get(API_URL+this.url_api.url_api+'?anio='+this.anio)
      .then(response => {
        this.data_anual = response.data.results;
        this.data_graph1 = this.getGraphicData(this.data_anual,2).series;
        this.colors_graph1 = this.getGraphicData(this.data_anual,2).colors;
        this.labels_graph1 = this.getGraphicData(this.data_anual,2).categorias;
      });
    },

    totalAvaluo(data){
      var total;
      total = data.avaluos_afectos + data.avaluos_exentos;
      return total;
    },

    cambiarAnio(anio){
      this.anio = anio;
      this.crearData();
    },

    cambiarTipo(tipo){
      this.tipo = tipo;

      this.total_region = this.formatCL(
                              this.selectData(this.data_anual_region).avaluo
                            );

      this.variacion_anual = this.selectData(this.data_anual_region).variacion;

     this.total_pais = this.formatCL(
                            this.selectData(this.data_anual_pais).avaluo
                          );

      this.variacion_anual_pais = this.selectData(this.data_anual_pais).variacion;    

      this.data_graph1 = this.getGraphicData(this.data_anual,2).series;
      this.colors_graph1 = this.getGraphicData(this.data_anual,2).colors;
      this.labels_graph1 = this.getGraphicData(this.data_anual,2).categorias;

    },

    selectData(data){
      var select;
      var variacion;
      var return_data;

      if(this.tipo === this.url_api.tipos[0]){
        select = data.avaluos_afectos;
        variacion = data.variacion_afectos;
      }
      else if(this.tipo === this.url_api.tipos[1]){
        select = data.avaluos_exentos;
        variacion = data.variacion_exentos;
      }

      return_data = {
        'avaluo': select,
        'variacion': variacion
      }

      return return_data;
     
    },

    formatCL(val){
      var valor = val.toLocaleString();
      var largo_string = valor.length
      var i;
      var stringFormat = ''; 
      for(i of valor){
        if( i === '.' ){
          i = ',';
          stringFormat+=i;
        }
        else if(i ===','){
          i = '.';
          stringFormat+=i;
        }
        else{
          stringFormat+=i;
        }
      }
      return stringFormat;
    },
    getGraphicData(data,labelType){
      // Tipo 1 -> Años, Tipo 2 -> Regiones
      var element;
      var labels = [];
      var series = [];
      var colors = [];

      if(labelType == 1){
        for(element of data){
          if(!labels.includes(element.anio)){
            labels.push(element.anio);
          }
          series.push(element.superficie);
        }
      }
      else if(labelType == 2){
        for(element of data){
          if(!labels.includes(element.region.nombre) && element.region.nombre !== 'Nacional'){
            labels.push(element.region.nombre);
            colors.push(element.region.color);
            series.push((this.selectData(element).avaluo));
          }
          
        }
      }
     
      return {
              'categorias': labels,
              'series': series,
              'colors': colors
             }
    },
  }
}
</script>