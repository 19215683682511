<template>
  <div class='chart'>
    <chart-sankey :data='chartData' :config='chartConfig'></chart-sankey>
  </div>
</template>

<script>
  import { ChartSankey } from 'vue-d2b'

  export default {
    props:['nodes','links','tipo_data'],
    components: {
      ChartSankey
    },
    data () {
      return {
        chartData: {
          nodes: [
            {
              "name": "Bencina",
              "color": "#138D75",
            },
            {
              "name": "Diesel",
              "color": "#263238",
              
            },
            {
              "name": "Gas",
              "color": "#FBC02D",
              
            },
            {
              "name": "Eléctrico",
              "color": "#304FFE",
              
            },
            {
              "name": "Catalitico",
              "color": "#7D3C98"
            },
            {
              "name": "No Catalitico",
              "color": "#DC7633",

            },
            {
              "name": "Magallanes",
              "color": "#263238"
            },
            {
              "name": "Aysén",
              "color": "#0D47A1"
            },
            {
              "name": "Los Lagos",
              "color": "#1976D2"
            },
            {
              "name": "La Araucanía",
              "color": "#C0CA33"
            },
            {
              "name": "Biobío",
              "color": "#7CB342"
            },
            {
              "name": "Maule",
              "color": "#66BB6A"
            },
            {
              "name": "Valparaíso",
              "color": "#880E4F"
            },
            {
              "name": "Coquimbo",
              "color": "#D81B60"
            },
            {
              "name": "Atacama",
              "color": "#E53935"
            },
            {
              "name": "Antofagasta",
              "color": "#F57C00"
            },
            {
              "name": "Tarapacá",
              "color": "#FFB300"
            },
            {
              "name": "Arica y Parinacota",
              "color": "#FDD835"
            },
            {
              "name": "Metropolitana",
              "color": "#4E342E"
            },
            {
              "name": "Los Ríos",
              "color": "#BBDEFB"
            },
            {
              "name": "Ñuble",
              "color": "#C8E6C9"
            }
          ],
          links:[
            {
              "source": "Magallanes",
              "target": "Bencina",
              "value": 48257,
              "tooltip": "Cantidad de Vehículos: 48.257 Vehículos"
            },
            {
              "source": "Magallanes",
              "target": "Diesel",
              "value": 26395,
              "tooltip": "Cantidad de Vehículos: 26.395 Vehículos"
            },
            {
              "source": "Magallanes",
              "target": "Gas",
              "value": 16635,
              "tooltip": "Cantidad de Vehículos: 3.327 Vehículos"
            },
            {
              "source": "Magallanes",
              "target": "Eléctrico",
              "value": 200,
              "tooltip": "Cantidad de Vehículos: 10 Vehículos"
            },
            {
              "source": "Catalitico",
              "target": "Magallanes",
              "value": 74731,
              "tooltip": "Cantidad de Vehículos: 74.731 Vehículos"
            },
            {
              "source": "No Catalitico",
              "target": "Magallanes",
              "value": 3258,
              "tooltip": "Cantidad de Vehículos: 3.258 Vehículos"
            },
            {
              "source": "Aysén",
              "target": "Bencina",
              "value": 23440,
              "tooltip": "Cantidad de Vehículos: 23.440 Vehículos"
            },
            {
              "source": "Aysén",
              "target": "Diesel",
              "value": 19693,
              "tooltip": "Cantidad de Vehículos: 19.693 Vehículos"
            },
            {
              "source": "Aysén",
              "target": "Gas",
              "value": 15,
              "tooltip": "Cantidad de Vehículos: 3 Vehículos"
            },
            {
              "source": "Aysén",
              "target": "Eléctrico",
              "value": 240,
              "tooltip": "Cantidad de Vehículos: 12 Vehículos"
            },
            {
              "source": "Catalitico",
              "target": "Aysén",
              "value": 41516,
              "tooltip": "Cantidad de Vehículos: 41.516 Vehículos"
            },
            {
              "source": "No Catalitico",
              "target": "Aysén",
              "value": 1632,
              "tooltip": "Cantidad de Vehículos: 1.632 Vehículos"
            },
            {
              "source": "Los Lagos",
              "target": "Bencina",
              "value": 177471,
              "tooltip": "Cantidad de Vehículos: 177.471 Vehículos"
            },
            {
              "source": "Los Lagos",
              "target": "Diesel",
              "value": 87156,
              "tooltip": "Cantidad de Vehículos: 87.156 Vehículos"
            },
            {
              "source": "Los Lagos",
              "target": "Gas",
              "value": 190,
              "tooltip": "Cantidad de Vehículos: 38 Vehículos"
            },
            {
              "source": "Los Lagos",
              "target": "Eléctrico",
              "value": 520,
              "tooltip": "Cantidad de Vehículos: 26 Vehículos"
            },
            {
              "source": "Catalitico",
              "target": "Los Lagos",
              "value": 256240,
              "tooltip": "Cantidad de Vehículos: 256.240 Vehículos"
            },
            {
              "source": "No Catalitico",
              "target": "Los Lagos",
              "value": 8451,
              "tooltip": "Cantidad de Vehículos: 8.451 Vehículos"
            },
            {
              "source": "La Araucanía",
              "target": "Bencina",
              "value": 174644,
              "tooltip": "Cantidad de Vehículos: 174.644 Vehículos"
            },
            {
              "source": "La Araucanía",
              "target": "Diesel",
              "value": 76574,
              "tooltip": "Cantidad de Vehículos: 76.574 Vehículos"
            },
            {
              "source": "La Araucanía",
              "target": "Gas",
              "value": 145,
              "tooltip": "Cantidad de Vehículos: 29 Vehículos"
            },
            {
              "source": "La Araucanía",
              "target": "Eléctrico",
              "value": 780,
              "tooltip": "Cantidad de Vehículos: 39 Vehículos"
            },
            {
              "source": "Catalitico",
              "target": "La Araucanía",
              "value": 239141,
              "tooltip": "Cantidad de Vehículos: 239.141 Vehículos"
            },
            {
              "source": "No Catalitico",
              "target": "La Araucanía",
              "value": 12145,
              "tooltip": "Cantidad de Vehículos: 12.145 Vehículos"
            },
            {
              "source": "Biobío",
              "target": "Bencina",
              "value": 342507,
              "tooltip": "Cantidad de Vehículos: 342.507 Vehículos"
            },
            {
              "source": "Biobío",
              "target": "Diesel",
              "value": 114220,
              "tooltip": "Cantidad de Vehículos: 114.220 Vehículos"
            },
            {
              "source": "Biobío",
              "target": "Gas",
              "value": 355,
              "tooltip": "Cantidad de Vehículos: 71 Vehículos"
            },
            {
              "source": "Biobío",
              "target": "Eléctrico",
              "value": 2140,
              "tooltip": "Cantidad de Vehículos: 107 Vehículos"
            },
            {
              "source": "Catalitico",
              "target": "Biobío",
              "value": 438838,
              "tooltip": "Cantidad de Vehículos: 438.838 Vehículos"
            },
            {
              "source": "No Catalitico",
              "target": "Biobío",
              "value": 18067,
              "tooltip": "Cantidad de Vehículos: 18.067 Vehículos"
            },
            {
              "source": "Maule",
              "target": "Bencina",
              "value": 279245,
              "tooltip": "Cantidad de Vehículos: 279.245 Vehículos"
            },
            {
              "source": "Maule",
              "target": "Diesel",
              "value": 120618,
              "tooltip": "Cantidad de Vehículos: 120.618 Vehículos"
            },
            {
              "source": "Maule",
              "target": "Gas",
              "value": 1435,
              "tooltip": "Cantidad de Vehículos: 287 Vehículos"
            },
            {
              "source": "Maule",
              "target": "Eléctrico",
              "value": 2700,
              "tooltip": "Cantidad de Vehículos: 135 Vehículos"
            },
            {
              "source": "Catalitico",
              "target": "Maule",
              "value": 375506,
              "tooltip": "Cantidad de Vehículos: 375.506 Vehículos"
            },
            {
              "source": "No Catalitico",
              "target": "Maule",
              "value": 24779,
              "tooltip": "Cantidad de Vehículos: 24.779 Vehículos"
            },
            {
              "source": "Valparaíso",
              "target": "Bencina",
              "value": 446819,
              "tooltip": "Cantidad de Vehículos: 446.819 Vehículos"
            },
            {
              "source": "Valparaíso",
              "target": "Diesel",
              "value": 143771,
              "tooltip": "Cantidad de Vehículos: 143.771 Vehículos"
            },
            {
              "source": "Valparaíso",
              "target": "Gas",
              "value": 760,
              "tooltip": "Cantidad de Vehículos: 152 Vehículos"
            },
            {
              "source": "Valparaíso",
              "target": "Eléctrico",
              "value": 5180,
              "tooltip": "Cantidad de Vehículos: 259 Vehículos"
            },
            {
              "source": "Catalitico",
              "target": "Valparaíso",
              "value": 568852,
              "tooltip": "Cantidad de Vehículos: 568.852 Vehículos"
            },
            {
              "source": "No Catalitico",
              "target": "Valparaíso",
              "value": 22149,
              "tooltip": "Cantidad de Vehículos: 22.149 Vehículos"
            },
            {
              "source": "Coquimbo",
              "target": "Bencina",
              "value": 172460,
              "tooltip": "Cantidad de Vehículos: 172.460 Vehículos"
            },
            {
              "source": "Coquimbo",
              "target": "Diesel",
              "value": 64274,
              "tooltip": "Cantidad de Vehículos: 64.274 Vehículos"
            },
            {
              "source": "Coquimbo",
              "target": "Gas",
              "value": 215,
              "tooltip": "Cantidad de Vehículos: 43 Vehículos"
            },
            {
              "source": "Coquimbo",
              "target": "Eléctrico",
              "value": 1900,
              "tooltip": "Cantidad de Vehículos: 95 Vehículos"
            },
            {
              "source": "Catalitico",
              "target": "Coquimbo",
              "value": 227262,
              "tooltip": "Cantidad de Vehículos: 227.262 Vehículos"
            },
            {
              "source": "No Catalitico",
              "target": "Coquimbo",
              "value": 9610,
              "tooltip": "Cantidad de Vehículos: 9.610 Vehículos"
            },
            {
              "source": "Atacama",
              "target": "Bencina",
              "value": 65542,
              "tooltip": "Cantidad de Vehículos: 65.542 Vehículos"
            },
            {
              "source": "Atacama",
              "target": "Diesel",
              "value": 30065,
              "tooltip": "Cantidad de Vehículos: 30.065 Vehículos"
            },
            {
              "source": "Atacama",
              "target": "Gas",
              "value": 40,
              "tooltip": "Cantidad de Vehículos: 8 Vehículos"
            },
            {
              "source": "Atacama",
              "target": "Eléctrico",
              "value": 500,
              "tooltip": "Cantidad de Vehículos: 25 Vehículos"
            },
            {
              "source": "Catalitico",
              "target": "Atacama",
              "value": 92407,
              "tooltip": "Cantidad de Vehículos: 92.407 Vehículos"
            },
            {
              "source": "No Catalitico",
              "target": "Atacama",
              "value": 3233,
              "tooltip": "Cantidad de Vehículos: 3.233 Vehículos"
            },
            {
              "source": "Antofagasta",
              "target": "Bencina",
              "value": 123122,
              "tooltip": "Cantidad de Vehículos: 123.122 Vehículos"
            },
            {
              "source": "Antofagasta",
              "target": "Diesel",
              "value": 47529,
              "tooltip": "Cantidad de Vehículos: 47.529 Vehículos"
            },
            {
              "source": "Antofagasta",
              "target": "Gas",
              "value": 95,
              "tooltip": "Cantidad de Vehículos: 19 Vehículos"
            },
            {
              "source": "Antofagasta",
              "target": "Eléctrico",
              "value": 280,
              "tooltip": "Cantidad de Vehículos: 14 Vehículos"
            },
            {
              "source": "Catalitico",
              "target": "Antofagasta",
              "value": 167945,
              "tooltip": "Cantidad de Vehículos: 167.945 Vehículos"
            },
            {
              "source": "No Catalitico",
              "target": "Antofagasta",
              "value": 2739,
              "tooltip": "Cantidad de Vehículos: 2.739 Vehículos"
            },
            {
              "source": "Tarapacá",
              "target": "Bencina",
              "value": 85721,
              "tooltip": "Cantidad de Vehículos: 85.721 Vehículos"
            },
            {
              "source": "Tarapacá",
              "target": "Diesel",
              "value": 53416,
              "tooltip": "Cantidad de Vehículos: 53.416 Vehículos"
            },
            {
              "source": "Tarapacá",
              "target": "Gas",
              "value": 1815,
              "tooltip": "Cantidad de Vehículos: 363 Vehículos"
            },
            {
              "source": "Tarapacá",
              "target": "Eléctrico",
              "value": 580,
              "tooltip": "Cantidad de Vehículos: 29 Vehículos"
            },
            {
              "source": "Catalitico",
              "target": "Tarapacá",
              "value": 137096,
              "tooltip": "Cantidad de Vehículos: 137.096 Vehículos"
            },
            {
              "source": "No Catalitico",
              "target": "Tarapacá",
              "value": 2433,
              "tooltip": "Cantidad de Vehículos: 2.433 Vehículos"
            },
            {
              "source": "Arica y Parinacota",
              "target": "Bencina",
              "value": 52783,
              "tooltip": "Cantidad de Vehículos: 52.783 Vehículos"
            },
            {
              "source": "Arica y Parinacota",
              "target": "Diesel",
              "value": 34301,
              "tooltip": "Cantidad de Vehículos: 34.301 Vehículos"
            },
            {
              "source": "Arica y Parinacota",
              "target": "Gas",
              "value": 6660,
              "tooltip": "Cantidad de Vehículos: 1.332 Vehículos"
            },
            {
              "source": "Arica y Parinacota",
              "target": "Eléctrico",
              "value": 160,
              "tooltip": "Cantidad de Vehículos: 8 Vehículos"
            },
            {
              "source": "Catalitico",
              "target": "Arica y Parinacota",
              "value": 84666,
              "tooltip": "Cantidad de Vehículos: 84.666 Vehículos"
            },
            {
              "source": "No Catalitico",
              "target": "Arica y Parinacota",
              "value": 3758,
              "tooltip": "Cantidad de Vehículos: 3.758 Vehículos"
            },
            {
              "source": "Metropolitana",
              "target": "Bencina",
              "value": 1723480,
              "tooltip": "Cantidad de Vehículos: 1.723.480 Vehículos"
            },
            {
              "source": "Metropolitana",
              "target": "Diesel",
              "value": 468239,
              "tooltip": "Cantidad de Vehículos: 468.239 Vehículos"
            },
            {
              "source": "Metropolitana",
              "target": "Gas",
              "value": 20120,
              "tooltip": "Cantidad de Vehículos: 4.024 Vehículos"
            },
            {
              "source": "Metropolitana",
              "target": "Eléctrico",
              "value": 38760,
              "tooltip": "Cantidad de Vehículos: 1.938 Vehículos"
            },
            {
              "source": "Catalitico",
              "target": "Metropolitana",
              "value": 2174830,
              "tooltip": "Cantidad de Vehículos: 2.174.830 Vehículos"
            },
            {
              "source": "No Catalitico",
              "target": "Metropolitana",
              "value": 22857,
              "tooltip": "Cantidad de Vehículos: 22.857 Vehículos"
            },
            {
              "source": "Los Ríos",
              "target": "Bencina",
              "value": 74840,
              "tooltip": "Cantidad de Vehículos: 74.840 Vehículos"
            },
            {
              "source": "Los Ríos",
              "target": "Diesel",
              "value": 35673,
              "tooltip": "Cantidad de Vehículos: 35.673 Vehículos"
            },
            {
              "source": "Los Ríos",
              "target": "Gas",
              "value": 185,
              "tooltip": "Cantidad de Vehículos: 37 Vehículos"
            },
            {
              "source": "Los Ríos",
              "target": "Eléctrico",
              "value": 340,
              "tooltip": "Cantidad de Vehículos: 17 Vehículos"
            },
            {
              "source": "Catalitico",
              "target": "Los Ríos",
              "value": 106149,
              "tooltip": "Cantidad de Vehículos: 106.149 Vehículos"
            },
            {
              "source": "No Catalitico",
              "target": "Los Ríos",
              "value": 4418,
              "tooltip": "Cantidad de Vehículos: 4.418 Vehículos"
            },
            {
              "source": "Ñuble",
              "target": "Bencina",
              "value": 110004,
              "tooltip": "Cantidad de Vehículos: 110.004 Vehículos"
            },
            {
              "source": "Ñuble",
              "target": "Diesel",
              "value": 46815,
              "tooltip": "Cantidad de Vehículos: 46.815 Vehículos"
            },
            {
              "source": "Ñuble",
              "target": "Gas",
              "value": 100,
              "tooltip": "Cantidad de Vehículos: 20 Vehículos"
            },
            {
              "source": "Ñuble",
              "target": "Eléctrico",
              "value": 440,
              "tooltip": "Cantidad de Vehículos: 22 Vehículos"
            },
            {
              "source": "Catalitico",
              "target": "Ñuble",
              "value": 146240,
              "tooltip": "Cantidad de Vehículos: 146.240 Vehículos"
            },
            {
              "source": "No Catalitico",
              "target": "Ñuble",
              "value": 10621,
              "tooltip": "Cantidad de Vehículos: 10.621 Vehículos"
            }
          ]
        },

        chartConfig (config) {
          config
            .sankey()
            .sankey()
            .nodePadding(15)
            

          config
            .sankey()
            .nodeDraggableY(true)
            

          config
            .nodeTooltip().html((d) => {
              function format_number(val){
                var valor = val.toLocaleString();
                var largo_string = valor.length
                var i;
                var stringFormat = ''; 
                for(i of valor){
                  if( i === '.' ){
                    i = ',';
                    stringFormat+=i;
                  }
                  else if(i ===','){
                    i = '.';
                    stringFormat+=i;
                  }
                  else{
                    stringFormat+=i;
                  }
                }
                return stringFormat;
              };

              const value = format_number(d.value);
              console.log(d)

              const tool = `<b>${d.data.name}:</b> ${value}`
              return tool
            })
        //     .nodeTooltip({
        //   // You can change the position of the tooltip 
        //   at: "bottom right",
        //   // Toggles the movement of the tooltip with the mouse
        //   followMouse: true,
        //   html: (data, value) => `<b>One Node -> ${data.name}:</b> ${value}`,
        //   // Orientation of the tooltip
        //   my: "right"
        // })

          config
            .sankey()
            .sankey()
            
        }
      }
    },
    
    computed: {
      updateSankeyNodes(){
        if (this.nodes.length !== 0){
          this.chartData.nodes = this.nodes;
          this.chartData.links = this.links;
         
        }
        
      },
      // updateSankeyLinks(){
      //   if (this.links.length !== 0){
      //     this.chartData.links = this.links;
      //     console.log(this.links)
      //   }
      // }
    },
    watch: {
      updateSankeyNodes(){
        this.chartData.nodes = this.nodes;
        this.chartData.links = this.links;
      },
      // updateSankeyLinks(){
      //   this.chartData.links = this.links;
      // }
    },
    methods:{
      formatCL(val){
        var valor = val.toLocaleString();
        var largo_string = valor.length
        var i;
        var stringFormat = ''; 
        for(i of valor){
          if( i === '.' ){
            i = ',';
            stringFormat+=i;
          }
          else if(i ===','){
            i = '.';
            stringFormat+=i;
          }
          else{
            stringFormat+=i;
          }
        }
        return stringFormat;
      },
    }

  }
</script>

<style scoped>
  .chart{
    width: 100%;
    height: 772.5px;
  }
</style>