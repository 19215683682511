<template>
	<div id="FuenteDatoComunaView">
    <div v-if="url_api.tipo_vista_comuna !== null">
    	<div v-if="url_api.tipo_vista_comuna === 'View1'">
        <View1Comuna :url_api="url_api" :comuna="comuna" :region="region" />
      </div>
      <div v-if="url_api.tipo_vista_comuna === 'View2'">
        <View2Comuna :url_api="url_api" :comuna="comuna" :region="region" />
      </div>
       <loadingData :overlay="overlay" style="z-index: 9999"/>
    </div>
    <div v-else>
      <h3 class="text-center font-weight-medium">
        Sin datos comunales.
      </h3>
    </div>
    
	</div>
</template>

<script>


import View1Comuna from '../Comuna/View1.vue';
import View2Comuna from '../Comuna/View2.vue';

import loadingData from '../../components/view_template/loadingData.vue';


export default {
  components: {
    View1Comuna,
    View2Comuna,
    loadingData

  },
  name: 'ComunaFuenteDato',
  props: ['comuna','url_api','region'],
  data: () => ({
    overlay:true
  }),
  mounted() {
     this.sleep(2000).then(() => {this.overlay=false;});
  },

  computed : {
     item(){
      return this.comuna,this.url_api
    }
  }, 

  watch: {
    item(){
      this.overlay = true;
      this.sleep(2000).then(() => {this.overlay=false;});
    }

  },
  methods: {
    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
  }
}
</script>