<template>
  <div>
    <div id="chart">
      <apexchart
        type="bar"
        height="300"
        :options="chartOptions"
        :series="series"
        ref="chart1"
        class="chart-wrapper"
      ></apexchart>
    </div>
  </div>
</template>

<style scoped>
div.chart-wrapper {
    display: flex;
    align-items: center;
    justify-content: center
}
</style>

<script>
import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    apexchart: VueApexCharts,
  },
  props: ["mydata", "mycategories","title","subtitle"],
  data() {
    return {
      series: [
        {
          data: this.mydata,
        },
      ],
      chartOptions: {
        chart: {
          height: 350,
          type: "bar",
          events: {
            click: function (chart, w, e) {
              console.log(chart, w, e);
            },
          },
        },
        plotOptions: {
          bar: {
            colors: {
              backgroundBarColors: [
                "#DD2C00",
                "#FFB300",
                "#558B2F",
                "#00796B",
                "#0277BD",
                "#FF4081",
              ],
            },
            horizontal: true,
            barHeight: "80%",
            distributed: true,
            dataLabels: {
              position: "bottom",
            },
          },
        },
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          style: {
            colors: ["#424242"],
            fontSize: "14px",
          },
          formatter: function (val, opt) {
            return opt.w.globals.labels[opt.dataPointIndex];
          },
          offsetX: 0,
          dropShadow: {
            enabled: false,
          },
        },
        tooltip: {
          x: {
            show: false,
          },
          y: {
            formatter: function (val) {
              return val + "%";
            },
            title: {
              formatter: function () {
                return "Porcentaje:";
                // return opts.w.globals.labels[opts.dataPointIndex]+":"; (val, opts)
              },
            },
          },
          marker: {
            show: true,
          },

          style: {
            fontSize: "14px",
            fontFamily: undefined,
          },
        },
        legend: {
          show: false,
        },
        xaxis: {
          categories: this.mycategories,
          labels: {
            style: {
              fontSize: "12px",
            },
          },
        },
        yaxis: {
          labels: {
            show: false,
          },
        },
        colors: [
          function ({ value }) {
            // if (value == this.barChartMax) {
            //   return "#F44336";
            // } else {
              return "#CFD8DC";
            // }
          },

        ],
        title: {
            text:''
        }
      },
    };
  },

  computed: {
    item() {
      return this.mydata;
    },
    item_2() {
      return this.title,this.subtitle,this.mycategories;
    },
    
   
  },

  watch: {
    item() {
      this.series = [
        {
          data: this.mydata,
        },
      ];
    },
    item_2() {

      this.$refs.chart1.updateOptions({
        xaxis: {
          categories: this.mycategories,
          max:100
        },
        title: {
          text: this.title,
          align: 'left',
          style: {
            fontSize:  '22px',
            fontWeight:  'light',
            fontFamily:  undefined,
            color:  '#263238'
          },
        },
        subtitle: {
          text: this.subtitle,
          align: 'left',
          style: {
            fontSize:  '17px',
            fontWeight:  'light',
            fontFamily:  undefined,
            color:  '#263238'
          },
        }
      })



      this.chartOptions = {
        xaxis: {
          categories: this.mycategories,
          max:100

        },
        
      };
    },

    
  },
};
</script>