<template>
  <div>
  <div id="mapContainer" class="basemap">
  </div>
  <div id='Info'>
    <div class="px-1"><strong>Comuna:</strong> <span id='comuna'></span></div>
    <div class="px-1"><strong>Ingresos:</strong> <span id='ingresos'></span></div>
    <div class="px-1"><strong>Gastos:</strong> <span id='gastos'></span></div>
    <div class="px-1"><strong>Diferencia:</strong> <span id='diferencia'></span></div>
    <div class="px-1"><strong>Ejecución:</strong> <span id='ejecucion'></span></div>
    <div class="px-1"><strong>Región:</strong> <span id='region'></span></div>
    <div class="px-1"><strong>Año:</strong> <span id='anio'></span></div>
  </div>  
</div>
</template>

<script>
import mapboxgl from "mapbox-gl";

export default {

  name: 'MapPM',
  props: [
    'coordenadas_region','codregion','codcomuna','data','tipo','data_comuna_selected'
  ],

  data () {
    return {
      accessToken: 'pk.eyJ1Ijoiam9zZW1jaGF2ZXpnIiwiYSI6ImNranloODJlZzAzeW4yd2xxbjQ2cTJ2aWEifQ.WQZWm73tdhtI79GUZTY-hQ',
      latitud: 0,
      longitud: 0
    }
  },
  mounted(){
    mapboxgl.accessToken = this.accessToken;

  },
  computed:{
    updateComuna(){
      return this.data;
    }
  },
  watch: {
    updateComuna(){
      mapboxgl.accessToken = this.accessToken;
      let codigo_region = this.cast_codigo_region(this.codregion);
      let codigo_comuna = this.codcomuna;
      let latitud = this.coordenadas_region[0];
      let longitud = this.coordenadas_region[1];

      let mapa = new mapboxgl.Map({
        container: "mapContainer",
        style: "mapbox://styles/mapbox/light-v10",
        center: [longitud,latitud],
        zoom: 6.5,
      });

      this.createMap(codigo_region,this.data,codigo_comuna,mapa,this.data_comuna_selected);

      // console.log(codigo_region,codigo_comuna);

    }
  },
  methods: {
    createMap(codigo_region,data_mapa,codigo_comuna,mapa,data_comuna){

      var comunaDisplay = document.getElementById('comuna');
      var regionDisplay = document.getElementById('region');
      var ingresosDisplay = document.getElementById('ingresos');
      var gastosDisplay = document.getElementById('gastos');
      var ejecucionDisplay = document.getElementById('ejecucion');
      var diferenciaDisplay = document.getElementById('diferencia');
      var anioDisplay = document.getElementById('anio');
     

      comunaDisplay.textContent = data_comuna.comuna.comuna;
      regionDisplay.textContent = data_comuna.comuna.region;
      diferenciaDisplay.textContent = '$'+this.formatCL(data_comuna.ingresos - data_comuna.gastos)+' Millones';
      ingresosDisplay.textContent = '$'+this.formatCL(data_comuna.ingresos)+' Millones';
      gastosDisplay.textContent = '$'+this.formatCL(data_comuna.gastos)+' Millones';
      ejecucionDisplay.textContent = data_comuna.porcentaje_gasto+'%';
      anioDisplay.textContent = data_comuna.anio;




      mapa.on('load',() => {

        mapa.addSource('map_regiones', {
            type: 'vector',
            url: 'mapbox://josemchavezg.ckluzc93b32jp28nq05ua9e3e-12lxz',
          },
          'country-label',
          'state-label'
        );
        mapa.addSource('map_puntos', {
            type: 'geojson',
            data: {
              "type": "FeatureCollection",
              "features": data_mapa
            }
          }          
        );
        //Add Magallanes Map
        if(codigo_region === '00012'){
          console.log('magallanes')
        }
        else{
          mapa.addLayer({
            id:'map_region',
            source: 'map_regiones',
            'source-layer': 'region01',
            type: 'fill',
            paint: {
              'fill-color': '#5D6D7E',
              'fill-opacity': 0.4,
            },
            "filter": ["==", "codigo_region", codigo_region]
          });

         
          mapa.addLayer({
            id:'comunas',
            source:'map_puntos',
            type: 'circle',
            layout: {
              'visibility':'visible'
            },
            paint: {
              'circle-radius': [
                  'step',
                  ['get',this.tipo],
                  5,
                  1000000,
                  8,
                  2000000,
                  11,
                  10000000,
                  17,
                  50000000,
                  24
                ],
              'circle-color': '#42A5F5',
              'circle-stroke-width': 1,
            },
            "filter": ["!=", "codigo_comuna", codigo_comuna]

          });

          mapa.addLayer({
            id:'comuna_selected',
            source:'map_puntos',
            type: 'circle',
            layout: {
              'visibility':'visible'
            },
            paint: {
              'circle-radius': [
                  'step',
                  ['get',this.tipo],
                  5,
                  1000000,
                  8,
                  2000000,
                  11,
                  10000000,
                  17,
                  50000000,
                  24
                ],
              'circle-color': '#CD2400',
              'circle-stroke-width': 1,
            },
            "filter": ["==", "codigo_comuna", codigo_comuna]

          });



        }

        mapa.on('mousemove','comunas',(e) => {
          mapa.getCanvas().style.cursor = 'pointer';
          var ingresos = e.features[0].properties.ingresos;
          var gastos = e.features[0].properties.gastos;
          var ejecucion = e.features[0].properties.porcentaje;
          var comuna = e.features[0].properties.comuna;
          var region = e.features[0].properties.region;
          var anio = e.features[0].properties.anio;
          var unidad = e.features[0].properties.unidad;

          if (e.features.length > 0) {
            // Display the data in the sidebar
            comunaDisplay.textContent = comuna;
            ingresosDisplay.textContent ='$'+this.formatCL(ingresos)+' '+unidad;
            gastosDisplay.textContent ='$'+this.formatCL(gastos)+' '+unidad;
            diferenciaDisplay.textContent = '$'+this.formatCL(ingresos-gastos)+' '+unidad;
            ejecucionDisplay.textContent = ejecucion+'%';
            regionDisplay.textContent = region;
            anioDisplay.textContent = anio;
          }

        });

        mapa.on('mouseleave','comunas',(e) => {
          // comunaDisplay.textContent = '';
          // ingresosDisplay.textContent = '';
          // gastosDisplay.textContent = '';
          // ejecucionDisplay.textContent = '';
          // regionDisplay.textContent = '';
          // anioDisplay.textContent = '';
          comunaDisplay.textContent = data_comuna.comuna.comuna;
          regionDisplay.textContent = data_comuna.comuna.region;
           diferenciaDisplay.textContent = '$'+this.formatCL(data_comuna.ingresos - data_comuna.gastos)+' Millones';
          ingresosDisplay.textContent = '$'+this.formatCL(data_comuna.ingresos)+' Millones';
          gastosDisplay.textContent = '$'+this.formatCL(data_comuna.gastos)+' Millones';
          ejecucionDisplay.textContent = data_comuna.porcentaje_gasto+'%';
          anioDisplay.textContent = data_comuna.anio;
          // Reset the cursor style
          mapa.getCanvas().style.cursor = '';
        });


        mapa.addControl(new mapboxgl.NavigationControl());
        mapa.addControl(new mapboxgl.FullscreenControl());


      });
    },

    formatCL(val){
      var valor = val.toLocaleString();
      var largo_string = valor.length
      var i;
      var stringFormat = ''; 
      for(i of valor){
        if( i === '.' ){
          i = ',';
          stringFormat+=i;
        }
        else if(i ===','){
          i = '.';
          stringFormat+=i;
        }
        else{
          stringFormat+=i;
        }
      }
      return stringFormat;
    },

    cast_codigo_region(codigo_region){
      let codigo;
      var tipo = String(codigo_region).length;
      if(tipo == 1){
        if(codigo_region == 8){
          codigo = '08';
        }
        else{
          codigo = '0000'+String(codigo_region);
        }
      }
      else if(tipo == 2){
        if(codigo_region == 16){
          codigo = '16';
        }
        else{
          codigo = '000'+String(codigo_region)
        }
      }

      return codigo;
    }
  }
}
</script>

<style scoped>
.basemap {
  width: 96%;
  height: 85%;
  position: absolute;
  top: 110px;
  bottom: 0;
}

#Info {
  position: absolute;
  font-family: sans-serif;
  margin-top: 5px;
  margin-left: 5px;
  padding: 5px;
  width: 35%;
  border: 2px solid black;
  font-size: 14px;
  color: #fff;
  background-color: black;
  border-radius: 3px;
  opacity: 0.7;
}
</style>