<template>
  <div id="AreaChart">
    <apexchart type="area" ref="chart" width="100%" :height="height"
    :options="chartOptions" :series="series" class="chart-wrapper" />
  </div>
</template>

<style scoped>
div.chart-wrapper {
  display: flex;
  align-items: center;
  justify-content: center
}
</style>


<!-- Tipo: 0 -> Valor, 1 -> Porcentaje -->
<script>
export default {
  name:'AreaChart',
  props: [
    'data','labels','colors','tipo_data',
    'nombre_serie','titulo','subtitulo','height','tipo'
  ],
  data() {
    return {
      series: [],
      chartOptions:{
        chart: {
          zoom: {
            enabled:false
          },
        },
        dataLabels:{
          enabled:false
        },
        stroke: {
          curve: 'straight'
        },
        title: {
          text: '',
          align: 'left',
          style: {
              fontSize:  '24px',
              fontWeight:  'light',
              fontFamily:  undefined,
              color:  '#263238'
            },
        },
        subtitle: {
          text: '',
          align: 'left',
          style: {
              fontSize:  '18px',
              fontWeight:  'light',
              fontFamily:  undefined,
              color:  '#263238'
            },
            offsetY:35
        },
        xaxis: {
          type: 'categories',
          categories: [],
          labels:{
            show:true,
            style:{
              colors: [],
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 500,
              cssClass: 'apexcharts-xaxis-label',
            }
          }
        },
        yaxis: {
          opposite: true,
          show: false
        },
        tooltip: {
          theme: 'dark',
          style: {
            fontSize: '14px',
            fontFamily: undefined
          },
          y: {
            formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
              let tipo = w.config.tipo;
              function formatCL(val){
                var valor = val.toLocaleString();
                var largo_string = valor.length
                var i;
                var stringFormat = ''; 
                for(i of valor){
                  if( i === '.' ){
                    i = ',';
                    stringFormat+=i;
                  }
                  else if(i ===','){
                    i = '.';
                    stringFormat+=i;
                  }
                  else{
                    stringFormat+=i;
                  }
                }
                return stringFormat;
              };

              if (tipo === 0){
                return formatCL(value)
              }
              else{
                return value+'%'
              }
              
            }
          }
        },
        tipo: ''
      }
    }
  },

  computed: {
    updateItem(){
      return this.data;
    }
  },

  watch: {
    updateItem(){
      this.$refs.chart.updateOptions({
        series: [{
          data: this.data,
          name: this.nombre_serie
        }],
        xaxis: {
          categories: this.labels
        },
        title: {
          text : this.titulo
        },
        subtitle: {
          text: this.subtitulo
        },
        colors: this.colors,
        tipo: this.tipo
      });
    }
  },

}
</script>