<template>
	<div id="View2">
    <div id="head">
      <v-app-bar color="light-blue darken-2" class="rounded" flat dark>
          <h2 class="font-weight-medium">{{url_api.nombre_data}}</h2>
        <v-col cols="2">
          <v-select
          :items="anios"
          v-model="anio"
          :label="'Año'"
          hide-no-data
          hide-details
          dense
          class="my-2 ml-4"
          flat
          solo-inverted
          v-on:change="cambiarAnio"
          >
          </v-select>
        </v-col>
        <v-spacer/>
        <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
           
            dark
            v-bind="attrs"
            v-on="on"
          >
            mdi-information-outline
          </v-icon>
        </template>
        <span>Fuente: {{url_api.fuente}}</span>
      </v-tooltip>
      </v-app-bar>
    </div>
    <div id="body" style="margin-top:-5px;">
      <v-row>
        <v-col cols="4">
          <v-card flat>
            <v-card-title class="justify-center">
              <h2 class="font-weight-medium"><small>País</small></h2>
            </v-card-title>
            <v-card-text style="margin-top:-5px;" class="text-center">
             <!--  <h2 class="font-weight-light mb-2">PIB</h2> -->
              <h2  class="display-1 ">${{valor_pais}}</h2>
              <h3  class=" mt-2 ml-2">Miles de millones de pesos</h3>
            </v-card-text>
          </v-card>
        
          <v-card flat class="mt-2">
            <v-card-title class="justify-center">
              <h2 class="font-weight-medium"><small>{{nombre_region}}</small></h2>
            </v-card-title>
           <v-card-text style="margin-top:-5px;" class="text-center">
              <!-- <h2 class="font-weight-light mb-2">PIB</h2> -->
              <h2  class="display-1">${{valor_region}}</h2>
              <h3  class="mt-2 ml-2">Miles de millones de pesos</h3>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="8">
          <v-card flat height="100%">
            <v-card-text>
              <column-chart-perc :data="data_graph1" :categories="labels_graph1" :colors="colors_graph1"
                :titulo="'PIB Regional '+anio" :height="250" :nombre_serie="'PIB'" :tipo_data="'Miles de millones de pesos'" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row style="margin-top:-15px;">
        <v-col>
          <v-card flat height=470>
            <v-card-title class="justify-center">
              <h4 class="font-weight-light">Comparar PIB con </h4>
              <v-col cols="2">
                <v-select
                :items="regiones"
                v-model="regionComparar"
                :label="'Región'"
                hide-no-data
                hide-details
                outlined
                flat
                dense
                item-text="nombre"
                item-value="id"
                v-on:change="cambiarRegionComparar"
                >
								</v-select>
							</v-col>
            </v-card-title>
            <v-card-text style="margin-top:-10px;">
              <AreaChartCompare :data_region1="data_region" :data_region2="data_region2"/>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import AreaChartCompare from '../../components/charts/AreaChart_Compare.vue';
import ColumnChartPerc from '../../components/chartsV2/ColumnChartPerc.vue';
const API_URL = 'https://api.datari.net/datari/data/'

export default{
	name: 'View2',
	components: {
		AreaChartCompare,
    ColumnChartPerc,
	},
	props: ['url_api','region','regiones'],
	data: () => ({
		regionSelect: null,
    nombre_region: null,
		regionComparar: null,
		data_fuente_regiones: [],
    data_fuente_region_anio: [],
		data_region: [],
		data_region2: [],
		anio: null,
		totalRegiones: null,
		valor_region: null,
    porcentaje_region: null,
    valor_pais: null,
		anios: [2019,2018,2017,2016,2015,2014,2013],
    data_graph1: [],
    labels_graph1:[],
    colors_graph1:[]
	}),
	created(){

    this.anio = this.anios[0];
    this.sleep(1500).then(() => {
      this.get_data_region_anio();
      this.get_data_region();
      this.get_data_pais();
    });
	},

	computed:{
		update(){
			return this.region;
		}
	},
	watch: {
		update(){
      this.get_data_region();
      this.get_data_region_anio();
      console.log(this.nombre_region)
      this.sleep(1500).then(() => {
        this.get_data_pais();   
      });

		
		}
	},
	
	methods: {
    formatCL(val){
      var valor = val.toLocaleString();
      var largo_string = valor.length
      var i;
      var stringFormat = ''; 
      for(i of valor){
        if( i === '.' ){
          i = ',';
          stringFormat+=i;
        }
        else if(i ===','){
          i = '.';
          stringFormat+=i;
        }
        else{
          stringFormat+=i;
        }
      }
      return stringFormat;
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    getTipoRegion(region){
      let result;
      if(region === 'Metropolitana'){
        result = 'Región '+region;
      }
      else if(region === 'Biobío' || region === 'Ñuble' || region == 'Maule'){
        result = 'Región del '+region;
      }
      else{
        result = 'Región de '+region;
      }
      return result;
    },

    getGraphicData(data,labelType){
      // Tipo 1 -> Años, Tipo 2 -> Regiones
      var element;
      var labels = [];
      var series = [];
      var colors = [];

      if(labelType == 1){
        for(element of data){
          if(!labels.includes(element.anio)){
            labels.push(element.anio);
          }
          series.push(element.cantidad);
        }
        colors = ['#00796B']
      }
      else if(labelType == 2){
        for(element of data){
          if(!labels.includes(element.region.nombre)){
            if(element.region.nombre.includes('Arica')){
              var temp = ['Arica y','Parinacota']
              labels.push(temp);
            }else{
              labels.push(element.region.nombre);
            }
            
          }
          if(this.nombre_region.includes(element.region.nombre)){
            colors.push('#1A5276');
          }
          else{
            colors.push("#B5B5B5");
          }

          series.push(element.valor_pib);
        }
      }

      else if(labelType === 3){
        for(element of data){
          labels.push(element.tipo);
          series.push(element.cantidad);
        }
        colors = ['#1A237E','#1976D2','#90CAF9'];
      }
     
      return {
              'categorias': labels,
              'series': series,
              'colors': colors
             }
    },

		get_data_pais(){
			axios.get(API_URL + this.url_api.url_api+'?anio='+this.anio)
			.then(response =>{
				this.data_fuente_regiones = response.data.results;
        let data_grafico = this.getGraphicData(this.data_fuente_regiones,2);
        this.data_graph1 = data_grafico.series;
        this.labels_graph1 = data_grafico.categorias;
        this.colors_graph1 = data_grafico.colors;
        this.valor_pais = this.formatCL(response.data.totalAnual);
			});
		},

		get_data_region_anio(){
      console.log(this.region)
      axios.get(API_URL+this.url_api.url_api+'?region='+this.region+'&anio='+this.anio)
			.then(response => {
				this.data_fuente_region_anio = response.data.results;
        this.data_fuente_region_anio = this.data_fuente_region_anio[0];
				this.valor_region = this.formatCL(this.data_fuente_region_anio.valor_pib);
        console.log(this.data_fuente_region_anio)
        this.nombre_region = this.getTipoRegion(this.data_fuente_region_anio.region.nombre);
        this.porcentaje_region = this.data_fuente_region_anio.porcentaje;
			});
		},

		get_data_region(){
			if(this.regionComparar === null){
				axios.get(API_URL+this.url_api.url_api+'?region='+this.region)
				.then(response =>{ 
					this.data_region = response.data.results;
				});
			}else{
				axios.get(API_URL+this.url_api.url_api+'?region='+this.region)
				.then(response => this.data_region = response.data.results);

				axios.get(API_URL+this.url_api.url_api+'?region='+this.regionComparar)
				.then(response => this.data_region2 = response.data.results);

			}
			
		},
		cambiarAnio(anio){
			this.anio = anio;
			this.get_data_region_anio();
      this.get_data_pais();
		},

		cambiarRegionComparar(region){
			this.regionComparar = region;
      this.get_data_region();
			
		}
	}

}
	

</script>