<template>
    <div id="map">
        <div id="mapContainer" class="basemap"></div>
        <div id="tooltip"></div>
    </div>
</template>

<style scoped>
.basemap {
    width:100%;
    height:490px;
    /* margin-top:-32px; */
}
</style>

<style >
#tooltip {
  font-family: Helvetica, Arial, sans-serif;
  position: absolute;
  padding: 4px;
  margin: 8px;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  max-width: 300px;
  font-size: 14px;
  z-index: 9;
  pointer-events: none;
  height: 220px !important;
  width: 300px !important;
}
</style>

<script>
import mapboxgl from "mapbox-gl";
import {ColumnLayer} from '@deck.gl/layers';
import {MapboxLayer} from '@deck.gl/mapbox';

export default {
	props: ['data','lat','lon','cod_region','tipo_valor'],
	name: 'MapTTAduana',
	data() {
		return {
			accessToken: 'pk.eyJ1Ijoiam9zZW1jaGF2ZXpnIiwiYSI6ImNranloODJlZzAzeW4yd2xxbjQ2cTJ2aWEifQ.WQZWm73tdhtI79GUZTY-hQ',
			map:null,
		}
	},
	mounted(){
		mapboxgl.accessToken = this.accessToken;
    let coordenadas_region = [this.lon,this.lat];
    let map3D = new mapboxgl.Map({
      container: "mapContainer",
      style: "mapbox://styles/mapbox/light-v10",
      center: coordenadas_region,
      zoom: 7,
      pitch: 60,
      bearing: 5
    });

    this.create_layers(map3D);
	},
	computed: {
		updateMap(){
			return this.lon,this.lat,this.data;
		}
	},
	watch: {
		updateMap(){
			let coordenadas_region = [this.lon,this.lat];
			let map3D = new mapboxgl.Map({
				container: "mapContainer",
				style: "mapbox://styles/mapbox/light-v10",
				center: coordenadas_region,
				zoom: 7,
				pitch: 60,
				bearing: 5
			});

			this.create_layers(map3D);
		}
	},
	methods: {


		create_layers(map){
			let data_map = this.data;

			let layer_columnas_particulares = new MapboxLayer({
				id: 'ColumnLayer',
				type: ColumnLayer,
				data: data_map,
				angle:10,
				coverage:1,
				diskResolution:300,
				elevationscale: 70,
				extruded: true,
				getElevation: d => d.properties.participacion_particular * 500 ,
				getFillColor: d => d.properties.color,
				getLineColor: [0, 0, 0],
				getLineWidth: 10,
				getPosition: d => d.geometry.coordinates,
				radius: 1500,
				pickable: true,
				controller: true,
				highlightColor: [0, 0, 128, 128],
				autoHighlight: true,
				onHover: ({object,x,y}) => {
            const tooltip = document.getElementById('tooltip');
            function formatCL(val){
              var valor = val.toLocaleString();
              var largo_string = valor.length
              var i;
              var stringFormat = ''; 
              for(i of valor){
                if( i === '.' ){
                  i = ',';
                  stringFormat+=i;
                }
                else if(i ===','){
                  i = '.';
                  stringFormat+=i;
                }
                else{
                  stringFormat+=i;
                }
              }
              return stringFormat;
            };
            if(object){
              let nombre = object.properties.nombre;
              let valor = object.properties.valor_particular;
              let tipo = object.properties.tipo;
              let tipo_valor = this.tipo_valor;
              let anio = object.properties.anio;
							let comuna = object.properties.comuna;
              let participacion = object.properties.participacion_particular;
              let unidad = object.properties.unidad_vehiculos;

							console.log(this.tipo_valor)
              
              tooltip.style.top = `${y}px`;
              tooltip.style.left = `${x+20}px`;
              tooltip.innerHTML = `
                <div class="text-center"><b>${nombre}</b></div>
								<div class="text-center">${comuna}</div>
                <div class="text-left ml-3 mt-4"><b>${tipo} vehiculos ${tipo_valor}</b></div>
                <div class="text-left ml-3 mt-1"><b>${anio}</b></div>
                <div class="text-left ml-3 ">${formatCL(valor)} ${unidad}</div>
                <div class="text-left ml-3 mt-2"><b>Participación Regional</b></div>
                <div class="text-left ml-3 ">${participacion} %</div>
                
              `;
              tooltip.style.visibility = "visible";
            }
            else {
              tooltip.style.visibility = "hidden";
              tooltip.innerHTML = '';
            }            
          }
			});

			let layer_columnas_pasajeros = new MapboxLayer({
				id: 'ColumnLayer',
				type: ColumnLayer,
				data: data_map,
				angle:10,
				coverage:1,
				diskResolution:300,
				elevationscale: 70,
				extruded: true,
				getElevation: d => d.properties.participacion_pasajero * 500 ,
				getFillColor: d => d.properties.color,
				getLineColor: [0, 0, 0],
				getLineWidth: 10,
				getPosition: d => d.geometry.coordinates,
				radius: 1500,
				pickable: true,
				controller: true,
				highlightColor: [0, 0, 128, 128],
				autoHighlight: true,
				onHover: ({object,x,y}) => {
            const tooltip = document.getElementById('tooltip');
            function formatCL(val){
              var valor = val.toLocaleString();
              var largo_string = valor.length
              var i;
              var stringFormat = ''; 
              for(i of valor){
                if( i === '.' ){
                  i = ',';
                  stringFormat+=i;
                }
                else if(i ===','){
                  i = '.';
                  stringFormat+=i;
                }
                else{
                  stringFormat+=i;
                }
              }
              return stringFormat;
            };
            if(object){
              let nombre = object.properties.nombre;
              let valor = object.properties.valor_pasajero;
              let tipo = object.properties.tipo;
              let tipo_valor = this.tipo_valor;
              let anio = object.properties.anio;
							let comuna = object.properties.comuna;
              let participacion = object.properties.participacion_pasajero;
              let unidad = object.properties.unidad_vehiculos;
              
              tooltip.style.top = `${y}px`;
              tooltip.style.left = `${x+20}px`;
              tooltip.innerHTML = `
                <div class="text-center"><b>${nombre}</b></div>
								<div class="text-center">${comuna}</div>
                <div class="text-left ml-3 mt-4"><b>${tipo} vehiculos ${tipo_valor}</b></div>
                <div class="text-left ml-3 mt-1"><b>${anio}</b></div>
                <div class="text-left ml-3 ">${formatCL(valor)} ${unidad}</div>
                <div class="text-left ml-3 mt-2"><b>Participación Regional</b></div>
                <div class="text-left ml-3 ">${participacion} %</div>
                
              `;
              tooltip.style.visibility = "visible";
            }
            else {
              tooltip.style.visibility = "hidden";
              tooltip.innerHTML = '';
            }            
          }
			});

			let layer_columnas_v_carga = new MapboxLayer({
				id: 'ColumnLayer',
				type: ColumnLayer,
				data: data_map,
				angle:10,
				coverage:1,
				diskResolution:300,
				elevationscale: 70,
				extruded: true,
				getElevation: d => d.properties.participacion_v_carga * 500 ,
				getFillColor: d => d.properties.color,
				getLineColor: [0, 0, 0],
				getLineWidth: 10,
				getPosition: d => d.geometry.coordinates,
				radius: 1500,
				pickable: true,
				controller: true,
				highlightColor: [0, 0, 128, 128],
				autoHighlight: true,
				onHover: ({object,x,y}) => {
            const tooltip = document.getElementById('tooltip');
            function formatCL(val){
              var valor = val.toLocaleString();
              var largo_string = valor.length
              var i;
              var stringFormat = ''; 
              for(i of valor){
                if( i === '.' ){
                  i = ',';
                  stringFormat+=i;
                }
                else if(i ===','){
                  i = '.';
                  stringFormat+=i;
                }
                else{
                  stringFormat+=i;
                }
              }
              return stringFormat;
            };
            if(object){
              let nombre = object.properties.nombre;
              let valor = object.properties.valor_v_carga;
              let tipo = object.properties.tipo;
              let tipo_valor = this.tipo_valor;
              let anio = object.properties.anio;
							let comuna = object.properties.comuna;
              let participacion = object.properties.participacion_v_carga;
              let unidad = object.properties.unidad_vehiculos;
              
              tooltip.style.top = `${y}px`;
              tooltip.style.left = `${x+20}px`;
              tooltip.innerHTML = `
                <div class="text-center"><b>${nombre}</b></div>
								<div class="text-center">${comuna}</div>
                <div class="text-left ml-3 mt-4"><b>${tipo} vehiculos ${tipo_valor}</b></div>
                <div class="text-left ml-3 mt-1"><b>${anio}</b></div>
                <div class="text-left ml-3 ">${formatCL(valor)} ${unidad}</div>
                <div class="text-left ml-3 mt-2"><b>Participación Regional</b></div>
                <div class="text-left ml-3 ">${participacion} %</div>
                
              `;
              tooltip.style.visibility = "visible";
            }
            else {
              tooltip.style.visibility = "hidden";
              tooltip.innerHTML = '';
            }            
          }
			});

			let layer_columnas_carga = new MapboxLayer({
				id: 'ColumnLayer',
				type: ColumnLayer,
				data: data_map,
				angle:10,
				coverage:1,
				diskResolution:300,
				elevationscale: 70,
				extruded: true,
				getElevation: d => d.properties.participacion_carga * 500 ,
				getFillColor: d => d.properties.color,
				getLineColor: [0, 0, 0],
				getLineWidth: 10,
				getPosition: d => d.geometry.coordinates,
				radius: 1500,
				pickable: true,
				controller: true,
				highlightColor: [0, 0, 128, 128],
				autoHighlight: true,
				onHover: ({object,x,y}) => {
            const tooltip = document.getElementById('tooltip');
            function formatCL(val){
              var valor = val.toLocaleString();
              var largo_string = valor.length
              var i;
              var stringFormat = ''; 
              for(i of valor){
                if( i === '.' ){
                  i = ',';
                  stringFormat+=i;
                }
                else if(i ===','){
                  i = '.';
                  stringFormat+=i;
                }
                else{
                  stringFormat+=i;
                }
              }
              return stringFormat;
            };
            if(object){
              let nombre = object.properties.nombre;
              let valor = object.properties.valor_carga;
              let tipo = object.properties.tipo;
              let tipo_valor = this.tipo_valor;
              let anio = object.properties.anio;
							let comuna = object.properties.comuna;
              let participacion = object.properties.participacion_carga;
              let unidad = object.properties.unidad_carga;
              
              tooltip.style.top = `${y}px`;
              tooltip.style.left = `${x+20}px`;
              tooltip.innerHTML = `
                <div class="text-center"><b>${nombre}</b></div>
								<div class="text-center">${comuna}</div>
                <div class="text-left ml-3 mt-4"><b>${tipo} vehiculos ${tipo_valor}</b></div>
                <div class="text-left ml-3 mt-1"><b>${anio}</b></div>
                <div class="text-left ml-3 ">${formatCL(valor)} ${unidad}</div>
                <div class="text-left ml-3 mt-2"><b>Participación Regional</b></div>
                <div class="text-left ml-3 ">${participacion} %</div>
                
              `;
              tooltip.style.visibility = "visible";
            }
            else {
              tooltip.style.visibility = "hidden";
              tooltip.innerHTML = '';
            }            
          }
			});

			console.log(data_map);
			console.log(this.tipo_valor);
			//Cargar Layers
			let codigo_region = this.normalizarCodigo(this.cod_region);
			map.on('load', () => {
				map.addSource('mapa_regiones', {
						type: 'vector',
						url: 'mapbox://josemchavezg.ckluzc93b32jp28nq05ua9e3e-12lxz',
					},
					'country-label',
					'state-label'
        );

				map.addLayer({
					id:'mapa_region',
					source: 'mapa_regiones',
					'source-layer': 'region01',
					type: 'fill',
					paint: {
						'fill-color': '#64B5F6',
						'fill-opacity': 0.3,
					},
					"filter": ["==", "codigo_region", codigo_region]
				});

				if (this.tipo_valor === 'particulares'){
					map.addLayer(layer_columnas_particulares);
				}
				else if(this.tipo_valor === 'de pasajeros'){
					map.addLayer(layer_columnas_pasajeros);
				}
				else if(this.tipo_valor === 'de carga'){
					map.addLayer(layer_columnas_v_carga);
				}
				else if(this.tipo_valor === 'carga en toneladas'){
					map.addLayer(layer_columnas_carga);
				}

				//Options
				map.addControl(new mapboxgl.NavigationControl());
        map.addControl(new mapboxgl.FullscreenControl());


			});
		},
		normalizarCodigo(cod){
        var temp = String(cod).length;
        var cod_region;

        if(temp == 1){
          if(cod == 8){
            cod_region = '08';
          }
          else{
            cod_region = '0000'+String(cod);
          }
        }
        else if(temp == 2){
          if(cod == 16){
            cod_region = String(cod);
          }
          else{
            cod_region = '000'+String(cod);
          }
        }
        return cod_region;
      },
	
	}
}
</script>
