<template>
    <div id="View8Region">
    <!-- Titulo Fuente de dato -->
      <div id="header">
         <v-app-bar color="light-blue darken-2" class="rounded" flat dark>
        <h2 class="font-weight-medium">{{url_api.nombre_data}}</h2>
         
        <v-col class="mx-4" cols="2">
          <v-select
            v-model="anio"
            :items ="url_api.anios"
            label ="Año"
            item-text="id"
            flat
            solo-inverted
            dense
            hide-no-data
            class="mx-2"
            hide-details
            v-on:change="cambiarAnio">
          </v-select>
        </v-col>
        <v-spacer/>
        <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
           
            dark
            v-bind="attrs"
            v-on="on"
          >
            mdi-information-outline
          </v-icon>
        </template>
        <span>Fuente: {{url_api.fuente}}</span>
      </v-tooltip>
      </v-app-bar>
    </div>
    <!-- Datos Región-Comuna Fuente de dato  -->
    <v-row class="mt-0">
      <v-col>
        <v-card height="100%" flat>
          <v-card-title class="justify-center font-weight-light">
            <h3 class="font-weight-medium">País</h3> 
          </v-card-title>
          <v-card-text class="text-center">
            <h2 class="font-weight-light mb-4">Total</h2>
            <h1 class="font-weight-medium" style="display:inline;"> {{totalPais}}</h1>
            <h3 class="mt-4 font-weight-medium ml-1" style="display:inline;">{{tipo_dato}}</h3>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card height="100%" flat>
          <v-card-title class="justify-center font-weight-light">
            <h3 class="font-weight-medium">{{nombre_region}}</h3> 
          </v-card-title>
          <v-card-text class=" text-center">
            <h2 class="font-weight-light mb-4">Total</h2>
            <h1 class="font-weight-medium" style="display:inline">{{totalRegion}}</h1>
            <h3 class="mt-2 font-weight-medium ml-1" style="display:inline">{{tipo_dato}}</h3>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card flat> 
          <v-card-title class="justify-center font-weight-light">
         <h3 class="font-weight-medium">% respecto a nivel País</h3>
          </v-card-title>
          <v-card-text class="text-center">
            <v-progress-circular color="#1A5276" :size="80" :width="9"
              :rotate="-90"  :value="participacion">
                {{participacion}}%       
            </v-progress-circular>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Gráficos -->
    <v-row style="margin-top:-10px;">
      <v-col>
        <v-card height="600" flat>
          <v-card-title class="justify-center font-weight-light">
            Capacidad Instalada Regional {{anio}}
          </v-card-title>
          <v-card-text>
            <PieChart :data="data_graph1" :labels="labels_graph1" :colors="colors1" :serie="'Capacidad'" :tipo_dato="'Mega Watts'"/>          
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card height="600" flat>
          <v-card-title class="justify-center font-weight-light">
            Ranking regional {{anio}}
          </v-card-title>
          <v-card-text >
            <ColumnChart :data="data_graph2" :categorias="labels_graph2" :tipo="3" :nombre_serie="'Capacidad '+anio" :colors="colors2" :tipo_dato="'Mega Watts'"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    </div>
</template>

<script>
import axios from 'axios';
const API_URL = 'https://api.datari.net/datari/data/';
import ColumnChart from '../../components/charts/ColumnChart.vue';
import PieChart from '../../components/charts/PieChart.vue';


export default {
  components: {
    ColumnChart,
    PieChart
  },
  name: 'View8Region',
  props: ['url_api','region'],
  data: () => ({
    nombre_region: null,
    anio: null,
    data_anual_region: null,
    totalPais: null,
    tipo_dato: null,
    totalRegion: null,
    participacion: null,
    data_anual: null,
    data_graph2: [],
    data_graph1:[],
    colors1: [],
    colors2: [],
    labels_graph1: [],
    labels_graph2: []

  }),
  created(){
    // document.documentElement.style.overflow = 'scroll';
    this.anio = this.url_api.anios[0];
    this.crearDataRegion();
    this.crearDataAnual();
  },
  computed: {
    updateItem(){
      return this.region, this.url_api;
    }

  },
  watch: {
    updateItem(){
      this.anio = this.url_api.anios[0];
      this.crearDataRegion();
      this.crearDataAnual();

    }
  },
  methods: {
    cambiarAnio(anio){
      this.anio = anio;
    },

    crearDataRegion(){
      axios.get(API_URL+this.url_api.url_api+'?region='+this.region+'&anio='+this.anio)
      .then(response => {
        if(response.data.count != 0){
          this.data_anual_region = response.data.results[0];
          this.totalPais = this.formatCL(response.data.totalAnual);
          this.tipo_dato = response.data.results[0].tipo_dato;
          this.nombre_region = response.data.results[0].region.nombre;
          this.totalRegion = this.formatCL(response.data.results[0].valor_mw);
          this.participacion = response.data.results[0].participacion;
        }
        else{
          this.data_anual_region = 0;
        }
      });
    },

    crearDataAnual(){
      axios.get(API_URL+this.url_api.url_api+'?anio='+this.anio)
      .then(response =>{
        this.data_anual = response.data.results;
        this.data_graph1 = this.getGraphicData(this.data_anual,2,'mw')['series'];
        this.labels_graph1 = this.getGraphicData(this.data_anual,2,'mw')['categorias'];
        this.colors1 = this.getGraphicData(this.data_anual,2,'mw')['colors'];
        this.colors2= (this.getGraphicData(this.order_by_rank(this.data_anual),2,'mw'))['colors']
        this.data_graph2 = (this.getGraphicData(this.order_by_rank(this.data_anual),2,'mw'))['series'];
        this.labels_graph2 = (this.getGraphicData(this.order_by_rank(this.data_anual),2,'mw'))['categorias'];
      })
    },
    getGraphicData(data,labelType,tipo){
      // LabelTipo 1 -> Años, Tipo 2 -> Regiones
      //Tipo de dato a extraer
      var element;
      var labels = [];
      var series = [];
      var colors = [];

      if(labelType == 1){
        for(element of data){
          if(!labels.includes(element.anio)){
            labels.push(element.anio);
          }
          if(tipo === 'mw'){
            series.push(element.valor_mw);
          }
          else if(tipo === 'participacion'){
            series.push(element.participacion);
          }
        }
      }
      else if(labelType == 2){
        for(element of data){
          if(!labels.includes(element.region.nombre)){
            labels.push(element.region.nombre);
            colors.push(element.region.color);
          }
          if(tipo === 'mw'){
            series.push(element.valor_mw);
          }
          else if(tipo === 'participacion'){
            series.push(element.participacion);
          }
        }
      }
     
      return {
              'categorias': labels,
              'series': series,
              'colors': colors
             }
    },

    order_by_rank(data){
      var data1 = data.sort(function(a,b){
        a = a.rank;
        b = b.rank;
        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
      })
      return data1;
    },

    formatCL(val){
      var valor = val.toLocaleString();
      var largo_string = valor.length
      var i;
      var stringFormat = ''; 
      for(i of valor){
        if( i === '.' ){
          i = ',';
          stringFormat+=i;
        }
        else if(i ===','){
          i = '.';
          stringFormat+=i;
        }
        else{
          stringFormat+=i;
        }
      }
      return stringFormat;
    },
  }
}
    
</script>