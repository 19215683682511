<template>
  <div>
    <div id="header">
      <v-app-bar color="light-blue darken-2" class="rounded" flat dark>
        <h2 class="font-weight-medium">{{url_api.nombre_data}}</h2>
        <v-col cols="2">
        <v-select
        v-model="anio"
        :items ="url_api.anios"
        label ="Año"
        hide-no-data
        hide-details
        class="mx-2 my-2"
        solo-inverted
        flat
        dense
        v-on:change="cambiarAnio"
        ></v-select>
      </v-col>
      <v-spacer/>
      <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-icon
         
          dark
          v-bind="attrs"
          v-on="on"
        >
          mdi-information-outline
        </v-icon>
      </template>
      <span>Fuente: {{url_api.fuente}}</span>
      </v-tooltip>
      </v-app-bar>
    </div>
    <div style="margin-top:-5px;" id="body">
     <v-row>
        <v-col>
          <v-card height="600" flat>
            <v-card-title class="font-weight-light">
              Variación anual por Region - {{ data_region.nombre }}
            </v-card-title>
            <v-card-subtitle class="font-weight-light">
              2006 - 2017
            </v-card-subtitle >
            <v-card-text class="font-weight-light">
              <Chart8
              class="mt-10"
              :myseries="series"
              :mycategories="url_api.anios"
              :mycolors="colors_chart8"
              :my_ytitle="yaxis_title_chart8"
              :mytooltip_y="tooltip_y_chart8"
            />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card height="600" flat>
            <v-card-title class="font-weight-light">
            Representación en el Ranking Nacional -
            {{anio}}
            </v-card-title>
           
              <v-slide-group
            v-model="model_comparacion"
            center-active
            show-arrows
            v-on:change="cambiarCategoria"
            mandatory
          >
            <v-slide-item
              class="ml-auto"
              v-for="n in url_api.tipos_datos"
              :key="n"
              v-slot="{ active, toggle }"
            >
              <v-card
                class="mr-auto my-1"
                :color="active ? 'blue lighten' : 'grey lighten-2'"
                @click="toggle"
                :input-value="active"
              >
                <v-card-text class="ml-5 mr-5 my-n2">
                  <template
                    class="font-weight-bolder black--text"
                    v-if="active"
                    ><b style="color: white">{{ n }}</b>
                  </template>
                  <template v-else>
                    <b>{{ n }}</b>
                  </template>
                </v-card-text>
              </v-card>
            </v-slide-item>
          </v-slide-group>
          <v-row class=" mt-2 mx-6 font-weight-light black--text">
            <v-col cols="7" class="mt-n2 ml-4">
              <v-list>
                <b class="ml-4">Región</b>
                <v-list-item
                  v-for="item in txt_ranking"
                  :key="item"
                  class="mb-n7"
                  style="margin:5px 0px;"
                  ><template v-if="data_region.nombre === item"
                    ><b class="item">{{ item }}</b></template
                  ><template v-else>{{ item }}</template></v-list-item
                >
              </v-list>
            </v-col>
            <v-col cols="5" class="mt-n2 ml-n4">
              <v-list>
                <b class="ml-4">Indice {{url_api.tipos_datos[model_comparacion]}}</b>
                <v-list-item
                  v-for="item in val_ranking"
                  :key="item.valor"
                  class="mb-n7"
                  style="margin:5px 0px;"
                  ><template v-if="item.region.id === region">
                     <v-progress-linear
                     
                      :value="item.valor.toFixed(2)" height="10" color="blue">
  
                      </v-progress-linear>
                      <!--  <b class="ml-1"><small>{{ item.valor.toFixed(2) }}%</small></b> -->

                      
                      <h4 style="display:inline;" class="ml-1"
                      >
                      {{ item.valor.toFixed(2) }}%</h4>
                    </template>
                  <template v-else
                    >
                    <v-progress-linear 
                      :value="item.valor.toFixed(2)" height="10" color="blue-grey">
                        
                      </v-progress-linear>
                    <!--   <b class="ml-1"><small>{{ item.valor.toFixed(2) }}%</small></b> -->
                      <h4 style="display:inline;" class="ml-1 font-weight-light"
                      >
                      {{ item.valor.toFixed(2) }}%</h4>
                    </template>
                  </v-list-item>
              </v-list>
            </v-col>
          </v-row>
          </v-card>
        </v-col>
        
      </v-row>
    </div>
  </div>
</template>

<script>
const API_URL = 'https://api.datari.net/datari/data/';
import axios from 'axios';
import Chart8 from "../../components/chartsV2/ApexChart8";


export default {
    components:{
      Chart8
    },
    name: 'View20',
    props: ['url_api','region'],
    data: () => ({
      data_region: [],
      anio: 2017,
      colors_chart8: ["#1565C0", "#FF9800", "#E53935"],
      yaxis_title_chart8: "Porcentaje",
      tooltip_y_chart8: "",
      series: [],
      model_comparacion: 0,
      txt_ranking : [],
      val_ranking: []
    }),

    mounted() {
      this.getRegion();
      this.getDataGraph1();
      this.getDataRanking();
      // this.getDataRegion()
    },
    computed :{
      updateRegion() {
        return this.region;
      },
      updateView(){
        return this.url_api;
      }
    },
    watch: {
      updateRegion(){
        this.getRegion();
        this.getDataGraph1();
        this.getDataRanking();
      },
      updateView(){
        this.model_comparacion =0 ;
        this.getDataGraph1();
        this.getDataRanking();
      }
    },
    methods: {
      async getRegion(){
        const response = await axios.get(API_URL+'regiones/'+this.region+'/');
        let data = response.data;
        this.data_region = data;
      },

      

      async getDataGraph1(){
        
        let data_categoria1 = [];
        let data_categoria2 = [];
        let data_categoria3 = [];
        let categorias =this.url_api.tipos_datos;
        for( let anio of this.url_api.anios){
          let response = await axios.get(
            API_URL+this.url_api.url_api+'?anio_data='+anio+'&region='+this.region
          );
          let data = response.data.results;
          for (var d of data){
            var name1,name2,name3;
            if(d.categoria === 1 || d.categoria.id === 1){
              data_categoria1.push(d.valor.toFixed(2));
              name1 = categorias[0];
            }
            else if(d.categoria === 2 || d.categoria.id === 2){
              data_categoria2.push(d.valor.toFixed(2));
              name2 = categorias[1];
            }
            else if(d.categoria === 3 || d.categoria.id === 3){
              data_categoria3.push(d.valor.toFixed(2));
              name3 = categorias[2];
            }
          }
        }

        


        let data_final = [
          {
            name: name1,
            data: data_categoria1
          },
          {
            name: name2,
            data: data_categoria2
          },
          {
            name: name3,
            data: data_categoria3
          }
        ];
        this.series = data_final;
      },

      async getDataRanking(){
        let elem;
        let ranking = [];
        let regiones = [];
        let data;
        let categoria = this.model_comparacion + 1;
        let response = await axios.get(
            API_URL+this.url_api.url_api+'?anio_data='+this.anio+
            '&categoria='+categoria
        );

        data = response.data.results;
        data.sort((a,b) => b.valor - a.valor);
        for (elem of data){
          ranking.push(elem);
          regiones.push(elem.region.nombre);
        }
        
        this.txt_ranking = regiones;
        this.val_ranking = ranking;
      },

      cambiarAnio(anio){
        this.anio = anio;
        this.getDataRanking();
      },

      cambiarCategoria(categoria){
        if (categoria === undefined) {
          this.model_comparacion = 0;
        }
        else {
          this.model_comparacion = categoria;
          
        }
        
        this.getDataRanking();
      }
    }

    
}
</script>