<template>
    <div id="DonutChart">
        <apexchart type="donut" ref="chart" width="550" :options="chartOptions" :series="series" class="chart-wrapper"></apexchart>
    </div>
</template>

<style scoped>
div.chart-wrapper {
    display: flex;
    align-items: center;
    justify-content: center
}
</style>

<script>
export default {
  name: 'DonutChart',
  props: [
    'data','labels','colors',
    'tipo_data','nombre_serie','titulo'
  ],
  data() {
    return {
      series: [],
      chartOptions: {
        chart: {
            type: 'donut',
            toolbar: {
              show: true,
              offsetX: 80,
              offsetY: 0,
            }
        },
        legend: {
            position: 'top',
            horizontalAlign: 'left',
            onItemHover: {
                highlightDataSeries: true
            },
            fontSize: '14px',
            fontFamily: 'Helvetica, Arial',
            fontWeight: 400,
            width:600,
            offsetY: -20,
        },
        labels: [],
        colors: [],
        title: {
            text: this.titulo,
            align: 'left',
            margin: 55,
            offsetY:-25,
            style: {
              fontSize:  '20px',
              fontWeight:  'light',
              fontFamily:  undefined,
              color:  '#263238'
            },
        }
      }
    }
  },

  mounted(){
  
  },

  computed: {
    updateItem(){
      return this.data;
    }
  },

  watch: {
    updateItem(){
      this.$refs.chart.updateOptions({
        series: this.data,
        labels: this.labels,
        // tipo_dato: this.tipo_dato,
        // serie: this.serie,
        colors: this.colors
      })
    }
  },

}
</script>