<template>
  <div class="text-center">
    <v-chip v-for="tipo in tipos" dark :key="tipo.tipo" style="z-index:1;top:15px;" class="mr-2" :color="tipo.color" @click="hideTipo(tipo.tipo)" >{{tipo.tipo}}</v-chip>
    <v-card flat>
      <div id="mapContainer" class="basemap"></div>
      <div id="tooltip"></div>
    </v-card>
  </div>
</template>

<style scoped>
.basemap {
  width: 100%;
  height: 500px;
  margin-top: -32px;
}
</style>

<style>
.mapboxgl-popup {
max-width: 400px;
font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

#tooltip {
  font-family: Helvetica, Arial, sans-serif;
  position: absolute;
  padding: 4px;
  margin: 8px;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  max-width: 300px;
  font-size: 14px;
  z-index: 9;
  pointer-events: none;
  height: 220px !important;
  width: 300px !important;
}
</style>

<script>
import mapboxgl from "mapbox-gl";
import {ColumnLayer} from '@deck.gl/layers';
import {MapboxLayer} from '@deck.gl/mapbox';

export default {
    props: ['data','lat','lon','codregion','unidad','data_puntos'],
    name: "IEMap1",
    data() {
      return {
        accessToken: 'pk.eyJ1Ijoiam9zZW1jaGF2ZXpnIiwiYSI6ImNranloODJlZzAzeW4yd2xxbjQ2cTJ2aWEifQ.WQZWm73tdhtI79GUZTY-hQ',
        layers: [],
        map: null,
        tipos: [],
        tipos_puntos: []
      }
    },
    mounted(){
      this.tipos = this.data_puntos;
      mapboxgl.accessToken = this.accessToken;
      new mapboxgl.Map({
        container: "mapContainer",
        style: "mapbox://styles/mapbox/streets-v11",
        center: [0,0],
        zoom: 3,
      });
    },
    computed: {
      updateItem(){
        return this.data;
      }
    },
    watch: {
      updateItem(){
        var temp = this.data_puntos;
        this.tipos = temp;
        mapboxgl.accessToken = this.accessToken;
        var zoom_region;
        if(this.codregion === 15){
          zoom_region = 7.3;
        }
        else{
          zoom_region = 6.8;
        }

        // this.map = new mapboxgl.Map({
        //   container: "mapContainer",
        //   style: "mapbox://styles/mapbox/light-v10",
        //   center: [this.lon,this.lat],
        //   zoom: zoom_region,
        //   pitch:10
        // });

        this.map3D = new mapboxgl.Map({
          container: "mapContainer",
          style: "mapbox://styles/mapbox/light-v10",
          center: [this.lon,this.lat],
          zoom: zoom_region+0.5,
          pitch:55,
          bearing: 5

        })

        if(this.data.length != 0){
          // this.regionLayer(this.map,this.data);
          this.layer3D(this.map3D);
        }

      }
    },
    methods: {

      selectTipo(tipo){
        for(var dato in this.tipos){
          if(this.tipos[dato].tipo == tipo){
            return dato;
          }
        }
      },

      hideTipo(tipo){
        var visibility = this.map.getLayoutProperty(tipo, 'visibility');
        var indexTipo = this.selectTipo(tipo);
        if (visibility === 'visible') {
          this.map.setLayoutProperty(tipo, 'visibility', 'none');
          this.tipos[indexTipo].color = '#B5B5B5';
        } else {
          this.map.setLayoutProperty(tipo, 'visibility', 'visible');
          console.log(this.map.getPaintProperty(tipo,'circle-color'));
          this.tipos[indexTipo].color = this.map.getPaintProperty(tipo,'circle-color');
        }
      },

      layer3D(mapa){
        const data_map = this.data;
        const layer = new MapboxLayer({
          id: 'ColumnLayer',
          type: ColumnLayer,
          data: data_map,
  /* props from ColumnLayer class */
          angle: 10,
          coverage: 1,
          diskResolution: 30,
          elevationScale: 70,
          extruded: true,
    
          getElevation: d => d.properties.participacion * 10 ,
          getFillColor: d => d.properties.color,
          getLineColor: [0, 0, 0],
          getLineWidth: 10,
          getPosition: d => d.geometry.coordinates,
          radius: 1500,
        
          pickable: true,
          controller: true,
          highlightColor: [0, 0, 128, 128],
          autoHighlight: true,
          onHover: ({object,x,y}) => {
            const tooltip = document.getElementById('tooltip');

            function formatCL(val){
              var valor = val.toLocaleString();
              var largo_string = valor.length
              var i;
              var stringFormat = ''; 
              for(i of valor){
                if( i === '.' ){
                  i = ',';
                  stringFormat+=i;
                }
                else if(i ===','){
                  i = '.';
                  stringFormat+=i;
                }
                else{
                  stringFormat+=i;
                }
              }
              return stringFormat;
            };

            if(object){
              let nombre = object.properties.nombre;
              let valor = object.properties.valor;
              let tipo = object.properties.tipo_dato;
              let tipo_punto = object.properties.tipo_punto;
              let tipo_valor = object.properties.tipo_valor;
              let anio = object.properties.año;
              let participacion = object.properties.participacion;
              let unidad;

              if (tipo_valor.includes('CIF')){
                valor = '$'+valor;
                unidad = 'Dólares';
              }
              else if(tipo_valor.includes('Peso')){
                unidad = 'Toneladas';
              }

              tooltip.style.top = `${y}px`;
              tooltip.style.left = `${x+20}px`;
              tooltip.innerHTML = `
                <div ><b>${tipo_punto} &nbsp;</b></div>
                <div><b>${nombre}</b></div>
                <div class="text-left ml-3 mt-4"><b>${tipo} - ${anio}</b></div>
                <div class="text-left ml-3 mt-1"><b>${tipo_valor}</b></div>
                <div class="text-left ml-3 ">${formatCL(valor)} ${unidad}</div>
                <div class="text-left ml-3 mt-2"><b>Participación Regional</b></div>
                <div class="text-left ml-3 ">${participacion} %</div>
                
              `;
              tooltip.style.visibility = "visible";
            }
            else {
              tooltip.style.visibility = "hidden";
              tooltip.innerHTML = '';
            }            
            // if(e.object !== undefined){
            //   console.log(e.object,x,y);
            // }
            
          }
        
        });

        var cod_region = this.normalizarCodigo(this.codregion);

        mapa.on('load',() => {

          mapa.addSource('map_regiones', {
            type: 'vector',
            url: 'mapbox://josemchavezg.ckluzc93b32jp28nq05ua9e3e-12lxz',
          },
          'country-label',
          'state-label'
          );

          mapa.addLayer({
            id:'map_region',
            source: 'map_regiones',
            'source-layer': 'region01',
            type: 'fill',
            paint: {
              'fill-color': '#64B5F6',
              'fill-opacity': 0.2,
            },
            "filter": ["==", "codigo_region", cod_region]
          });

         

          mapa.addLayer(layer);

          mapa.scrollZoom.disable();

          mapa.addControl(new mapboxgl.NavigationControl());
          mapa.addControl(new mapboxgl.FullscreenControl());

        
          
          
        });
      },

      regionLayer(mapa,data){
        function isVisible(mapa,layer) {
          return  mapa.getLayoutProperty(layer, 'visibility');
        }

        var cod_region = this.normalizarCodigo(this.codregion);
        mapa.on('load',function(){

          mapa.addSource('map_regiones', {
            type: 'vector',
            url: 'mapbox://josemchavezg.ckluzc93b32jp28nq05ua9e3e-12lxz',
          },
          'country-label',
          'state-label'
          );

          mapa.addSource('map_puntos', {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: data
            }
          }          
          );

  
          
          if(this.codregion != 12){
            mapa.addLayer({
              id:'map_region',
              source: 'map_regiones',
              'source-layer': 'region01',
              type: 'fill',
              paint: {
                'fill-color': '#5D6D7E',
                'fill-opacity': 0.4,
              },
              "filter": ["==", "codigo_region", cod_region]
            });

            mapa.addLayer({
              id:'PASO FRONTERIZO',
              source:'map_puntos',
              type: 'circle',
              layout: {
                'visibility':'visible'
              },
              paint: {
                'circle-radius': [
                  'step',
                  ['get','participacion'],
                  5,
                  23,
                  15,
                  40,
                  22
                ],
                'circle-color':  '#E53935',
                'circle-stroke-width': 1,
              },
              "filter": ['all',["==", "tipo_punto", 'PASO FRONTERIZO']]
            });

           


            mapa.addLayer({
              id:'AEROPUERTO',
              source:'map_puntos',
              type: 'circle',
              layout: {
                'visibility':'visible'
              },
              paint: {
                'circle-radius': [
                  'step',
                  ['get','participacion'],
                  5,
                  23,
                  15,
                  40,
                  20
                ],
                'circle-color':  '#F1C40F',
                'circle-stroke-width': 1,
              },
              "filter": ["==", "tipo_punto", 'AEROPUERTO']
            });

            mapa.addLayer({
              id:'PUERTO',
              source:'map_puntos',
              type: 'circle',
              layout: {
                'visibility':'visible'
              },
              paint: {
                'circle-radius': [
                  'step',
                  ['get','participacion'],
                  5,
                  23,
                  15,
                  40,
                  20
                ],
                'circle-color': '#42A5F5',
                'circle-stroke-width': 0.8,
              },
              "filter": ["==", "tipo_punto", 'PUERTO']
            });

            mapa.addLayer({
              id:'TREN',
              source:'map_puntos',
              type: 'circle',
              filter: ['all',["==", "tipo_punto", 'TREN']],
              layout: {
                'visibility': 'visible'
              },
              paint: {
                'circle-radius': [
                  'step',
                  ['get','participacion'],
                  5,
                  23,
                  15,
                  53,
                  20
                ],
                'circle-color': '#27AE60',
                'circle-stroke-width': 1,
              },
              
            });

          
          }

          mapa.scrollZoom.disable();

          mapa.addControl(new mapboxgl.NavigationControl());
          mapa.addControl(new mapboxgl.FullscreenControl());


          var toggleableLayerIds = ['PASO FRONTERIZO','AEROPUERTO','PUERTO','TREN']; 
          var clickedLayer = toggleableLayerIds[0];
         
          
          for (var i = 0; i < toggleableLayerIds.length; i++) {
            var layers = document.getElementById('menu');
            var id = toggleableLayerIds[i];
            
            mapa.on('click', id, function (e) {
              var data_descrp = '<h3 class="mb-2">'+e.features[0].properties.nombre+'</h3>'+
                '<div class="text-left">'+e.features[0].properties.tipo_dato+': '+e.features[0].properties.valor+'</div>'+
                '<div class="text-left">'+e.features[0].properties.tipo_valor+'</div>'+
                '<div class="text-left">Año : '+e.features[0].properties.año+'</div>'+
                '<div class="text-left">Tipo: '+e.features[0].properties.tipo_punto+'</div>'+
                '<div class="text-left">Comuna: '+e.features[0].properties.comuna+'</div>'+
                '<div class="text-left">Región: '+e.features[0].properties.region+'</div>';
                
              new mapboxgl.Popup()
              .setLngLat(e.lngLat)
              .setHTML(data_descrp)
              .addTo(mapa);
            });

            mapa.on('mouseenter',id, function () {
              mapa.getCanvas().style.cursor = 'pointer';
            });
            
            // Change it back to a pointer when it leaves.
            mapa.on('mouseleave',id, function () {
              mapa.getCanvas().style.cursor = '';  
            });


            // var link = document.createElement('a');
            // link.href = '#';
            // link.className = 'active';
            // link.textContent = id;

            
            // link.onclick = function (e) {
            //   var clickedLayer = this.textContent;
            //   e.preventDefault();
            //   e.stopPropagation();
              
            //   var visibility = mapa.getLayoutProperty(clickedLayer, 'visibility');
              
            //   // toggle layer visibility by changing the layout object's visibility property
            //   if (visibility === 'visible') {
            //   mapa.setLayoutProperty(clickedLayer, 'visibility', 'none');
            //     this.className = '';
            //   } else {
            //     this.className = 'active';
            //   mapa.setLayoutProperty(clickedLayer, 'visibility', 'visible');
            //   }
            // };

            // layers.appendChild(link);
            
            
            
          }
        });

        
       
        

      },

      normalizarCodigo(cod){
        var temp = String(cod).length;
        var cod_region;

        if(temp == 1){
          if(cod == 8){
            cod_region = '08';
          }
          else{
            cod_region = '0000'+String(cod);
          }
        }
        else if(temp == 2){
          if(cod == 16){
            cod_region = String(cod);
          }
          else{
            cod_region = '000'+String(cod);
          }
        }
        return cod_region;
      },

    },

  
  

}
</script>