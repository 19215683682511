<template>
  <div id="ColumnChart2">
    <apexchart type="bar" ref="chart1" width="95%" height="250"
      :options="chartOptions" :series="series" class="chart-wrapper">
    </apexchart>
  </div>
</template>

<style scoped>
div.chart-wrapper {
  display: flex;
  align-items: center;
  justify-content: center
}
</style>

<script>
export default {
    name: 'ColumnChart2Value',
    props: ['data','categories','colors','tipo_data','nombre_serie','titulo'],
    data() {
      return {
        series: [{
          name: '',
          data: []
        }],
        chartOptions: {
          plotOptions:{
            bar: {
              borderRadius: 10,
              distributed: true,
              dataLabels: {
                position: 'top'
              }
            }
          },
          colors: [],
          dataLabels: {
            enabled: true,
            offsetY: -20,
            style: {
              fontSize: '12px',
              colors: ["#304758"]
            },
            formatter: function (val, w) {

              function formatCL(val){
                var valor = val.toLocaleString();
                var largo_string = valor.length
                var i;
                var stringFormat = ''; 
                for(i of valor){
                  if( i === '.' ){
                  i = ',';
                  stringFormat+=i;
                  }
                  else if(i ===','){
                  i = '.';
                  stringFormat+=i;
                  }
                  else{
                  stringFormat+=i;
                  }
                }
                return stringFormat;
              };


              // var datos = w['w'].globals.series[0];
              // var total = 0;
              // datos.forEach(function(dato){
              //     total += dato;
              // })

              // var porcentaje = parseFloat(((val/total)*100).toFixed(2));

              // if(porcentaje != 0.00){
              //     return porcentaje+'%';
              // }else{
              //     return '0%';
              // }
              return formatCL(val);
            },
          }
        },
        xaxis: {
          type: 'category',
          categories: [],
          position: 'bottom',
          axisBorder: {
            show: true
          },
          axisTicks: {
            show:true
          },
          labels: {
            style: {
              fontSize: '13px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
            }
          }
        },
        yaxis: {
          axisBorder: {
            show: true
          },
          axisTicks: {
            show: true,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val + "%";
            }
          }
        },
        title: {
          text: '',
        },
        tooltip: {
          enabled: true,
          theme: 'dark'
        },
        grid:{
          show:false
        }
        

      }
    },

    mounted(){
      this.$refs.chart1.updateOptions({
          series: [{
            name: this.nombre_serie,
            data: this.data,
          }],
          xaxis: {
            categories: this.categories,
            axisBorder: {
              show: true,
              
            },
            axisTicks: {
              show:true
            },
            labels: {
              style: {
                fontSize: '13px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 500,
              },
              formatter: function(value, timestamp, opts) {
                
                  return value
                
                
          }
            }
          },
          colors: this.colors,
          grid:{
            show:false
          },
          // tipo_dato: this.tipo_dato,
          // serie: this.serie,
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
              formatter: function (val) {
                return val + "";
              }
            }
          },
          title: {
            text: this.titulo,
            align: 'left',
            style: {
              fontSize:  '20px',
              fontWeight:  'light',
              fontFamily:  undefined,
              color:  '#263238'
            },
          },
          legend: {
            show:false
          },
          tooltip:{
            show: true,
            theme: 'dark',
            followCursor: true,
            x: {
              show: true,
              formatter: undefined,
            },
            intersect: true
          }
          
        })
    },

    computed: {
      updateData(){
        return this.data;
      }
    },

    watch: {
      updateData(){
        this.$refs.chart1.updateOptions({
          series: [{
            name: this.nombre_serie,
            data: this.data,
          }],
          xaxis: {
            categories: this.categories,
            axisBorder: {
              show: true,
              
            },
            axisTicks: {
              show:true
            },
            labels: {
              style: {
                fontSize: '13px',
                fontFamily: 'Helvetica, Arial, sans-serif',
                fontWeight: 500,
              },
              formatter: function(value, timestamp, opts) {
                
                  return value
                
                
          }
            }
          },
          colors: this.colors,
          grid:{
            show:false
          },
          // tipo_dato: this.tipo_dato,
          // serie: this.serie,
          yaxis: {
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
            labels: {
              show: false,
              formatter: function (val) {
                return val
              }
            }
          },
          title: {
            text: this.titulo,
            align: 'left',
            style: {
              fontSize:  '20px',
              fontWeight:  'light',
              fontFamily:  undefined,
              color:  '#263238'
            },
          },
          legend: {
            show:false
          },
          tooltip:{
            show: true,
            theme: 'dark',
            followCursor: true,
            x: {
              show: true,
              formatter: undefined,
            },
            intersect: true
          }
          
        })
    
      }
    },

    
}
</script>