<template>
	<div id="View3Regional">
    <v-toolbar
    color="light-blue darken-2"
    dark
    flat
    class="rounded">
      <v-toolbar-title>{{url_api.nombre_data}}</v-toolbar-title>
      <v-spacer/>
        <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
           
            dark
            v-bind="attrs"
            v-on="on"
          >
            mdi-information-outline
          </v-icon>
        </template>
        <span>Fuente: {{url_api.fuente}}</span>
      </v-tooltip>
    </v-toolbar>
      <v-card flat class="mt-2">
			<v-card-text>
				<v-row >
				<v-col cols="12" class="mx-auto" style="display: flex; align-items: center; justify-content: center">
					<v-slide-group
						v-model="anio"
						show-arrows
						mandatory
						v-on:change="cambiarAnio"
						>
						<v-slide-item
						v-for="(anio) in url_api.tipos"
						:key="anio"
						v-slot="{ active, toggle }"
						>
								<v-btn
								class="mx-1"
								:input-value="active"
								active-class="blue darken-1 white--text"
								:color="active ? 'blue darken-1  white--text' : 'grey lighten-2'"
								depressed
								
								@click="toggle"
								>
								{{anio}}
							</v-btn>
						</v-slide-item>
					</v-slide-group>
				</v-col>
			</v-row>
	
			<v-row>
				<v-col cols="4" class="text-center" style="display: flex; align-items: center; justify-content: center">
					<div v-if="data_fuente_region.length != 0" >
					<v-col cols="12" class="mx-auto">
					<h3 class="font-weight-light">Gasto en I+D Nacional {{url_api.tipos[anio]}}</h3>

					<h2 class="font-weight-medium mt-4"> ${{totalPais}}</h2>
					<p class="mt-2">Millones de Pesos</p>
					</v-col>
					<v-col cols="12" class="mx-auto">
						<h3 class="font-weight-light">Gasto en I+D  {{url_api.tipos[anio]}} Región {{data_fuente_region[0].region.nombre}}</h3>

						<h2 class="font-weight-medium mt-4"> ${{gasto}}</h2>
						<p class="mt-2">Millones de Pesos</p>
					</v-col>
					<v-col cols="12" class="mx-auto">
						<h3 class="font-weight-light">% Gasto I+D Regional a nivel nacional</h3>
            <v-progress-circular
              :rotate="-90"
              :size="120"
              :width="15"
              :value="porcentaje"
              color="primary"
              class="mt-4"
            >
              <h3 class="black--text">{{porcentaje}}%</h3>
            </v-progress-circular>
					</v-col>
					
				</div>
				</v-col>
				<v-col cols="8">
					<TreeMap :data="data_fuente"/>
				</v-col>
			</v-row>	

			</v-card-text>
		</v-card>
		
			
	</div>
</template>
<script>
import axios from 'axios';
const API_URL = 'https://api.datari.net/datari/data/';
import TreeMap from '../../components/charts/TreeMap.vue';

export default {
	components: {
		TreeMap,
	},
	name: 'View3Regional',
	props: ['url_api','region'],
	data: () => ({
		anio: null,
		data_fuente: [],
		data_fuente_region: [],
		gasto: null,
		porcentaje: null,
		totalPais: null
	}),
	created(){
		document.documentElement.style.overflow = 'hidden';
		this.anio = this.url_api.tipos.length - 1
		this.apiCall();
	},
	computed: {
		update(){
			return this.region
		}
	},
	watch: {
		update(){
			this.apiCall();
		}
	},
	methods: {

		cambiarAnio(anio){
			this.anio = anio;
			this.apiCall();
		},

		calcularTotal(data){
			var d;
			var total = 0;
			for(d of data){
				total += d.gasto;
			}
			return total
		},

		calcularPorcentaje(valor,total){
			var temp = valor / total;
			var perc = temp *100;
			return parseFloat(perc.toFixed(1));
		},

		apiCall(){
			axios.get(API_URL+this.url_api.url_api+'?anio='+this.url_api.tipos[this.anio])
			.then(response =>{ 
				this.data_fuente = response.data.results;
				this.totalPais = (response.data.totalAnual).toLocaleString().replace(',','.');
			});

			axios.get(API_URL+this.url_api.url_api+'?region='+this.region+'&anio='+this.url_api.tipos[this.anio]).then(response => {
					this.data_fuente_region =response.data.results;
					this.gasto = this.data_fuente_region[0].gasto.toLocaleString().replace(',','.');

					this.porcentaje = this.data_fuente_region[0].porcentaje
					
			});

		}

	}
}

</script>