<template>
  <div id="FillChart">
    <apexchart width="100%" :height="height" ref="chart1" type="bar" :options="options" :series="series" class="chart-wrapper">
    </apexchart>	
  </div>
</template>

<!-- Centrar Chart --->

<style scoped>
div.chart-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -50px;
}
</style>

<script>

export default {
  name: 'FillChart',
  props: [
    'data','labels','colors','tipo_data','nombre_serie',
    'titulo','subtitulo','height','region'
  ],
  data() {
    return {
      options: {    
        chart: {
          id: 'FillChart',
          zoom: false,
          toolbar:{
              show:true,
              offsetY: 50,
          },
          stacked: true,
        },
        plotOptions:{
          bar: {
            barWidth: '100%',
            dataLabels: {
            position: 'top',
            borderRadius: 10
           },
          }
        },
        xaxis: {
          type: "categories",
          categories: [],
          style: {
              colors: '#fff',
              fontSize: '16px'
          },
          
        },
        yaxis:{
          show: false,
          labels: {
              formatter: function (val) {
                  return val+'%'
              }
          }
        },
        dataLabels: {
          enabled: true,
          enabledOnSeries: [0],
          offsetY:-25,
          offsetX:1,
          formatter: function(val,w){
            var index = w['dataPointIndex'];
            var data = w['w'].globals.series
            var totalData = data[0][index] + data[1][index]
            var porcentaje = parseFloat(((val / totalData)*100).toFixed(2));
            if(porcentaje != 0.00){
              return porcentaje+'%';
            }else{
              return '0%';
            }
          },
          style: {
            fontSize: '14x',
            colors: ['#000']
          },  
        },
        colors:["#00796B","#CCCCCC"],
        legend: {
            show:false
        },
        grid: {
          show:false
        },
        tooltip:{

          theme:'dark',
          custom: function({series, seriesIndex, dataPointIndex, w}) {

            function formatnumber(numero){
              var valor = numero.toLocaleString();
              var i;
              var stringFormat = ''; 
              for(i of valor){
                if( i === '.' ){
                  i = ',';
                  stringFormat+=i;
                }
                else if(i ===','){
                  i = '.';
                  stringFormat+=i;
                }
                else{
                  stringFormat+=i;
                }
              }

              return stringFormat;
            }

            var index = dataPointIndex;
          
            var dato1 = series[0][index];
            var dato2 = series[1][index]+dato1;
            var categoria = w.globals.labels[index];
            let region;
            if(categoria[0].length > 1){
              region = categoria[0]+' '+categoria[1];
            }else{
              region = categoria;
            }

            let tipo_data = w.config.tipo_data;

          


            
            var porcentaje = parseFloat(((dato1 / dato2)*100).toFixed(2));
            var tool = '<div style="margin: 0 auto;width: 350px; height: 150px;opacity:0.95;" >' +
                '<h4 class="font-weight-light text-left text-white" style="margin-top:10px; margin-left:15px"><B>'+region.toUpperCase()+'</B></h4>'+
                '<h3 class="font-weight-light text-left"  style="margin-top:5px;margin-left:15px">Uso de Internet</h3>'+
                '<h3 class="font-weight-light text-left"  style="margin-top:5px;margin-left:15px"><b>'+tipo_data+'</b></h3>'+
                '<h2 class="font-weight-light text-left" style="margin-top:5px;margin-left:15px"><b>'+porcentaje+'%</b></h2>'+

            '</div>';
            return tool;
          },  
        }
      },
      series: [],
    }
  },
  computed:{
    updateData(){
      return this.data;
    }
  },
  watch: {
    updateData() {
      console.log(this.colors);
      let data_100 = [];
      let dato;

      for(dato of this.data){
        let valor = 100.0-dato;
        data_100.push(valor);
      }

      this.$refs.chart1.updateOptions({
        series: [
          {
            data:this.data,
            name:'a'
          },
          {
            data:data_100,
            name:'b'
          }
        ],
        title:{
          text: this.titulo,
          align: 'left',
          style: {
            fontSize:  '24px',
            fontWeight:  'light',
            fontFamily:  undefined,
            color:  '#263238'
            },
            margin:0,
            offsetY:50
        },
        subtitle:{
          text:this.subtitulo,
          align: 'left',
          style: {
            fontSize:  '18px',
            fontWeight:  'light',
            fontFamily:  undefined,
            color:  '#263238'
            },
            offsetY:80
        },
        xaxis:{
          categories: this.labels,
          labels:{
            show:true,
            style:{
              colors: this.colors,
              fontSize: '14px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 550,
              cssClass: 'apexcharts-xaxis-label',
            }
          }
          
        },
        region: this.region,
        tipo_data:this.tipo_data
      });
    }
  }
};
</script>