<template>
  <div class="fuenteDato">
    <div v-if="url_api.tipo_vista_comuna !== null ">
      <div v-if="comuna == 0">
        <Region :region="region" :url_api="url_api" :fuente="fuente" :ml="menulateral" :regiones="regiones" />
      </div>
      <div v-else>
        <Comuna :comuna="comuna" :region="region" :url_api="url_api" :fuente="fuente"/>
      </div>
    </div>
    <div v-else>
      <Region :region="region" :url_api="url_api" :fuente="fuente" :ml="menulateral" :regiones="regiones"/>
    </div>
  </div>
</template>

<script>
  import axios from 'axios';
  import Region from '../views/Region/FuenteDatoRegion';
  import Comuna from '../views/Comuna/FuenteDatoComuna.vue'
  const API_URL = 'https://api.datari.net/datari/data/'

  export default {
    name: 'VistaFuenteDato',
    props: ['region','comuna','categoria','url','menulateral','no_comunas','regiones'],
    components: { 
      Region,
      Comuna
    },
    data: () => ({
      dataFuenteDato: [],
      fuente: null,
      data_region: null,
      url_api: null,
      tipos_api: []

    }),
    created(){

      axios.get(API_URL+'categorias/'+this.categoria+'/')
        .then(response => {
          this.dataFuenteDato = response.data.fuenteDato;
          var data;
          for(data in this.dataFuenteDato){
            if(this.dataFuenteDato[data].url === this.url){
              this.fuente = this.dataFuenteDato[data].descripcion;
            }
          }   
      });

      
      this.crearData();

    },
    computed:{
      updateItem(){
        return this.categoria,this.url
      },
    },
    watch: {
      updateItem(){

        axios.get(API_URL+'categorias/'+this.categoria+'/')
        .then(response => {
          this.dataFuenteDato = response.data.fuenteDato;
          var data;
          for(data in this.dataFuenteDato){
            if(this.dataFuenteDato[data].url === this.url){
              this.fuente = this.dataFuenteDato[data].descripcion;
            }
          }   
        });

        // axios.get(API_URL+'regiones/'+this.region+'/')
        // .then(response => this.data_region = response.data);

        this.crearData();

      }
    },
    methods: {
      crearData(){

        
        this.url_api = this.obtenerUrlApi();

        if( this.url_api.tipo_vista_comuna === null ){
          console.log('no_comunas')
          this.$emit('update',true,0);
        }
        else {
          console.log('comunas')
          this.$emit('update',false,this.comuna);
        }
      },

      generarAnios(anio1,anio2){
        var lista = [];
        var i;
        for(i=anio1; i <= anio2;i++){
          lista.push(i);
        }
        lista = lista.sort((a, b) => b - a);
        return lista
      },

      tipoFuente(url){
        var tipo;
        if(url.includes('regional')){
          tipo = 'Regional';
        }
        else{
          tipo = 'No Regional';
        }
        return tipo;
      },
            
      //Estructura para cada nueva fuente de dato.
      obtenerUrlApi(){
        var urlapi;
        var temp = this.url.split('_');

        // No Regionales


        if(temp[1] === 'exportacion' || temp[1] === 'importacion'){
          if(temp[2] !== 'agricola'){
            var tipos;
            var t;
            if(temp[1] === 'exportacion'){
              tipos = [
                {'value': 'peso','text':'PESO'},
                {'value': 'monto','text':'FOB (US$)'}  
              ];
              t = 'exportación';
            }
            else if(temp[1] === 'importacion'){
               tipos = [
                {'value': 'peso','text':'PESO'},
                {'value': 'monto','text':'CIF (US$)'}  
              ];
              t = 'importación';
            }

            urlapi = {
              url: this.url,
              url_api: 'importacion_exportacion_aduana/',
              url_api_puntos: 'puntos_ingreso_salida/',
              //TIPO: IMPORTACIÓN O EXPORTACIÓN
              tipo: t,
              tipos_puntos: ['PASO FRONTERIZO','AEROPUERTO','PUERTO'],
              tipos_unidad: ['Dólares','Toneladas'],
              tipos_valor: tipos,
              tipo_vista_region: 'View1',
              anios:  [2002,2003,2004,2005,2006,2007,2008,2009,2010,2011,2012,2013,2014,2015,2016,2017,2018,2019,2020],
              vistas: ['Regiones','Puntos de ingreso/salida'],
              filtros: ['Tipo Punto Ingreso/Salida','Punto Ingreso/Salida','Tipo de Valor'],
              nombre_data: this.capitalize(temp[1]),
              fuente: 'ADUANA',
              tipo_vista_comuna: null,
              
            }
          }
          else{

            urlapi = {
              url:this.url,
              url_api: 'exportaciones_agricolas_region/',
              anios: this.generarAnios(2008,2020),
              filtros: ['Año','Tipo Exportación'],
              tipos: ['Agrícola','Forestal','Pecuario','Total'],
              tipo_vista_region:'View9',
              nombre_data: 'Exportaciones Silvoagropecuarias',
              fuente: 'ADUANA - INE',
              tipo_vista_comuna: null,
            }
                  
          }
          
        }
        else if(temp[1] === 'transporte'){
          urlapi = {
            url: this.url,
            url_api: 'transporte_territorial_aduana/',
            url_api_puntos: 'puntos_ingreso_salida/',
            //TIPO: IMPORTACIÓN O EXPORTACIÓN
            tipos: ['ingreso','salida'],
            tipos_puntos: ['PASO FRONTERIZO'],
            anios: [2005,2006,2007,2008,2009,2010,2011,2012,2013,2014,2015,2016,2017,2018,2019,2020],
            tipos_valor: ['particulares', 'de pasajeros','de carga','carga en toneladas'],
            tipo_vista_region: 'View5',
            vistas: ['Datos Regionales','Puntos de ingreso/Salida'],
            filtros: ['Punto Ingreso/Salida','Tipo de Valor'],
            nombre_data: 'Tráfico Terrestre',
            fuente: 'ADUANA',
            tipo_vista_comuna: null,
          
          }
        }


        else if (temp[1] === 'materialidad' && temp[2] === 'vivienda'){

           urlapi = {
            url: this.url,
            url_api: 'materialidad_vivienda/',
            nombre_data: 'Materialidad Vivienda',
            anios:[2006, 2009, 2011, 2013, 2015, 2017],
            tipo: tipo,
            url_tipo: 'categoria_materialidad_vivienda/',
            tipos_datos: ["Aceptable", "Recuperable", "Irrecuperable"],
            tipo_vista_region: 'View20',
            fuente: 'CASEN MINISTERIO DESARROLLO SOCIAL',
            tipo_vista_comuna: null,
          }
        }

        else if (temp[1] === 'deficit' && temp[2] === 'habitacional'){
          urlapi = {
            url: this.url,
            url_api: 'deficit_habitacional/',
            nombre_data: 'Deficit Habitacional',
            anios: [2006, 2009, 2011, 2013, 2015, 2017],
            tipo_vista_region: 'View20',
            tipos_datos: ['Calidad','Allegamiento Externo','Allegamiento Hacinados'],
            fuente: 'CASEN MINISTERIO DESARROLLO SOCIAL',
            tipo_vista_comuna: null,
          }
        }

        else if(temp[1] === 'pobreza' && temp[2] == 'ingreso') {
          urlapi =  {
            url: this.url,
            url_api:'pobreza_multidimensional/',
            nombre_data: 'Pobreza multidimensional',
            anios:  [2015, 2017],
            tipo_vista_region:'View16',
            tipos_datos: ['Extremos','No Extremos','No Pobres'],
            fuente: 'CASEN MINISTERIO DESARROLLO SOCIAL',
            tipo_vista_comuna: null,
          }
        }

        else if(temp[1] === 'hacinamiento'){
          urlapi={
            url: this.url,
            url_api: 'hacinamiento/',
            nombre_data: 'Índice de Hacinamiento',
            anios:  [2006, 2009, 2011, 2013, 2015, 2017],
            tipo_vista_region: 'View17',
            categorias: ['Sin Hacinamiento','Medio Bajo','Medio Alto','Critico'],
            fuente: 'CASEN MINISTERIO DESARROLLO SOCIAL',
            tipo_vista_comuna: null,
          }
        }

        else if(temp[1] === 'est' && temp[2] === 'empresas'){
          var tipo;
          var nombre_vista;
          var api;
          var api_tipo;
          var view;


          if(temp[3] === 'tramo'){
            tipo = 'tramo';
            nombre_vista = 'Estadísticas Empresas por Tramo';
            api = 'est_empresas_tramo/';
            api_tipo = 'tramo_empresas/';
            view = "View12"
          }
          else if(temp[3] === 'rubro'){
            tipo = 'rubro';
            nombre_vista = 'Estadísticas Empresas por Rubro';
            api = 'est_empresas_rubro/';
            api_tipo = 'rubro_empresas/';
            view="View13"
          }

           

          urlapi = {
            url: this.url,
            url_api: api,
            tipo_vista_region: view,
            tipo_vista_comuna: null,
            nombre_data: nombre_vista,
            anios: this.generarAnios(2005,2019),
            tipo: tipo,
            url_tipo: api_tipo,
            filtros: ['Empresas', 'Ventas'],
            tipos_datos: ['Empresas', 'UF'],
            fuente: 'SII'
          }

        }


        else if(temp[1]==='uso' && temp[2] === 'internet'){
          urlapi = {
            url: this.url,
            url_apis:[ 
              'data_internet_banca/',
              'data_internet_compraventa/',
              'data_internet_educacion/',
              'data_internet_entretenimiento/',
              'data_internet_tramitespublicos/',
              'data_internet_usobuscadores/',
              'data_internet_usuarios/'
            ],
            tipo_vista_region:'View18',
            nombre_data: 'Uso de Internet',
            tipos: [
              {
                id: 0,
                text: 'Banca Electronica',
                descripcion: 'Porcentaje de uso de internet para operación de banca electrónica',
                tooltip: 'Operación Banca electrónica'
              },
              {
                id: 1,
                text: 'Compra Venta',
                descripcion: 'Porcentaje de uso de internet para compra y venta de artículos y servicios',
                tooltip: 'Compra y venta de artículos y servicios'
              },
              {
                id: 2,
                text: 'Educación',
                descripcion:'Uso de internet para actividades de educación formal y capacitación',
                tooltip: 'Actividades de educación y capacitación'
              },
              {
                id: 3,
                text: 'Entretenimiento',
                descripcion: 'Porcentaje de uso de internet para entretenimiento',
                tooltip: 'Entretenimiento'
              },
              {
                id: 4,
                text: 'Trámites Públicos',
                descripcion: 'Porcentaje de uso de internet para trámites en línea con instituciones públicas',
                tooltip: 'Trámites en línea con instituciones públicas'
              },
              {
                id: 5,
                text: 'Uso Buscadores',
                descripcion: 'Uso de internet para obtener información en buscadores',
                tooltip: 'Obtener información en buscadores'
              },
              {
                id: 6,
                text: 'Usuarios',
                descripcion: 'Porcentaje de usuarios de internet, por región',
                tooltip: 'Porcentaje de usuarios de internet'
              },
            ],
            anios: [2013,2015,2017,2020],
            tipo_vista_comuna: null,
            fuente: 'FUNDACIÓN PAÍS DIGITAL'
          }
        }

        else if(temp[1] === 'permisos' && temp[2] === 'circulacion'){
          urlapi = {
            url: this.url,
            tipo_vista_region:'View19',
            tipo_vista_comuna: 'View1',
            nombre_data: 'Permisos de circulación',
            anios: this.generarAnios(2008,2019),
            url_apis:[
              'vehiculos_segun_motor/',
              'vehiculos_en_circulacion/',
              'vehiculos_cataliticos_nocataliticos/'
            ],
            fuente: 'INE'
          }
        }

        //Regionales
        else if(temp[1] === 'pib'){

          urlapi = {
            url: this.url,
            url_api: 'pib_anual/',
            tipo_vista_region: 'View2',
            filtros: ['Comparar entre 2 Regiones','Datos Regionales'],
            nombre_data: 'PIB Regional', 
            tipo_vista_comuna: null,
            fuente: 'BANCO CENTRAL'
          }
        }
        else if(temp[1] === 'presupuesto' && temp[2] === 'regional'){

          urlapi = {
            url: this.url,
            url_api: 'presupuesto_regional/',
            tipo_vista_region: 'View4',
            filtros: ['mes','anio'],
            meses: ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'],
            anios:  [2011,2012,2013,2014,2015,2016,2017,2018,2019,2020,2021],
            anio: 2019,
            nombre_data: 'Presupuesto Regional',
            fuente: 'SUBDERE',
            tipo_vista_comuna: null,
            
          }   
        }
        else if(temp[1] === 'gasto' && temp[2] === 'id' && temp[3] === 'regional'){
          
          urlapi = {
            url: this.url,
            url_api: 'gasto_regional_innovacion/',
            tipo_vista_region: 'View3',
            filtros: ['anio'],
            tipos: [2009,2010,2011,2012,2013,2014,2015,2016,2017],
            nombre_data: 'Gasto en I+D Regional',
            fuente: 'MINISTERIO DE ECONOMÍA',
            tipo_vista_comuna: null, 
          }   
        }
        else if(temp[1] === 'viviendas' && temp[2] === 'unid' ){
          urlapi = {
            url: this.url,
            url_api: 'unidades_viviendas_tramo_superficie/',
            tipo_vista_region: 'View6',
            tipos_valor: ['Unidades','Superficie'],
            meses: ['Enero','Febrero','Marzo','Abril','Mayo','Junio','Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'],
            tramos: ['1-35','36-50','51-70','71-100','101-140','140+'],
            filtros: ['anio','mes'],
            anios:  [2002,2003,2004,2005,2006,2007,2008,2009,2010,2011,2012,2013,2014,2015,2016,2017,2018,2019,2020],
            nombre_data: 'Estadisticas Regionales sobre Viviendas', 
            fuente: 'INE (OBS. URBANO - MINVU)',
            tipo_vista_comuna: null,
          } 
        }

        else if(temp[1] === 'superficie'){
          if (temp[2] === 'plantada'){
              urlapi ={
                url: this.url,
                url_api: 'superficie_sembrada_por_grupo_cultivo_region/',
                tipo_vista_region: 'View11',
                anios: [2007],
                nombre_data: 'Superficie Sembrada según grupo de cultivo',
                tipos: ['Total','Cereales','Leguminosas','Industriales','Hortalizas','Flores','Forrajeras','Frutales','Viñas','Viveros','Semilleros','Forestales'],
                fuente: 'INE - CENSO AGROPECUARIO 2007',
                tipo_vista_comuna: null,
              }
          }
          
          else if(temp[2] === 'urbana' || temp[2] === 'agricola' || temp[2] === 'desprovista'){
            var nombre;
            var api;

            if(temp[2] === 'urbana'){
              nombre = 'Superficie Urbana e Industrial';
              api = 'superficie_area_urbana_industrial/';
            }
            else if(temp[2] === 'agricola'){
              nombre =  'Superficie Agrícola';
              api = 'superficie_area_agricola/';
            }
            else if(temp[2] === 'desprovista'){
              nombre = 'Superficie Desprovista de Vegetación';
              api = 'superficie_area_desprovista_vegetacion/';
            }

            urlapi = {
              url: this.url,
              url_api: api,
              tipo_vista_region: 'View7',
              anios: this.generarAnios(1999,2019),
              filtros: ['Año'],
              nombre_data: nombre,
              fuente: 'INE - CONAF',
              tipo_vista_comuna: null,
            }
          }
        }

        else if(temp[1] === 'generacion'){
          var nombre;
          var api;
          var anios;
          if(temp[2] === 'instalada'){
            nombre = 'Capacidad Instalada de Generación';
            api = 'capacidad_instalada_generacion/';
            anios = [2020];
          }
            // cambiar URL API
          else if(temp[2] === 'ernc'){
            nombre =  'Generación ERNC';
            api = 'capacidad_generacion_ernc/';
            anios = [2010];
          }

          urlapi = {
            url: this.url,
            url_api: api,
            tipo_vista_region: 'View8',
            anios: anios,
            nombre_data: nombre,
            fuente: 'MINISTERIO DE ENERGIA',
            tipo_vista_comuna: null,
          }
        }

        else if(temp[1] === 'bienes'){
          if(temp[2] === 'no' && temp[3] === 'agricolas'){
            
               urlapi = {
                  url: this.url,
                  url_api: 'est_bienes_raices_no_agricolas_region/',
                  tipo_vista_region: 'View10',
                  anios: [2020],
                  nombre_data: 'Estadísticas Bienes Raices No Agrícolas',
                  tipos: ['Afectos','Exentos','Total'],
                  fuente: 'SII',
                  tipo_vista_comuna: null,
                }
            
          }
        }

        else if(temp[1] === 'avaluo'){
          if(temp[2] === 'predios' && temp[3] === 'agricolas'){
            urlapi = {
              url: this.url,
              url_api: 'avaluos_predios_agricolas_noagricolas/',
              tipo_vista_region: 'View14',
              anios: [2020,2019],
              nombre_data: 'Avaluo Predios Agrícolas y No Agrícolas',
              tipos: ['Afectos','Exentos'],
              fuente: 'SII',
              tipo_vista_comuna: null,
            }
          }
        }


        else if(temp[1] === 'patentes'){
          urlapi = {
            url: this.url,
            url_api: 'patentes_registradas/',
            tipo_vista_region: 'View15',
            nombre_data: 'Patentes Registradas',
            fuente: 'INAPI',
            tipo_vista_comuna: null,
          }
        }

        else if(temp[1] === 'presupuesto' && temp[2] === 'municipal'){
          urlapi = {
            url: this.url,
            tipo_vista_region: 'View21',
            tipo_vista_comuna: 'View2',
            nombre_data: 'Presupuesto municipal',
            url_api: 'evolucion_presupuestaria/',
            anios: [2015,2016,2017,2018,2019],
            fuente: 'INE'
          }
        }
        
        return urlapi
      },

      capitalize(word) {
        if(word === 'exportacion'){
          return 'Exportación'
        }else if(word === 'importacion'){
          return 'Importación'
        }
        else{
          return word[0].toUpperCase() + word.slice(1);
        }
      }




    }

  }
</script>
