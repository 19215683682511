<template>
  <div id="Chart">
    <apexchart type="" ref="chart" width="100%" height="290'"
    :options="chartOptions" :series="series" class="chart-wrapper" />
  </div>
</template>

<style scoped>
div.chart-wrapper {
  display: flex;
  align-items: center;
  justify-content: center
}
</style>

<script>
export default {
  name:'Chart',
  props: [
    'data','data2','labels','colors','tipo_data','unidad',
    'nombre_serie1','nombre_serie2','titulo','subtitulo'
  ],
  data() {
    return {
      series: [],
      chartOptions:{
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          title: {
            text: this.tipo_data
          },
          labels:{
            show:false
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          theme: 'dark',
          y: {
            formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {

              function formatnumber(numero){
                var valor = numero.toLocaleString();
                var i;
                var stringFormat = ''; 
                for(i of valor){
                  if( i === '.' ){
                    i = ',';
                    stringFormat+=i;
                  }
                  else if(i ===','){
                    i = '.';
                    stringFormat+=i;
                  }
                  else{
                    stringFormat+=i;
                  }
                }

                return stringFormat;
              };

              let unidad = w.config.unidad;
              return formatnumber(value)+' '+unidad
            }
          }
        },
        unidad: this.unidad
      }
    }
  },

  mounted(){
    this.$refs.chart.updateOptions({
      series: [
        {
          data:this.data,
          name:this.nombre_serie1
        },
        {
          data:this.data2,
          name:this.nombre_serie2
        }
      ],
      xaxis: {
        categories: this.labels
      },
      title:{
        text: this.titulo,
        align: 'left',
        style: {
          fontSize:  '22px',
          fontWeight:  'light',
          fontFamily:  undefined,
          color:  '#263238'
          },
          margin:0,
          offsetY:-5
      },
      subtitle:{
        text:this.subtitulo,
        align: 'left',
        style: {
          fontSize:  '16px',
          fontWeight:  'light',
          fontFamily:  undefined,
          color:  '#263238'
          },
          offsetY:25
      },

    });
    
  },

  computed: {
    updateItem(){
      return this.data;
    }
  },

  watch: {
    updateItem(){
      this.$refs.chart.updateOptions({
        series: [
          {
            data:this.data,
            name:this.nombre_serie1
          },
          {
            data:this.data2,
            name:this.nombre_serie2
          }
        ],
        xaxis: {
          categories: this.labels
        },
        title:{
          text: this.titulo,
          align: 'left',
          style: {
            fontSize:  '22px',
            fontWeight:  'light',
            fontFamily:  undefined,
            color:  '#263238'
            },
            margin:0,
            offsetY:-5
        },
        subtitle:{
          text:this.subtitulo,
          align: 'left',
          style: {
            fontSize:  '16px',
            fontWeight:  'light',
            fontFamily:  undefined,
            color:  '#263238'
            },
            offsetY:25
        },

      });
    }
  },

}
</script>