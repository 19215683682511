<template>
    <div id="View13Region">
      <div id="header">
        <v-app-bar color="light-blue darken-2" class="rounded" dense flat dark>
          <h2 class="font-weight-medium">{{url_api.nombre_data}}</h2>
          <v-spacer/>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                dark
                v-bind="attrs"
                v-on="on"
              >
                mdi-information-outline
              </v-icon>
            </template>
            <span>Fuente: {{url_api.fuente}}</span>
          </v-tooltip>
        </v-app-bar>
      </div>
      <div id="filtros" class="mt-1">
        <v-card flat height="60">
          <v-card-text>
            <v-row style="margin-top:-15px;">
              <v-col cols="2" >
                <v-select
                v-model="anio"
                :items ="url_api.anios"
                label ="Año"
                item-text="id"
                dense
                outlined
                v-on:change="cambiarAnio">
                </v-select>
              </v-col>
              <v-col >
                <v-select
                v-model="tipo"
                :items ="tipos"
                item-text="tipo"
                item-value="id"
                dense
                outlined
                :label ="url_api.tipo"
                v-on:change="cambiarTipo">
                </v-select>
              </v-col>
              <v-col cols="3">
                <v-select
                  v-model="select"
                  :items ="url_api.filtros"
                  item-text="tipo"
                  item-value="id"
                  dense
                  outlined
                  label="Tipo de dato"
                  v-on:change="cambiarSelect">
                </v-select>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </div>
      <div id="RegionInfo" style="margin-top:-5px;">
        <v-row>
          <v-col cols="4">
            <v-card flat height="135">
              <v-card-title class="justify-center">
                <h3 class="font-weight-medium">País</h3>
              </v-card-title>
              <v-card-text class="text-center" style="margin-top:-10px;">
                <div v-if="select === 'Empresas'">
                  <h3 class="font-weight-light mb-4">
                    Cantidad de Empresas - {{anio}}
                  </h3>
                  
                  <h1 class="font-weight-medium mt-2" style="display:inline">
                    {{total_pais}}
                  </h1>
                  <h3 class="font-weight-medium mt-2" style="display:inline">
                    {{tipo_dato}}
                  </h3>
                </div>
                <div v-else>
                   <h3 class="font-weight-light mb-4">
                    Ventas Empresas - {{anio}}
                  </h3>
                  
                  <h1 class="font-weight-medium mt-2" style="display:inline">
                    ${{total_pais}}
                  </h1>
                  <h3 class="font-weight-medium mt-2" style="display:inline">
                    {{tipo_dato}}
                  </h3>
                </div>
              </v-card-text>
            </v-card>
            <v-card flat class="mt-2" height="185">
              <v-card-title class="justify-center">
                <h3 class="font-weight-medium">{{data_region.nombre}}</h3>
              </v-card-title>
              <v-card-text class="text-center" style="margin-top:-10px;">
                <h3 v-if="select === 'Empresas'" class="font-weight-light mb-2">
                  Cantidad de Empresas - {{anio}}
                </h3>
                 <h3 v-else class="font-weight-light mb-2">
                  Ventas - {{anio}}
                </h3>
                
                <v-row class="mt-1">
                  <v-col>
                    <h3 class="font-weight-light mb-4">Total Rubro</h3>

                     <h1 v-if="select === 'Empresas'" class="font-weight-medium mt-2" style="display:inline">
                      {{total_region}}
                    </h1>
                    <h1 v-else class="font-weight-medium mt-2" style="display:inline">
                      ${{total_region}}
                    </h1>
                    <h3 class="font-weight-medium mt-2" style="display:inline">
                      {{tipo_dato}}
                    </h3>
                  </v-col>
                  <v-col>
                    <h3 class="font-weight-light mb-4">Total Región</h3> 
                    <h1 class="font-weight-medium mt-2" style="display:inline" v-if="select === 'Ventas'">${{total_rubro}}</h1>
                    <h1 class="font-weight-medium  " style="display:inline" v-else>{{total_rubro}}</h1>
                    <h3 class="font-weight-medium mt-2" style="display:inline">
                      {{tipo_dato}}
                    </h3>
                  </v-col>
                </v-row>


                
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="8">
            <v-card flat height="330">
              <v-card-text>
                <column-chart-perc :height="295"  :data="data_graph3"
                :categories="labels_graph3" :colors="colors_graph3"
                :tipo_data="tipo_dato" :nombre_serie="select"
                :titulo="'Comparación Regional - '+select" :subtitulo="data_tipo.tipo"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
     
        <v-row style="margin-top:-15px">
          <v-col>
            <v-card flat height="420">
              <v-card-title class="justify-center">
                  <h3 class="font-weight-light">Distribución empresas según rubro</h3>
              </v-card-title>
              <v-card-text style="margin-top:-20px">
                <TreeMap :data="data_graph1" :labels="labels_graph1" :tipo_dato="tipo_dato" :nombre_serie="select" :colors="colors2"/>
              </v-card-text>
            </v-card>
          </v-col>
           <v-col>
            <v-card flat height="420">
              <v-card-text>
              <lineChart :data="data_graph2" :height="370"
              :titulo="select+' '+ url_api.anios[url_api.anios.length-1]+'-'+url_api.anios[0]+' - '+nombre_region"
              :labels="labels_graph2" :serie="serie_data2" :subtitulo="data_tipo.tipo" 
              :tipo_serie="tipo_serie"
              /> 
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
  </div>
</template>

<script>
import axios from 'axios';
const API_URL = 'https://api.datari.net/datari/data/';
// import PieChart from '../../../components/charts/PieChart3.vue';
import lineChart from '../../components/chartsV2/LineChart.vue';
import ColumnChart from '../../components/charts/ColumnChart.vue';
import TreeMap from '../../components/charts/TreeMap3.vue';
import ColumnChartPerc from '../../components/chartsV2/ColumnChartPerc';



export default {
  components: {
    // PieChart,
    lineChart,
    ColumnChartPerc,
    ColumnChart,
    TreeMap
  },
  name: 'View13Region',
  props: ['url_api','region'],
  data: () => ({
    select: null,
    anio: null,
    tipo: null,
    tipos: [],
    data_anual_region: [],
    data_anual_tipo_region: [],
    data_anios_region: [],
    data_anual_tipo_pais: [],
    data_anual_pais: [],
    data_regiones: [],
    tipo_dato: null,
    total_pais: null,
    total_region: null,
    porcentaje_region: null,
    data_tipo: [],
    data_region: [],
    data_graph1: [],
    data_graph2: [],
    serie_data2:null,
    tipo_serie:null,
    data_graph3: [],
    labels_graph1: [],
    labels_graph2: [],
    labels_graph3:[],
    total_rubro: null,
    nombre_region:null,
    colors: [],
    colors_graph3:[],
    colors2: ["#000099","#FBC02D","#CC3399",'#003333', '#546E7A', '#FF0033', '#999966', '#669933', '#0000CC', '#6600CC',"#006633",'#f48024', '#CC0033',"#CCCC00","#66BB6A"],
    tab: null,
    vistas: ['Datos Anuales Rubro','Comparación Regional Rubro']
  }),
  mounted(){
    document.documentElement.style.overflow = 'hidden';
    this.anio = this.url_api.anios[0];
    this.select = this.url_api.filtros[0];
    this.tipo_dato = this.url_api.tipos_datos[0];
    this.cargarTipos();
    this.tipo = 1;

    axios.get(API_URL+'regiones/'+this.region+'/')
    .then(response => {
      this.data_region = response.data;
      this.nombre_region = response.data.nombre;
    });

    axios.get(API_URL+this.url_api.url_tipo+this.tipo+'/')
    .then(response => this.data_tipo = response.data);

    

    // Cargar Tramo o Rubro segun Corresponda.
    this.sleep(1000).then(() => {
      this.crearDataAnualTramo();
      this.crearDataAnual();
      this.crearDataTramo();
    });
   
  },
  computed: {
    updateItem(){

      return this.region;
    }
  },
  watch: {
    updateItem(){
      axios.get(API_URL+'regiones/'+this.region+'/')
      .then(response => {
        this.data_region = response.data;
        this.nombre_region = response.data.nombre;
      });

      this.sleep(1000).then(() => {
        this.crearDataAnualTramo();
        this.crearDataAnual();
        this.crearDataTramo();
      })
      
    }
  },
  methods: {

    cargarTipos(){
        axios.get(API_URL+this.url_api.url_tipo)
        .then(response => {
          if(this.url_api.tipo === 'rubro'){
            this.tipos = response.data.results;
          }
          else{
            this.tipos = response.data.results;
          }
        });

    },


    crearDataTramo(){
      axios.get(API_URL+this.url_api.url_api+'?region='+this.region+'&rubro='+this.tipo)
      .then(response => {
        this.data_anios_region = response.data.data_rubro_anios;
        this.data_graph2 = this.getGraphicData(this.data_anios_region,1).series;
        this.labels_graph2 = this.getGraphicData(this.data_anios_region,1).categorias;
        if(this.select === 'Ventas'){
          this.serie_data2 = 'Ventas en UF';
          this.tipo_serie = 'UF';
        }
        else{
          this.serie_data2 = 'Cantidad de empresas';
          this.tipo_serie = 'Empresas';
        }
      })
    },




    crearDataAnual(){
      //Region
      axios.get(API_URL+this.url_api.url_api+'?region='+this.region+'&anio='+this.anio)
      .then(response => {
        // Región
        this.data_anual_region = response.data.data_anual_region;
        if(this.select === 'Ventas'){
          this.total_rubro = this.formatCL(this.data_anual_region.total_anual_ventas);
        }
        else{
          this.total_rubro = this.formatCL(this.data_anual_region.total_anual_empresas);
        }
        var x = this.data_anual_region.datos_rubros;
        this.data_graph1 = this.getGraphicData(x,3).series;
        this.labels_graph1 = this.getGraphicData(x,3).categorias;
        this.colors = this.getGraphicData(x,3).colors;
      });

      axios.get(API_URL+this.url_api.url_api+'?anio='+this.anio+'&rubro='+this.tipo)
      .then(response => {
        this.data_regiones = response.data.data_anual_regiones;
        let data_graph = this.getGraphicData(this.data_regiones,2);
        this.data_graph3 = data_graph.series;
        this.labels_graph3 = data_graph.categorias;
        this.colors_graph3 = data_graph.colors;
      });
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    crearDataAnualTramo(){
      if(this.tipo != null){

        axios.get(API_URL+this.url_api.url_api+'?region='+this.region+'&anio='+this.anio+'&rubro='+this.tipo)
        .then(response => {
          // Región
          this.data_anual_tipo_region = response.data.data_anual_region;
          // País
          this.data_anual_tipo_pais = response.data.data_anual_pais;


          if(this.select === 'Ventas'){
            this.total_region = this.formatCL(this.data_anual_tipo_region.valores.total_ventas_rubro);
            this.total_pais = this.formatCL(this.data_anual_tipo_pais.total_ventas);
            this.tipo_dato = this.url_api.tipos_datos[1];
            this.porcentaje_region = this.data_anual_tipo_region.porcentajes.participacion_ventas;
          }
          else{
            this.total_region = this.formatCL(this.data_anual_tipo_region.valores.total_empresas_rubro);
            this.total_pais = this.formatCL(this.data_anual_tipo_pais.total_empresas);
            this.tipo_dato = this.url_api.tipos_datos[0];
            this.porcentaje_region = this.data_anual_tipo_region.porcentajes.participacion_empresas;
          }
          
        });
      }
      
    },


    cambiarAnio(anio){
      this.anio = anio;
      this.crearDataAnual();
      this.crearDataAnualTramo();
    },
    cambiarTipo(tipo){
      this.tipo = tipo;
      axios.get(API_URL+this.url_api.url_tipo+this.tipo+'/')
      .then(response => this.data_tipo = response.data);
      this.crearDataAnualTramo();
      this.crearDataTramo();
      this.crearDataAnual();
      
    },

    cambiarSelect(select){
      this.select = select;
    
      var x = this.data_anual_region.datos_rubros;
      this.data_graph1 = this.getGraphicData(x,3).series;
      this.labels_graph1 = this.getGraphicData(x,3).categorias;
      this.colors = this.getGraphicData(x,3).colors;

      this.data_graph3 = this.getGraphicData(this.data_regiones,2).series;
      this.labels_graph3 = this.getGraphicData(this.data_regiones,2).categorias;
      this.colors_graph3 = this.getGraphicData(this.data_regiones,2).colors;
      this.crearDataTramo();
      // this.crearDataAnual();

      if(this.select === 'Ventas'){
        this.total_region = this.formatCL(this.data_anual_tipo_region.valores.total_ventas_rubro);
        this.total_pais = this.formatCL(this.data_anual_tipo_pais.total_ventas);
        this.tipo_dato = this.url_api.tipos_datos[1];
        this.porcentaje_region = this.data_anual_tipo_region.porcentajes.participacion_ventas;
        this.total_rubro = this.formatCL(this.data_anual_region.total_anual_ventas);
      }
      else{
        this.total_region = this.formatCL(this.data_anual_tipo_region.valores.total_empresas_rubro);
        this.total_pais = this.formatCL(this.data_anual_tipo_pais.total_empresas);
        this.tipo_dato = this.url_api.tipos_datos[0];
        this.porcentaje_region = this.data_anual_tipo_region.porcentajes.participacion_empresas;
        this.total_rubro = this.formatCL(this.data_anual_region.total_anual_empresas);

      }

    },

    formatCL(val){
      var valor = val.toLocaleString();
      var largo_string = valor.length
      var i;
      var stringFormat = ''; 
      for(i of valor){
        if( i === '.' ){
          i = ',';
          stringFormat+=i;
        }
        else if(i ===','){
          i = '.';
          stringFormat+=i;
        }
        else{
          stringFormat+=i;
        }
      }
      return stringFormat;
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    getGraphicData(data,labelType){
      // Tipo 1 -> Años, Tipo 2 -> Regiones
      var element;
      var labels = [];
      var series = [];
      var colors = [];

      if(labelType == 1){
        for(element of data){
          if(!labels.includes(element.anio)){
            labels.push(element.anio);
          }
          if(this.select === 'Ventas'){
            series.push(element.total_ventas_rubro);
          }
          else{
            series.push(element.total_empresas_rubro);
          }
        }
      }
      else if(labelType == 2){
        for(element of data){
          if(!labels.includes(element.region)){
            if(element.region.includes('Arica')){
              var temp = ['Arica y','Parinacota']
              labels.push(temp);
            }else{
              labels.push(element.region);
            }
            
            
           
           
            if(element.region === this.data_region.nombre){
                colors.push('#1A5276');
            }
            else{
              colors.push("#B5B5B5");
            }


            if(this.select === 'Ventas'){
              series.push(element.total_ventas);
            }
            else{
              series.push(element.total_empresas);
            }
          }
        }
      }

      else if(labelType == 3){
        for(element of data){
          if(!labels.includes(element.tipo)){
            labels.push(element.tipo);
            if(element.rubro == this.tipo){
              colors.push('#33b2df');
            }
            else{
              colors.push('#546E7A');
            }
          }
          if(this.select === 'Ventas'){
            if(element.ventas_rubro != null){
              series.push(element.ventas_rubro);
            }
            else{
              series.push(0);
            }
            
          }
          else{
            if(element.cantidad_rubro != null){
              series.push(element.cantidad_rubro);

            }
            else{
              series.push(0);
            }
          }
        }
      }
     
      return {
              'categorias': labels,
              'series': series,
              'colors': colors
             }
    },
  }
}
    
</script>