<template>
  <div id="columnChart2">

    <apexchart type="bar" ref="chart1"  :height="height" width="100%" :options="chartOptions" :series="series" class="chart-wrapper"></apexchart>

  </div>
</template>

<style scoped>
div.chart-wrapper {
  display: flex;
  align-items: center;
  justify-content: center
}
</style>

<script>
export default {
  name: 'columnChart',
  props: ['data','categorias','nombre_serie','tipo_dato','colors','tipo','height','titulo','subtitulo'],
  data() {
    return {
      series: [{
        name:'cambiar',
        data: []
      }],
      chartOptions: {
        chart:{
          type: 'bar',
          height: 450,
          toolbar: {
              show:true
          },
        },
        grid: {
          show:false
        }, 
        plotOptions: {
          bar: {
            barHeight: '70%',
            distributed: true,
            horizontal: false,
            dataLabels: {
              position: 'top',
            },
          }
        },
        colors: ["#000099","#FBC02D","#CC3399",'#003333', '#546E7A', '#FF0033', '#999966', '#669933', '#0000CC', '#6600CC',"#006633",'#f48024', '#CC0033',"#CCCC00","#66BB6A"],
        dataLabels: {
          enabled: true,
          style: {
            colors: ['#000'],
            fontSize: '11px',
            fontWeight: '500'
          },
          formatter: function (val, w) {
            var datos = w['w'].globals.series[0];
            var total = 0;
            datos.forEach(function(dato){
                total += dato;
            })

            var porcentaje = parseFloat(((val/total)*100).toFixed(2));

            if(porcentaje != 0.00){
                return porcentaje+'%';
            }else{
                return '0%';
            }
          },
          offsetY: -25,
          offsetX: 3,
          dropShadow: {
            enabled: false
          }
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        xaxis: {
          type: 'category',
          categories: [],
          labels: {
            style: {
              fontSize: '13px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
            }
          }
        },
        legend: {
            show:false
        },
        yaxis: {
          axisBorder:{
            show:false
          },
          axisTicks: {
            show: false,
            borderType: 'solid',
            color: '#78909C',
            width: 1,
            offsetX: 0,
            offsetY: 0
          },
          labels: {
            show: false,
            formatter: function (val){
                return val
            },
            style: {
              colors: [],
              fontSize: '8px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-yaxis-label',
            },
          },
          forceNiceScale: true
        },
        tooltip: {
          theme: 'dark',
          onDatasetHover: {
            highlightDataSeries: true,
          },
          custom: function({series,seriesIndex,dataPointIndex,w,opt}){
            function formatCL(val){
              var valor = val.toLocaleString();
              var largo_string = valor.length;
              var i;
              var stringFormat = ''; 
              for(i of valor){
                if( i === '.' ){
                  i = ',';
                  stringFormat+=i;
                }
                else if(i ===','){
                  i = '.';
                  stringFormat+=i;
                }
                else{
                  stringFormat+=i;
                }
              }
              return stringFormat;
            };

            function porcentaje(valor,total){
              return parseFloat(((valor / total)*100).toFixed(2));
            }

            var tipo_dato = w.config.tipo_dato;
            var serie = w.config.serie;


            var total = 0;
            var datos = series[0];
            datos.forEach(function(i){
              total += i
            })
           
            var porcentaje = porcentaje(datos[dataPointIndex],total);

          

            var region = w.globals.labels[dataPointIndex];
            var valor = formatCL(datos[dataPointIndex]);
            var tipo;
            
            if(region === 'Metropolitana'){
              tipo = 'Región ';
            }else if(region === 'Biobío' || region === 'Maule' ){
              tipo = 'Región del '
            }else{
              tipo = 'Región de '
            }
            return (
              '<div style="margin: 0 auto;width: 300px; height: 170px;opacity:1; background-color: #263238;">'+
              '<h2 class="font-weight-light text-left" style="margin-top:10px; margin-left:15px">'+tipo + region+'</h2>'+
              '<h4 class="font-weight-light text-left" style="margin-top:10px;margin-left:15px;font-size:16px">'+serie+'</h4>'+
              '<h3 class="font-weight-medium text-left" style="margin-top:2px;margin-left:15px">'+valor+' '+tipo_dato+'</h3>'+
              '<h4 class="font-weight-light text-left"  style="margin-top:5px;margin-left:15px;font-size:16px">% respecto a nivel país</h4>'+
              '<h3 class="font-weight-medium text-left" style="margin-top:2px;margin-left:15px">'+porcentaje+'%</h3>'+
              '</div>'
            );
          },
          followCursor: true
        },
        title:{
          text:'',
          align: 'left',
          style: {
            fontSize:  '22px',
            fontWeight:  'light',
            fontFamily:  undefined,
            color:  '#263238'
          },
        },
        subtitle:{
          text:'',
          align: 'left',
          style: {
            fontSize:  '18px',
            fontWeight:  'light',
            fontFamily:  undefined,
            color:  '#263238'
          },
        },
        tipo_dato: '',
        serie: ''
      }
    }
  },
  created(){
  
    // console.log(this.data,this.categorias)
    // this.cargar_categorias(this.categorias);

    // this.$refs.chart1.updateOptions({
    //     colors: this.colors,
    //     tipo_dato: this.tipo_dato,
    //     serie: this.nombre_serie,
    //     series:[
    //     {
    //         data: this.data,
    //         name: 'PIB '
    //     }]
    // })


  },
  computed: {
    item(){
      if(this.tipo == 1){
        this.chartOptions.dataLabels.formatter =function(val,w){
          var datos = w['w'].globals.series[0];
          var total = 0;
          datos.forEach(function(dato){
              total += dato;
          });

          var porcentaje = parseFloat(((val/total)*100).toFixed(2));

          if(porcentaje != 0.00){
              return porcentaje+'%';
          }else{
              return '0%';
          }
        };
      }
      else if(this.tipo == 3){
        this.chartOptions.plotOptions.bar.horizontal = true;
        this.chartOptions.dataLabels.enabled = false;
        this.chartOptions.dataLabels.offsetY = 0;
        this.chartOptions.dataLabels.offsetX = 50;
        this.chartOptions.chart.height = 650;
        this.chartOptions.grid.show = true;
        this.chartOptions.yaxis.labels.show = true;
        this.chartOptions.yaxis.labels.style.fontSize = '13px';
      }
      else {
        this.chartOptions.plotOptions.bar.horizontal = true;
        this.chartOptions.plotOptions.bar.barHeight= '80%',
        this.chartOptions.dataLabels.enabled = false;
        this.chartOptions.dataLabels.offsetY = 0;
        this.chartOptions.dataLabels.offsetX = 35;
        this.chartOptions.chart.height = 650;
        this.chartOptions.grid.show = true;
        this.chartOptions.yaxis.labels.style.fontSize = '14px';
        this.chartOptions.yaxis.labels.show = true;
        this.chartOptions.dataLabels.formatter = function(val,w){
          return val;
        }
      }

      return this.data;
    }
  },

  watch: {

    item(){
      
      
      
        this.$refs.chart1.updateOptions({
        colors: this.colors,
        tipo_dato: this.tipo_dato,
        serie: this.nombre_serie,
        series:[
        {
            data: this.data,
            name: this.nombre_serie
        }],
        xaxis:{
          categories: this.categorias
        },
        title:{
          text:this.titulo
        },
        subtitle: {
          text:this.subtitulo
        }
        })
      
      
      

      

    }
  },
  methods: {
    
    cargar_categorias(categorias){
      var region;
      var categoriesChart = this.chartOptions.xaxis.categories;
      for(region of categorias){
        if(!categoriesChart.includes(region)){
          categoriesChart.push(region);
        }
      }
    },

  }

}
</script>