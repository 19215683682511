<template>
	<div id="View10Region">
    <div id="header">
      <v-app-bar color="light-blue darken-2" class="rounded" flat dark>
        <h2 class="font-weight-medium">{{url_api.nombre_data}}</h2>
        
        <v-col style="margin-left: 30px"  cols="2">
            <v-select
            v-model="tipo_seleccion"
            :items ="tipos_seleccion"
            label ="tipo"
            item-text="id"
            flat
            solo-inverted
            dense
            hide-no-data
            class="mx-2"
            hide-details
            v-on:change="cambiarTipoSeleccion">
          </v-select>
        </v-col>
        <v-col style="margin-left: -15px" cols="2">
           <v-select
            v-model="tipo"
            :items ="url_api.tipos"
            label ="Tipo"
            item-text="id"
            flat
            solo-inverted
            dense
            hide-no-data
            class="mx-2"
            hide-details
            v-on:change="cambiarTipo">
          </v-select>
        </v-col>
        <v-col style="margin-left: -15px"  cols="2">
            <v-select
            v-model="anio"
            :items ="url_api.anios"
            label ="Año"
            item-text="id"
            flat
            solo-inverted
            dense
            hide-no-data
            class="mx-2"
            hide-details
            v-on:change="cambiarAnio">
          </v-select>
        </v-col>
        <v-spacer/>
        <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
           
            dark
            v-bind="attrs"
            v-on="on"
          >
            mdi-information-outline
          </v-icon>
        </template>
        <span>Fuente: {{url_api.fuente}}</span>
      </v-tooltip>
    </v-app-bar>
    </div>
    <div id="body" style="margin-top:-5px;">
      <v-row>
        <v-col cols="4">
         
         <v-card  flat>
          <v-card-title class="justify-center">
              <h3 class="font-weight-medium">País</h3>
          </v-card-title>
          <v-card-text class="text-center">
            <div v-if="tipo_seleccion === 'Predios'">
              <h2 class="font-weight-light mb-4">Predios {{tipo}}</h2>
              <h1 class="font-weight-medium " style="display:inline;">{{predios_pais}}</h1>
              <h3 class="mt-2 font-weight-medium ml-1" style="display:inline;">Predios</h3>
            </div>
            <div v-else>
              <h2 class="font-weight-light mb-4" >Avaluo {{tipo}}</h2>
              <h1 class="font-weight-medium " style="display:inline;">${{avaluo_pais}}</h1>
              <h3 class="mt-2 font-weight-medium" style="display:inline;">{{tipo_dato}}</h3>
            </div>
          </v-card-text>
        </v-card>
            
    
        <v-card flat class="mt-2">
          <v-card-title class="justify-center">
              <h3 class="font-weight-medium">{{nombre_region}}</h3>
          </v-card-title>
          <v-card-text class="text-center">
            <div v-if="tipo_seleccion === 'Predios'">
              <h2 class="font-weight-light mb-4">Predios {{tipo}}</h2>
              <h1 class="font-weight-medium " style="display:inline;">{{predios_region}}</h1>
              <h3 class="mt-2 font-weight-medium ml-1" style="display:inline;">Predios</h3>

            </div>
            <div v-else>
              <h2 class="font-weight-light mb-4" >Avaluo {{tipo}}</h2>
              <h1 class="font-weight-medium" style="display:inline;">${{avaluo_region}}</h1>
              <h3 class="mt-2 font-weight-medium" style="display:inline;">{{tipo_dato}}</h3>
            </div>
          </v-card-text>
        </v-card>
       
        </v-col>
        <v-col cols="8">
          <v-card flat height="300">
    
          <v-card-text>
            <div v-if="tipo_seleccion ==='Predios'">

              <column-chart-perc :data="data_graph1" :categories="labels_graph1" :nombre_serie="'Predios '+tipo"
              :colors="colors" :tipo_data="'Predios'" :height="270" :titulo="'Comparación Regional '+anio"/>
              <!-- <ColumnChart :data="data_graph1" :categorias="labels_graph1" :nombre_serie="'Predios '+tipo " 
                  :tipo="1" :colors="colors" :tipo_dato="'Predios'"/> -->
            </div>
            <div v-else>
              <column-chart-perc :data="data_graph1" :categories="labels_graph1" :nombre_serie="'Avaluo '+tipo"
              :colors="colors" :tipo_data="'Millones de pesos'" :height="270" :titulo="'Comparación Regional '+anio"/>
              
            </div>
          </v-card-text >
        </v-card>
        </v-col>
      </v-row>

      <v-row style="margin-top:-15px;">
        <v-col>
          <v-card flat height="490">
          <v-card-title class=" font-weight-medium">
            <v-row>
              <v-col cols="12">
                <h3 class="font-weight-light ml-3">Distribución por comunas</h3>     
              </v-col>
              <v-col cols="12" style="margin-top:-25px;">
                <h5 class="font-weight-light ml-3">{{tipo_seleccion}} {{tipo}}</h5>
              </v-col>
            </v-row>
          </v-card-title>
          <v-card-text style="margin-top:-30px;">
            <div v-if="tipo_seleccion ==='Predios'">
              <TreeMap :data="data_graph2" :labels="labels_graph2" :tipo_dato="'Predios'" :nombre_serie="'Predios '+tipo " :colors="colors_comuna"/>

            </div>
            <div v-else>
              <TreeMap :data="data_graph2" :labels="labels_graph2" :tipo_dato="'Millones de pesos'" :nombre_serie="'Avaluo '+tipo " :colors="colors_comuna"/>
            </div>
          </v-card-text>
        </v-card>
        </v-col>
      </v-row>
      
    </div>



    
	</div>
</template>

<script>
import axios from 'axios';
const API_URL = 'https://api.datari.net/datari/data/';
import ColumnChart from '../../components/charts/ColumnChart.vue';
import PieChart from '../../components/charts/PieChart.vue';
import TreeMap from '../../components/charts/TreeMap2.vue';
import ColumnChartPerc from '../../components/chartsV2/ColumnChartPerc.vue';


export default {
  components: {
    ColumnChart,
    TreeMap,
    ColumnChartPerc
  },
  name: 'View10Region',
  props: ['url_api','region'],
  data: () => ({
    tab:null,
    anio: null,
    tipo: null,
    tipo_dato: null,
    tipo_seleccion: null,
    tipos_seleccion: ['Predios','Avaluo'],
    data_anual_pais: [],
    data_anual_region :[],
    data_anual_regiones: [],
    nombre_region:null,
    predios_pais: null,
    avaluo_pais: null,
    predios_region: null,
    avaluo_region: null,
    participacion_predios: null,
    participacion_avaluo:null,
    data_graph1: [],
    labels_graph1:[],
    data_graph2: [],
    labels_graph2:[],
    colors: [],
    data_anual_comunas: [],
    colors_comuna:[],
    colors_items :["#000099","#FBC02D","#CC3399",'#003333', '#546E7A', '#FF0033', '#999966', '#669933', '#0000CC', '#6600CC',             "#006633",'#f48024', '#CC0033',"#CCCC00","#66BB6A"
    ],
    vistas: ['Datos Comunas','Comparación Regional'],
  }),
  created(){
    document.documentElement.style.overflow = 'hidden';
    this.anio = this.url_api.anios[0];
    this.tipo = this.url_api.tipos[0];
    this.tipo_seleccion = this.tipos_seleccion[0];
    this.crearData();

  },
  computed: {
    updateItem(){
      return this.region;
    }
  },
  watch: {
    updateItem(){
      this.crearData();
    }
  },
  methods: {
    cambiarAnio(anio){
      this.anio = anio;
      this.crearData();
    },

    cambiarTipo(tipo){
      this.tipo = tipo;
      this.predios_pais = this.formatCL(this.selectData(this.data_anual_pais).predios);
      this.avaluo_pais = this.formatCL(this.selectData(this.data_anual_pais).avaluo);
      this.predios_region = this.formatCL(this.selectData(this.data_anual_region).predios);
      this.avaluo_region = this.formatCL(this.selectData(this.data_anual_region).avaluo);
      this.participacion_predios = this.selectData(this.data_anual_region).porcentaje_predios;
      this.participacion_avaluo = this.selectData(this.data_anual_region).porcentaje_avaluo;
      this.data_graph1 = this.getGraphicData(this.data_anual_regiones,2,1).series;
      this.labels_graph1 = this.getGraphicData(this.data_anual_regiones,2,1).categorias;
      this.data_graph2 = this.getGraphicData(this.data_anual_comunas,2,2).series;
      this.labels_graph2 = this.getGraphicData(this.data_anual_comunas,2,2).categorias;
    },
    cambiarTipoSeleccion(select){
      this.tipo_seleccion = select;
      this.data_graph1 = this.getGraphicData(this.data_anual_regiones,2,1).series;
      this.labels_graph1 = this.getGraphicData(this.data_anual_regiones,2,1).categorias;
      this.data_graph2 = this.getGraphicData(this.data_anual_comunas,2,2).series;
      this.labels_graph2 = this.getGraphicData(this.data_anual_comunas,2,2).categorias;

    },

    selectData(data){
      var predios;
      var avaluo;
      var porcentaje_avaluo;
      var porcentaje_predios;
      //AFECTO
      if(this.tipo == this.url_api.tipos[0]){
        predios = data.predios_afectos;
        avaluo = data.total_avaluo_afecto;
        porcentaje_avaluo = data.porcentaje_total_avaluo_afecto;
        porcentaje_predios = data.porcentaje_predios_afectos;

      }
      else if(this.tipo == this.url_api.tipos[1]){
        predios = data.predios_exentos;
        avaluo = data.total_avaluo_exento;
        porcentaje_avaluo = data.porcentaje_total_avaluo_exento;
        porcentaje_predios = data.porcentaje_predios_exentos;

      }
      else if(this.tipo == this.url_api.tipos[2]){
        predios = data.predios_totales;
        avaluo = data.total_avaluo;
        porcentaje_avaluo = data.porcentaje_total_avaluo;
        porcentaje_predios = data.porcentaje_predios_totales;

      }

      return {
        'predios':predios,
        'avaluo':avaluo,
        'porcentaje_avaluo': porcentaje_avaluo,
        'porcentaje_predios': porcentaje_predios
      }
    },

    crearData(){
      axios.get(API_URL+this.url_api.url_api+'?region='+this.region+'&anio='+this.anio)
      .then(response => {
        this.data_anual_region = response.data.results[0];
        this.predios_region = this.formatCL(this.selectData(this.data_anual_region).predios);
        this.avaluo_region = this.formatCL(this.selectData(this.data_anual_region).avaluo);
        this.participacion_predios = this.selectData(this.data_anual_region).porcentaje_predios;
        this.participacion_avaluo = this.selectData(this.data_anual_region).porcentaje_avaluo;
        this.nombre_region = this.data_anual_region.region.nombre;
      });

      axios.get(API_URL+this.url_api.url_api+'?region=18&anio='+this.anio)
      .then(response => {
        this.data_anual_pais = response.data.results[0];
        this.predios_pais = this.formatCL(this.selectData(this.data_anual_pais).predios);
        this.avaluo_pais = this.formatCL(this.selectData(this.data_anual_pais).avaluo);
        this.tipo_dato = this.data_anual_pais.tipo_dato;
      });

      axios.get(API_URL+this.url_api.url_api+'?anio='+this.anio)
      .then(response => {
        this.data_anual_regiones = response.data.results;
        this.data_graph1 = this.getGraphicData(this.data_anual_regiones,2,1).series;
        this.labels_graph1 = this.getGraphicData(this.data_anual_regiones,2,1).categorias;
        this.colors = this.getGraphicData(this.data_anual_regiones,2,1).colors;

      });

      axios.get(API_URL+'est_bienes_raices_no_agricolas_comuna/?region='+this.region+'&anio='+this.anio)
      .then(response =>{
        this.data_anual_comunas = response.data.results;
        this.data_graph2 = this.getGraphicData(this.data_anual_comunas,2,2).series;
        this.labels_graph2 = this.getGraphicData(this.data_anual_comunas,2,2).categorias;
        var largo = this.data_graph2.length
        console.log(largo)
        this.colors_comuna = this.colors_items.slice(0,largo);
      });
    },

    formatCL(val){
      var valor = val.toLocaleString();
      var largo_string = valor.length
      var i;
      var stringFormat = ''; 
      for(i of valor){
        if( i === '.' ){
          i = ',';
          stringFormat+=i;
        }
        else if(i ===','){
          i = '.';
          stringFormat+=i;
        }
        else{
          stringFormat+=i;
        }
      }
      return stringFormat;
    },


    getGraphicData(data,labelType,tipoData){
      // Tipo 1 -> Años, Tipo 2 -> Regiones
      var element;
      var labels = [];
      var series = [];
      var colors= [];
      if(tipoData == 1){
        if(labelType == 1){
        for(element of data){
          if(!labels.includes(element.anio)){
            labels.push(element.anio);
          }
            series.push(element.superficie);
          }
        }
        else if(labelType == 2){

          for(element of data){
            if(element.region.nombre != 'Nacional'){
              if(!labels.includes(element.region.nombre)){
                if(element.region.nombre.includes('Arica')){
                  var temp = ['Arica y','Parinacota']
                  labels.push(temp);
                }else{
                  labels.push(element.region.nombre);
                }
                if(element.region.nombre === this.nombre_region){
                  colors.push('#1A5276');
                }
                else{
                  colors.push("#B5B5B5");
                }
              }
              if(this.tipo == this.url_api.tipos[0]){
                if(this.tipo_seleccion === 'Predios'){
                  series.push(element.predios_afectos);
                }
                else if(this.tipo_seleccion === 'Avaluo'){
                  series.push(element.total_avaluo_afecto);
                }
              }
              else if(this.tipo == this.url_api.tipos[1]){
                if(this.tipo_seleccion  === 'Predios'){
                  series.push(element.predios_exentos);
                }
                else if(this.tipo_seleccion  === 'Avaluo'){
                  series.push(element.total_avaluo_exento);
                }
               

              }
              else if(this.tipo == this.url_api.tipos[2]){
                if(this.tipo_seleccion === 'Predios'){
                  series.push(element.predios_totales);
                }
                else if(this.tipo_seleccion === 'Avaluo'){
                  series.push(element.total_avaluo);
                }

              }
            }
          }
        }
      }
      else if(tipoData == 2){
        if(labelType == 1){
          for(element of data){
            if(!labels.includes(element.anio)){
              labels.push(element.anio);
            }
              series.push(element.superficie);
          }
        }
        else if(labelType == 2){
          for(element of data){
            if(element.region.nombre != 'Nacional'){
              if(!labels.includes(element.comuna.nombre)){
                labels.push(element.comuna.nombre);
              }
              if(this.tipo == this.url_api.tipos[0]){
                if(this.tipo_seleccion === 'Predios'){
                  series.push(element.predios_afectos);
                }
                else if(this.tipo_seleccion === 'Avaluo'){
                  series.push(element.total_avaluo_afecto);
                }
              }
              else if(this.tipo == this.url_api.tipos[1]){
                if(this.tipo_seleccion  === 'Predios'){
                  series.push(element.predios_exentos);
                }
                else if(this.tipo_seleccion  === 'Avaluo'){
                  series.push(element.total_avaluo_exento);
                }
               

              }
              else if(this.tipo == this.url_api.tipos[2]){
                if(this.tipo_seleccion === 'Predios'){
                  series.push(element.predios_totales);
                }
                else if(this.tipo_seleccion === 'Avaluo'){
                  series.push(element.total_avaluo);
                }

              }
            }
          }
        }
      }
      
     
      return {
              'categorias': labels,
              'series': series,
              'colors': colors
             }
    },
  }
}
	
</script>