<template>
	<div id="treeMap">
		<div id="chart">
      <apexchart type="treemap" ref="chart" width="100%" :height="height" :options="chartOptions" :series="series" class="chart-wrapper"></apexchart>
      </div>
    </div>
</template>

<!-- Centrar Chart --->
<style scoped>
div.chart-wrapper {
  display: flex;
  align-items: center;
  justify-content: center
}
</style>

<script>
export default {
  components: {
  },
  name: 'treeMap',
  props: ['data','serie','unidad','titulo','subtitulo','height','colors'],
  data: () => ({
    series: [
      {
        data: [
          {x:'',y:''}
        ]
      }
    ],
    chartOptions: {
      legend: {
        show:false
      },
      chart: {
        height: 350,
        type: 'treemap',
        toolbar: {
          show:false
        }
      },
      title: {
        text: '',
        align: 'center'
      },
      plotOptions: {
        treemap: {
          distributed: false,
          enableShades: false
        }
      },
      unidad:'',
      nombre_serie:''
    }
  }),
  mounted(){
    
  },
  computed: {
    updateData(){
      return this.data;
    }
  },
  watch: {
    
    updateData(){
      console.log(this.unidad);
      this.$refs.chart.updateOptions({
        series: [{
          data:this.data
        }],
        title: {
          text:this.titulo,
          align:'left',
          style:{
            colors: ["#464646"],
            fontSize: '18px',
            fontFamily: 'Helvetica, sans-serif',
            fontWeight: 500,
          },
        },
        plotOptions: {
          treemap: {
            distributed:false,
            enableShades: true,
            shadeIntensity: 0.68,
          }
        },
        dataLabels:{
          enabled: true,
          style:{
            colors: ["#464646"],
            fontSize: '18px',
            fontFamily: 'Helvetica, sans-serif',
            fontWeight: 500,
          },
          offsetY:-4,
          formatter: function(text, op) {
            function formatCL(val){
              var valor = val.toLocaleString();
              var largo_string = valor.length
              var i;
              var stringFormat = ''; 
              for(i of valor){
                if( i === '.' ){
                i = ',';
                stringFormat+=i;
                }
                else if(i ===','){
                i = '.';
                stringFormat+=i;
                }
                else{
                stringFormat+=i;
                }
              }
              return stringFormat;
            };

            let dato_text = text

            if(text.length >= 19){
              if(text === 'San Vicente de Tagua Tagua'){
                text = "San Vicente";
              }
              else if(text === 'Pedro Aguirre Cerda'){
                text = "PAC";
              }
              else if(text === 'San Pedro de La Paz'){
                text = 'San Pedro';
              }
              else if(text === 'San Juan de la Costa'){
                text = "San Juan"
              }
              else{
                text = text.split(' ');

              }
            }         

            let cantidad_datos = op.w.config.series[0].data.length;

            if (cantidad_datos >= 30){
              if(parseFloat(op.value) >= 50000000.0){
              return [text, '$'+formatCL(op.value)]
              }
              else if(parseFloat(op.value) >= 25000000.0 && parseFloat(op.value) < 50000000.0){
                return text
              }
              else{
                return ''
              }
            }
            else if(cantidad_datos < 30 && cantidad_datos > 10 ){
              if(parseFloat(op.value) >= 50000000.0){
                return [text, '$'+formatCL(op.value)]
              }
              else if(parseFloat(op.value) >= 10000000.0 && parseFloat(op.value) < 50000000.0){
                return text
              }
              else{
                return ''
              }
            }
            else{
              if(parseFloat(op.value) >= 9000000.0){
                return [text, '$'+formatCL(op.value)]
              }
              else if(parseFloat(op.value) >= 2000000.0 && parseFloat(op.value) < 9000000.0){
                return text
              }
              else{
                return ''
              }
            }

           
            
          },
        },
        unidad: this.unidad,
        nombre_serie: this.serie,
        tooltip: {
          theme: 'dark',
          enableShades:false,
          custom: function({series,seriesIndex,dataPointIndex,w,opt}){
            function formatCL(val){
              var valor = val.toLocaleString();
              var largo_string = valor.length
              var i;
              var stringFormat = ''; 
              for(i of valor){
                if( i === '.' ){
                  i = ',';
                  stringFormat+=i;
                }
                else if(i ===','){
                  i = '.';
                  stringFormat+=i;
                }
                else{
                  stringFormat+=i;
                }
              }
              return stringFormat;
            };


            var valor = series[seriesIndex][dataPointIndex];
            var text = w.globals.categoryLabels[dataPointIndex];
            var unidad = w.config.unidad;
            var nombre_serie = w.config.nombre_serie;
            
            return (
              '<div style="margin: 0 auto;width: 350px; height: 130px;opacity:1; background-color: #263238;">'+
              '<h2 class="font-weight-light text-left" style="margin-top:10px; margin-left:15px;">'+text+'</h2>'+
                '<div class="row">'+
                  '<div class="col-sm-12 ">'+
                    '<h3 class="font-weight-light mt-2 text-left" style="margin-left:15px;font-size:16px">'+nombre_serie+'</h3>'+
                    '<h3 class="font-weight-medium text-left" style="margin-top:2px;margin-left:15px">$'+formatCL(valor)+'</h3>'+
                    '<h4 class="font-weight-medium text-left" style="margin-top:-2px;margin-left:15px">'+unidad+'</h4>'+
                  '</div>'+
                  // '<div class="col-sm-12" style="margin-top:-15px">'+
                  //   '<h4 class="font-weight-light text-left"  style="margin-left:15px;font-size:16px">Participacion regional</h4>'+
                  //   '<h3 class="font-weight-medium text-left" style="margin-top:2px;margin-left:15px">'+porcentaje+'%</h3>'+
                  // '</div>'+
                '</div>'+
              '</div>'
            );
          },
        }
      });
    }
  }
}

</script>
