<template>
  <div id="View2">
    <div id="header">
      <v-app-bar color="light-blue darken-2" class="rounded" flat dark>
        <h2 class="font-weight-medium">{{url_api.nombre_data}}</h2>
        <v-col cols=2>
          <v-select
            v-model="anio"
            :items ="url_api.anios"
            label ="Año"
            hide-no-data
            hide-details
            class="mx-2"
            flat
            solo-inverted
            dense
            v-on:change="cambiarAnio"
            >
          </v-select>
        </v-col>
        <v-spacer/>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
             
              dark
              v-bind="attrs"
              v-on="on"
            >
              mdi-information-outline
            </v-icon>
          </template>
          <span>Fuente: {{url_api.fuente}}</span>
        </v-tooltip>
      </v-app-bar>
    </div>
    <div id="body" style="margin-top:-5px;">
      <v-row>
        <v-col cols=5>
          <v-card flat>
            <v-card-title class="justify-center">
               <h3 class="font-weight-medium">{{nombre_comuna}}</h3>
            </v-card-title>
            <v-card-text class="text-center" style="margin-top: -10px;">
              
              <h2 class="font-weight-light mb-2">Presupuesto {{anio}}</h2>
              <h2 clas="font-weight-light mt-4">Ingresos</h2>
              <h1 class="font-weight-medium mt-4 mb-6">${{presupuesto_anual}} Millones</h1>
              <h2 clas="font-weight-light" style="margin-top:10px;">Gastos</h2>
              <h1 class="font-weight-medium mt-4">${{gasto_anual}} Millones</h1>

              <h2 class="font-weight-light mt-6">Ejecución Presupuestaria </h2>
              <v-col cols="6" class="mx-auto mt-1">
              <v-progress-linear v-model="porcentaje" height="25" color="blue-grey" class="white--text">
                <strong>{{porcentaje}}%</strong>
              </v-progress-linear>
            </v-col>
            </v-card-text>
          </v-card>
          <v-card flat class="mt-2">
            <v-card-title class="justify-center">
            </v-card-title>
            <v-card-text>
              <MultiColumnChart :data="data_graph1[0]" :data2="data_graph1[1]"
              :labels="labels_graph1" :colors="colors_graph1" :tipo_data="'Millones'"
              :unidad="'Millones'" :nombre_serie1="'Ingresos'" :nombre_serie2="'Gastos'" :titulo="'Evolución Presupuestaria 2015-2019'" :subtitulo="'Ejecución presupuestaria - '+nombre_comuna" />
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols=7>
          <v-card flat height="100%">
            <v-card-title class="justify-center">
              <h3 class="font-weight-medium">Comparación regional Presupuesto {{anio}} </h3>
            </v-card-title>
           <!--  <v-card-subtitle class="text-center">
              <h3>{{tipos[tipo].toUpperCase()}} {{anio}}</h3>
            </v-card-subtitle> -->
            <v-card-text >
              <Mapa :coordenadas_region="coordenadas_region" :codregion="codigo_region" :codcomuna="codigo_comuna" :data="data_mapa" :tipo="tipos[tipo].toLowerCase()" 
                :data_comuna_selected="data_comuna_presup"
              />
              <div style="width:100%;text-align: center;" >
              <v-slide-group
                  v-model="tipo"
                  show-arrows
                  mandatory
                  center-active
                  v-on:change="cambiarTipo"
                  dense
                  style="display: inline-block;"
                  
                  >
                  <v-slide-item
                  v-for="(tipo) in tipos"
                  :key="tipo"
                  v-slot="{ active, toggle }"
                  >
                      <v-btn
                      class="mx-1"
                      :input-value="active"
                      :color="active ? 'blue-grey lighten-2 white--text' : 'grey lighten-2'"
                      depressed
                      
                      @click="toggle"
                      >
                      {{tipo}}
                    </v-btn>
                  </v-slide-item>
                </v-slide-group>
              </div>
              
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
const API_URL = 'https://api.datari.net/datari/data/';
import MultiColumnChart from '../../components/chartsV2/MultiColumnChart2.vue';
import Mapa from '../../components/maps/MapPM.vue';

export default {

  name: 'View2Comuna',
  props: [
    'url_api','comuna','region'
  ],
  components: {
    MultiColumnChart,
    Mapa
  },

  data () {
    return {
      nombre_comuna: null,
      codigo_comuna: 0,
      anio: 2015,
      presupuesto_anual: null,
      gasto_anual:null,
      data_graph1: [],
      labels_graph1: [],
      colors_graph1:[],
      data_mapa: [],
      coordenadas_region: [],
      data_comuna_presup:[],
      codigo_region: 0,
      tipos: ['Ingresos','Gastos'],
      tipo: 0
    }
  },
  mounted(){
    document.documentElement.style.overflow = 'hidden';
    this.get_data_comuna();
    this.get_data_mapa();
    // this.get_data_presupuesto_comuna_anio();
  },

  computed: {
    cambiarComuna(){
      return this.comuna;
    }
  },
  watch: {
     cambiarComuna(){
      this.get_data_comuna();
      this.get_data_mapa();
     }
  },
  methods: {
    async get_data_comuna(){
      let response = await axios.get(
        API_URL+'comunas/?id='+this.comuna
      );

      let data = response.data.results[0];
      this.nombre_comuna = data.nombre;
      this.codigo_comuna = data.codigo;
      this.coordenadas_region = [data.region.lat,data.region.lon];
      this.codigo_region = data.region.numero_region;


      response = await axios.get(
        API_URL+this.url_api.url_api+'?codigo_comuna='+this.codigo_comuna+
        '&anio_data='+this.anio
      );

      data = response.data.results[0];
      this.data_comuna_presup = data;
      this.porcentaje = data.porcentaje_gasto;
      this.presupuesto_anual = this.formatCL(data.ingresos);
      this.gasto_anual = this.formatCL(data.gastos);

      response = await axios.get(
        API_URL+this.url_api.url_api+'?codigo_comuna='+this.codigo_comuna
      ); 

      data = response.data.results;

      data.sort((a, b) => (a.anio_data > b.anio_data) ? 1 : -1)
      console.log(data)

      let data_grafico = this.get_graphic_data(data,1);
      
      this.data_graph1 = [data_grafico.series,data_grafico.series2];
      this.labels_graph1 = data_grafico.categorias;
      this.colors_graph1 = data_grafico.colors;

     
    },

    async get_data_mapa(){
      let response = await axios.get(
        'https://api.datari.net/datari/mapas/presupuesto_municipal/?anio_data='+this.anio+
        '&region='+this.region
      );

      let data = response.data.results;

      this.data_mapa = data;
    },


    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    formatCL(val){
      var valor = val.toLocaleString();
      var largo_string = valor.length
      var i;
      var stringFormat = ''; 
      for(i of valor){
        if( i === '.' ){
          i = ',';
          stringFormat+=i;
        }
        else if(i ===','){
          i = '.';
          stringFormat+=i;
        }
        else{
          stringFormat+=i;
        }
      }
      return stringFormat;
    },

    get_graphic_data(data,type){
      var element;
      var labels = [];
      var series = [];
      var series2= [];
      var colors = [];
      if(type === 1){
        for(element of data){
          let anio = element.anio_data;
          let ingreso = element.ingresos;
          let gasto = element.gastos;

          labels.push(anio);
          series.push(ingreso);
          series2.push(gasto)
          colors = ['#FBC02D','#304FFE'];
        }

      }

      return {
        'categorias': labels,
        'series': series,
        'colors': colors,
        'series2':series2
       }
    },

    cambiarAnio(anio){
      this.anio = anio;
      this.get_data_comuna();
      this.get_data_mapa();
    },
    cambiarTipo(tipo){
      this.tipo = tipo;
      this.get_data_mapa();
    }

    
  }
}
</script>

<style lang="css" scoped>
</style>