<template>
    <div id="LineChart2">
        <apexchart width="100%"  ref="chart1" type="line"  :height="height" :options="options" :series="series" class="chart-wrapper"></apexchart>
    </div>
</template>

<!-- Centrar Chart --->
<style scoped>
div.chart-wrapper {
    display: flex;
    align-items: center;
    justify-content: center
}
</style>

<script>
  export default {
      name: 'LineChart',
      props: ['data','labels','serie','titulo','subtitulo','height'],
      data() {
          return {
              
              options: {
                  chart: {
                      id: 'lineChart',
                      toolbar:{
                        show: true,
                        tools: {
                          download: true,
                          selection: false,
                          zoom: false,
                          zoomin: false,
                          zoomout: false,
                          pan: false,
                          reset: false | '<img src="/static/icons/reset.png" width="20">',
                          customIcons: []
                        }, 
                      }
                  },
                  xaxis: {
                      type: "category",
                      categories: [0],
                      style: {
                          colors: '#fff',
                          fontSize: '11px'
                      },
                      labels: {
                          formatter: function(val){
                              return val
                          }
                      }
                  },
                  
                  yaxis:{
                    axisBorder:{
                      show:false
                    },
                    axisTicks:{
                      show:false
                    },
                    labels: {
                          show: false,
                          offsetX: 0,
                          formatter: function (valor) {
                            function formatCL(val){
                              var valor = val.toLocaleString();
                              var largo_string = valor.length
                              var i;
                              var stringFormat = ''; 
                              for(i of valor){
                                if( i === '.' ){
                                  i = ',';
                                  stringFormat+=i;
                                }
                                else if(i ===','){
                                  i = '.';
                                  stringFormat+=i;
                                }
                                else{
                                  stringFormat+=i;
                                }
                              }
                              return stringFormat;
                            };
                            return formatCL(valor)
                          }
                      },
                      style: {
                        colors: '#fff',
                        fontSize: '5px'
                      }
                  },
                  dataLabels: {
                      enabled: false
                  },
                  stroke: {
                      curve: 'straight'
                  },
                  colors:['#16A085'],
                  tooltip: {
                      theme: 'dark',
                      enabled: true,
                      y:{
                          title:{
                              formatter: function(val){

                                  return val
                              }
                          },
                          formatter: function(val){
                            return val
                          }  
                      }
                  },
              },
          
              series: [{
                  name: 'serie-1',
                  data: []
              }],
          
          }
      },
      mounted() {
         this.$refs.chart1.updateOptions({
              series: [
                {
                data: this.data,
                name: this.serie
                }
              ],
              xaxis:{
                categories: this.labels
              },
              title:{
                text: this.titulo,
                align: 'left',
                style: {
                  fontSize:  '24px',
                  fontWeight:  'light',
                  fontFamily:  undefined,
                  color:  '#263238'
                  },  
              },
              subtitle:{
                text: this.subtitulo,
                align: 'left',
                style: {
                  fontSize:  '18px',
                  fontWeight:  'light',
                  fontFamily:  undefined,
                  color:  '#263238'
                  },  
              }
            });

      },

      computed: {
          item(){
              if(this.serie.includes('Superficie')){
                this.options.tooltip.y.formatter = function(value,a){
                  function formatCL(val){
                    var valor = val.toLocaleString();
                    var largo_string = valor.length
                    var i;
                    var stringFormat = ''; 
                    for(i of valor){
                      if( i === '.' ){
                        i = ',';
                        stringFormat+=i;
                      }
                      else if(i ===','){
                        i = '.';
                        stringFormat+=i;
                      }
                      else{
                        stringFormat+=i;
                      }
                    }
                    return stringFormat;
                  };

                  return formatCL(value)+' Hectareas'
                }
              }
              else if(this.serie.includes('Exportación')){
                this.options.tooltip.y.formatter = function(value,a){
                  function formatCL(val){
                    var valor = val.toLocaleString();
                    var largo_string = valor.length
                    var i;
                    var stringFormat = ''; 
                    for(i of valor){
                      if( i === '.' ){
                        i = ',';
                        stringFormat+=i;
                      }
                      else if(i ===','){
                        i = '.';
                        stringFormat+=i;
                      }
                      else{
                        stringFormat+=i;
                      }
                    }
                    return stringFormat;
                  };

                  return '$'+formatCL(value)+' Dolares FOB'
                }
              }
              else{
              this.options.tooltip.y.formatter = function(value,a){
                function formatCL(val){
                  var valor = val.toLocaleString();
                  var largo_string = valor.length
                  var i;
                  var stringFormat = ''; 
                  for(i of valor){
                    if( i === '.' ){
                      i = ',';
                      stringFormat+=i;
                    }
                    else if(i ===','){
                      i = '.';
                      stringFormat+=i;
                    }
                    else{
                      stringFormat+=i;
                    }
                  }
                  return stringFormat;
                };

                return formatCL(value)+' Empresas'
              }
            }
            
            
            return this.data
          }
      },

      watch: {
          item(){ 
          
            this.$refs.chart1.updateOptions({
              series: [
                {
                data: this.data,
                name: this.serie
                }
              ],
              xaxis:{
                categories: this.labels
              },
              title:{
                text: this.titulo,
                align: 'left',
                style: {
                  fontSize:  '24px',
                  fontWeight:  'light',
                  fontFamily:  undefined,
                  color:  '#263238'
                  },  
              },
              subtitle:{
                text: this.subtitulo,
                align: 'left',
                style: {
                  fontSize:  '18px',
                  fontWeight:  'light',
                  fontFamily:  undefined,
                  color:  '#263238'
                  },  
              }
            });
          }
      },
      methods: {


          cargar_categorias(categorias){
              var region;
              var categoriesChart = this.options.xaxis.categories;

              if(categoriesChart[0] == 0){
                  categoriesChart.splice(0,1);
              }
              
              for(region of categorias){
                  if(!categoriesChart.includes(region)){
                      categoriesChart.push(region);
                  }

              }
              
              
          },

          formatCL(val){
            var valor = val.toLocaleString();
            var largo_string = valor.length
            var i;
            var stringFormat = ''; 
            for(i of valor){
              if( i === '.' ){
                i = ',';
                stringFormat+=i;
              }
              else if(i ===','){
                i = '.';
                stringFormat+=i;
              }
              else{
                stringFormat+=i;
              }
            }
            return stringFormat;
          },
          
      }

      





      
  };
</script>
