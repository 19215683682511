<template>
  <div id="View9Region">
    <div id="header">
      <v-app-bar color="light-blue darken-2" class="rounded" flat dark>
        <h2 class="font-weight-medium">{{url_api.nombre_data}}</h2>
        <v-col cols="2" class="ml-2">
          <v-select
          v-model="anio"
          :items ="url_api.anios"
          label ="Año"
          item-text="id"
          flat
          solo-inverted
          dense
          hide-no-data
          class="mx-2"
          hide-details
          v-on:change="cambiarAnio">
          </v-select>
        </v-col>
        <v-col style="margin-left: -25px"  cols="2">
          <v-select
          v-model="tipo"
          :items ="url_api.tipos"
          label ="Año"
          item-text="id"
          flat
          solo-inverted
          dense
          hide-no-data
          class="mx-2"
          hide-details
          v-on:change="cambiarTipo">
          </v-select>
        </v-col>
        <v-spacer/>
        <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
           
            dark
            v-bind="attrs"
            v-on="on"
          >
            mdi-information-outline
          </v-icon>
        </template>
        <span>Fuente: {{url_api.fuente}}</span>
      </v-tooltip>
      </v-app-bar>
    </div>
    <div id="body" style="margin-top:-5px;">
      <v-row>
        <v-col cols="4">
          <v-card flat>
            <v-card-title class="justify-center">
              <h2 class="font-weight-medium"><small>País</small></h2>
            </v-card-title>
            <v-card-text class="text-center" style="margin-top:-10px;">
              <h2 class="font-weight-light mb-3">Exportación {{tipo}}</h2>
              <h2 style="display:inline" class="display-1 ">${{exportacion_pais}}</h2>
              <h3 style="display:inline" class=" ml-2">{{tipo_dato}}</h3>
            </v-card-text>
          </v-card>
          <v-card flat class="mt-2">
            <v-card-title class="justify-center">
              <h2 class="font-weight-medium"><small>{{nombre_region}}</small></h2>
            </v-card-title>
            <v-card-text class="text-center"  style="margin-top:-10px;">
              <h2 class="font-weight-light mb-3">Exportación {{tipo}}</h2>
              <h2 style="display:inline" class="display-1 ">${{exportacion_region}}</h2>
              <h3 style="display:inline" class=" ml-2">{{tipo_dato}}</h3>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="8">
          <v-card flat height="300">
            <v-card-title class="justify-center">
            </v-card-title>
            <v-card-text class="text-center">
              <column-chart-perc :data="data_graph2" :categories="labels_graph2" :colors="colors"
              :titulo="'Exportación '+tipo+' regional - '+anio" :height="250" :nombre_serie="'Exportación '+anio" :tipo_data="'Miles de dólares FOB'" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row style="margin-top:-15px;">
        <v-col>
          <v-card flat>
            <v-card-text class="text-center">
              <PieChart :data="data_graph3" :labels="labels_graph3" :colors="colors_graph3" :serie="'Exportación '" :tipo_dato="'miles de dólares FOB'" :titulo="'Distribución según tipo de producto'"/>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card flat>
            <v-card-text class="text-center">
              <lineChart :data="data_graph1" :labels="labels_graph1" :serie="'Exportación :'" :height="440"
              :titulo="titulo" 
              :subtitulo="url_api.anios[url_api.anios.length-1]+'-'+url_api.anios[0]"
              />    
            </v-card-text>
          </v-card>
        </v-col>  
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
const API_URL = 'https://api.datari.net/datari/data/';
import lineChart from '../../components/chartsV2/LineChart2.vue';
import PieChart from '../../components/chartsV2/PieChart.vue';
import ColumnChartPerc from '../../components/chartsV2/ColumnChartPerc.vue';


export default {
  components: {
    lineChart,
    PieChart,
    ColumnChartPerc,
  },
  name: 'View9Region',
 props: ['url_api','region'],
  data: () => ({
    tab: null,
    anio: null,
    tipo: null,
    tipo_dato: null,
    nombre_region: null,
    data_anual_region: [],
    data_anual_pais: [],
    data_anual_regiones: [],
    data_region: [],
    exportacion_region: null,
    participacion_region: null,
    exportacion_pais: null,
    participacion_pais: null,
    vistas: ['Exportación Anual','Comparación Regional'],
    data_graph1: [],
    labels_graph1: [],
    data_graph2: [],
    labels_graph2: [],
    data_graph3: [],
    labels_graph3: [],
    colors_graph3: [],
    colors: [],
    data_rubro: [],
    titulo: null,
    loading: true
  }),
  created(){
    document.documentElement.style.overflow = 'hidden';
    this.anio = this.url_api.anios[0];
    this.tipo = this.url_api.tipos[0];
    this.crearData();
    this.sleep(1500).then(() => {this.loading=false;});
    

  },
  computed: {
    updateItem(){
      return this.region;
    }
  },
  watch: {
    updateItem(){
      axios.get(API_URL+'regiones/'+this.region)
      .then( response => {
        this.nombre_region = response.data.nombre;
      })
      this.sleep(1000).then(() => {
        this.crearData();  
      });
      
    }
  },
  methods: {
    cambiarAnio(anio){
      this.anio = anio;
      this.crearData();
    },

  

    cambiarTipo(tipo){
      this.tipo = tipo;
      
      this.exportacion_region = this.selectData(this.data_anual_region)['exportacion'];
      this.participacion_region = this.selectData(this.data_anual_region)['participacion'];
      this.exportacion_pais = this.selectData(this.data_anual_pais)['exportacion'];
      this.data_graph1 = (this.getGraphicData(this.order_by_year(this.data_region),1)).series;
      this.labels_graph1 = (this.getGraphicData(this.order_by_year(this.data_region),1)).categorias;
      this.data_graph2 = this.getGraphicData(this.data_anual_regiones,2).series;
      this.labels_graph2 = this.getGraphicData(this.data_anual_regiones,2).categorias;
      this.colors = this.getGraphicData(this.data_anual_regiones,2).colors;
      this.titulo = 'Exportación '+this.tipo+' '+this.nombre_region;

  },


    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },
    selectData(data){
      var exportacion;
      var participacion;

      if(this.tipo === this.url_api.tipos[0]){
        exportacion = data.exportaciones_agricola;
        participacion = data.porcentajes.participacion_agricola;
      }
      else if(this.tipo === this.url_api.tipos[1]){
        exportacion = data.exportaciones_forestal;
        participacion = data.porcentajes.participacion_forestal;

      }
      else if(this.tipo === this.url_api.tipos[2]){
        exportacion = data.exportaciones_pecuario;
        participacion = data.porcentajes.participacion_pecuario;

      }
      else if(this.tipo === this.url_api.tipos[3]){
        exportacion = data.exportaciones_total;
        participacion = data.porcentajes.participacion_region;

      } 

      return {
        'exportacion': this.formatCL(exportacion),
        'participacion': participacion
        }  



    },


    crearData(){
      //Data Region
      axios.get(API_URL+this.url_api.url_api+'?region='+this.region+'&anio='+this.anio)
      .then(response =>{
        this.data_anual_region = response.data.results[0];
        this.data_rubro = this.data_anual_region.data_rubros;
        let data_graficos = this.getGraphicData(this.data_rubro,3);
        console.log(data_graficos)
        this.data_graph3 = data_graficos.series;
        this.labels_graph3 = data_graficos.categorias;
        this.colors_graph3 = data_graficos.colors;
        this.nombre_region = this.data_anual_region.region.nombre;
        this.tipo_dato = this.data_anual_region.tipo_dato;
        this.titulo = 'Exportación '+this.tipo+' '+this.nombre_region;
        this.exportacion_region = this.selectData(this.data_anual_region)['exportacion'];
        this.participacion_region = this.selectData(this.data_anual_region)['participacion'];
      });

      axios.get(API_URL+this.url_api.url_api+'?region=18&anio='+this.anio)
      .then(response => {
        this.data_anual_pais = response.data.results[0];
        this.exportacion_pais = this.selectData(this.data_anual_pais)['exportacion'];
      });

      axios.get(API_URL+this.url_api.url_api+'?region='+this.region)
      .then(response => {
        this.data_region = response.data.results;
        this.data_graph1 = (this.getGraphicData(this.order_by_year(this.data_region),1)).series;
        this.labels_graph1 = (this.getGraphicData(this.order_by_year(this.data_region),1)).categorias;
      });

      axios.get(API_URL+this.url_api.url_api+'?anio='+this.anio)
      .then(response => {
        this.data_anual_regiones = response.data.results;
        let data_graph = this.getGraphicData(this.data_anual_regiones,2);
        
        this.data_graph2 = data_graph.series;
        this.labels_graph2 = data_graph.categorias;
        this.colors = data_graph.colors;
      });


    },


    formatCL(val){
      var valor = val.toLocaleString();
      var largo_string = valor.length
      var i;
      var stringFormat = ''; 
      for(i of valor){
        if( i === '.' ){
          i = ',';
          stringFormat+=i;
        }
        else if(i ===','){
          i = '.';
          stringFormat+=i;
        }
        else{
          stringFormat+=i;
        }
      }
      return stringFormat;
    },
    order_by_year(data){
      var data1 = data.sort(function(a,b){
        a = a.anio;
        b = b.anio;
        if (a > b) {
            return 1;
        }
        if (b > a) {
            return -1;
        }
        return 0;
      })
      return data1;
    },
    getGraphicData(data,labelType){
      // Tipo 1 -> Años, Tipo 2 -> Regiones
      var element;
      var labels = [];
      var series = [];
      var colors = [];

      if(labelType == 1){
        for(element of data){
          if(!labels.includes(element.anio)){
            labels.push(element.anio);
          }
          if(this.tipo === this.url_api.tipos[0]){
            series.push(element.exportaciones_agricola);
          }
          else if(this.tipo === this.url_api.tipos[1]){
            series.push(element.exportaciones_forestal);
          }
          else if(this.tipo === this.url_api.tipos[2]){
            series.push(element.exportaciones_pecuario);
          }
          else if(this.tipo === this.url_api.tipos[3]){
            series.push(element.exportaciones_total);
          }
          
        }
      }
      else if(labelType == 2){

        for(element of data){

          if(element.region.nombre != 'Nacional'){
            if(!labels.includes(element.region.nombre)){
              if(element.region.nombre.includes('Arica')){
                var temp = ['Arica y','Parinacota']
                labels.push(temp);
              }else{
                labels.push(element.region.nombre);
              }
              
            }
            if(this.nombre_region === element.region.nombre){
              colors.push('#1A5276');
            }
            else{
              colors.push("#B5B5B5");
            }

            if(this.tipo === this.url_api.tipos[0]){
              series.push(element.exportaciones_agricola);
            }
            else if(this.tipo === this.url_api.tipos[1]){
              series.push(element.exportaciones_forestal);
            }
            else if(this.tipo === this.url_api.tipos[2]){
              series.push(element.exportaciones_pecuario);
            }
            else if(this.tipo === this.url_api.tipos[3]){
              series.push(element.exportaciones_total);
            }
          }
        }
      }

      else if(labelType == 3){
        for(element of data){
         if(!labels.includes(element.rubro)){
          labels.push(element.rubro);
         }
         series.push(element.valor);
         colors.push(element.color);
        }
      }
     
      return {
              'categorias': labels,
              'series': series,
              'colors': colors
             }
    },
  }
}
    
</script>