<template>
    <div class="text-center">
        <v-overlay
        :absolute="true"
        :value="overlay"
        opacity="1"
        color="#eceff1"
      >
        <v-progress-circular
          indeterminate
          size="64"
          color="blue-grey"
        >
        </v-progress-circular>
          <h4 class="mt-4 black--text" >Cargando datos... </h4>  
      </v-overlay>
    </div>
</template>

<script>
export default {
    name: 'loadingData',
	props: ['overlay'],
    data() {
        return {
            
        }
    }
}
</script>