<template>
	<div id="StackChartFill">
		<div id="chart">
        	<apexchart type="bar" height="740" width="100%" ref="chart1" :options="chartOptions" :series="series" class="chart-wrapper"></apexchart>
      </div>
	</div>
</template>
<!-- Centrar Chart --->
<style scoped>
div.chart-wrapper {
	display: flex;
	align-items: center;
	justify-content: center
}
</style>
<script>
import axios from 'axios';
const API_URL = 'https://api.datari.net/datari/data/';

export default {
	components: {

	},
	name: 'StackChartFill',
	props: ['data','mes','anio'],
	data: () => ({
		series: [{
	        data: []
	    }, {
	        data: []
	    }],
	   	chartOptions: {
	   		chart: {
            	type: 'bar',
              	stacked: true,
              	
              	toolbar: {
              		show:true
              	}
            },
            plotOptions: {
              	bar: {
              		barHeight: '78%',
                	horizontal: true,
					dataLabels: {
						position: 'top',
					},
             
              	},
            },
            colors: ["#26A69A", "#CCCCCC"],
            
            dataLabels: {
            	enabled: false,
            	enabledOnSeries: [0],
            	offsetX:65,
            	style:{
            		colors:["000"],
            		fontSize: '11px'
            	},
            	formatter: function(val,w){
            		var index = w['dataPointIndex'];
            		var data = w['w'].globals.series
            		var totalData = data[0][index] + data[1][index]
            		var porcentaje = parseFloat(((val / totalData)*100).toFixed(2));
            		if(porcentaje != 0.00){
								return porcentaje+'%';
							}else{
								return '0%';
							}
            		
            	}

            },
            yaxis:{
            	labels: {
            		maxWidth: 180,
					style: {
						fontSize: '14px',
						fontFamily: 'Helvetica, Arial, sans-serif',
						fontWeight: 400,
					},
					offsetX: 0,

				}
            },
            xaxis: {
            	type: 'category',
              	categories: [],
              	
            },
            tooltip: {
            	enabled:true,
          
            	theme: 'dark',
              	custom :[
              		function({series, seriesIndex, dataPointIndex, w}) {

              			function formatcategory(categoria){
              				var tipo='';
			                if(categoria === 'Metropolitana'){
			                  tipo = 'Región ';
			                }else if(categoria === 'Biobío' || categoria === 'Maule' ){
			                  tipo = 'Región del '
			                }else{
			                  tipo = 'Región de '
			                }
			                return tipo+categoria;
              			}

	              		function formatnumber(numero){
	              			var valor = numero.toLocaleString();
		        			var i;
		        			var stringFormat = ''; 
		        			for(i of valor){
		        				if( i === '.' ){
		        					i = ',';
		        					stringFormat+=i;
		        				}
		        				else if(i ===','){
		        					i = '.';
		        					stringFormat+=i;
		        				}
		        				else{
		        					stringFormat+=i;
		        				}
		        				}

		        				return stringFormat;
	              		}

	              		var index = dataPointIndex;
	              	
	              		var dato1 = series[0][index];
	              		var dato2 = series[1][index] + dato1;
	              		var categoria = w.globals.labels[index];
	              		
	              		var porcentaje = parseFloat(((dato1 / dato2)*100).toFixed(2));
	              		var tool = '<div style="margin: 0 auto;width: 300px; height: 200px;opacity:0.8; background-color: #263238;">' +
	              				'<h2 class="font-weight-light text-left" style="margin-top:10px; margin-left:15px">'+formatcategory(categoria)+'</h4>'+
	              				'<h4 class="font-weight-light text-left" style="margin-top:10px;margin-left:15px">Gasto Acumulado:</h4>'+
	              				'<h3 class="font-weight-light text-left" style="margin-top:2px;margin-left:15px">$'+formatnumber(dato1)+' Millones</h3>'+
	              				'<h4 class="font-weight-light text-left"  style="margin-top:5px;margin-left:15px">Marco Evaluación:</h4>'+
	              				'<h3 class="font-weight-light text-left" style="margin-top:2px;margin-left:15px">$'+formatnumber(dato2)+' Millones</h3>'+
	              				'<h4 class="font-weight-light text-left"  style="margin-top:5px;margin-left:15px">Ejecución Presupuestaria:</h4>'+
	              				'<h3 class="font-weight-light text-left" style="margin-top:2px;margin-left:15px">'+porcentaje+'%</h3>'+

					      		'</div>';
					    return tool;
					},
					function({series, seriesIndex, dataPointIndex, w}) {

						function formatcategory(categoria){
              				var tipo='';
			                if(categoria === 'Metropolitana'){
			                  tipo = 'Región ';
			                }else if(categoria === 'Biobío' || categoria === 'Maule' ){
			                  tipo = 'Región del '
			                }else{
			                  tipo = 'Región de '
			                }
			                return tipo+categoria;
              			}

	              		function formatnumber(numero){
	              			var valor = numero.toLocaleString();
		        			var i;
		        			var stringFormat = ''; 
		        			for(i of valor){
		        				if( i === '.' ){
		        					i = ',';
		        					stringFormat+=i;
		        				}
		        				else if(i ===','){
		        					i = '.';
		        					stringFormat+=i;
		        				}
		        				else{
		        					stringFormat+=i;
		        				}
		        				}

		        				return stringFormat;
	              		}

	              		var index = dataPointIndex;
	              	
	              		var dato1 = series[0][index];
	              		var dato2 = series[1][index] + dato1;
	              		var categoria = w.globals.labels[index];
	              		
	              		var porcentaje = parseFloat(((dato1 / dato2)*100).toFixed(2));
	              		var tool = '<div style="margin: 0 auto;width: 300px; height: 200px;opacity:1; background-color: #263238;">' +
	              				'<h2 class="font-weight-light text-left" style="margin-top:10px; margin-left:15px">'+formatcategory(categoria)+'</h4>'+
	              				'<h4 class="font-weight-light text-left" style="margin-top:10px;margin-left:15px">Gasto Acumulado:</h4>'+
	              				'<h3 class="font-weight-light text-left" style="margin-top:2px;margin-left:15px">$'+formatnumber(dato1)+' Millones</h3>'+
	              				'<h4 class="font-weight-light text-left"  style="margin-top:5px;margin-left:15px">Marco Evaluación:</h4>'+
	              				'<h3 class="font-weight-light text-left" style="margin-top:2px;margin-left:15px">$'+formatnumber(dato2)+' Millones</h3>'+
	              				'<h4 class="font-weight-light text-left"  style="margin-top:5px;margin-left:15px">Ejecución Presupuestaria:</h4>'+
	              				'<h3 class="font-weight-light text-left" style="margin-top:2px;margin-left:15px">'+porcentaje+'%</h3>'+

					      		'</div>';
					    return tool;
					},



              	]

            },
            
            legend: {
              	position: 'bottom',
              	horizontalAlign: 'center',
              	offsetY: 10
            },
						
	        
	    },
	    datos_gasto: null,
	    datos_marco: null,
	    datos_categorias: null

	}),
	mounted(){

		this.datos_gasto = this.crear_data(this.data,1);
		//tipo 2
		this.datos_marco = this.crear_data(this.data,2);
		//tipo 3
		this.datos_categorias = this.crear_data(this.data,3);

		console.log(this.datos_categorias)
	

		

		this.$refs.chart1.updateOptions({
			series : [
				{
				data: this.datos_gasto,
				name: 'Gasto Acumulado '+this.mes+' '+this.anio
			},
			{	
				data: this.datos_marco,
				name: 'Marco Evaluación '+this.mes+' '+this.anio
			}
			],
			xaxis:{
				categories: this.datos_categorias
			},
			title:{
				text: 'Ejecución presupuestaria regional',
				align: 'left',
				style: {
					fontSize:  '22px',
					fontWeight:  'light',
					fontFamily:  undefined,
					color:  '#263238'
				},
				
			},
			subtitle:{
				text:'asda',
				align: 'left',
				style: {
					fontSize:  '18px',
					fontWeight:  'light',
					fontFamily:  undefined,
					color:  '#263238'
				},
				margin:10
      },
		})


	},
	computed: {
		update(){
			return this.data;
		}
	},
	watch: {
		update(){
			this.datos_gasto = this.crear_data(this.data,1);
			//tipo 2
			this.datos_marco = this.crear_data(this.data,2);
			//tipo 3
			this.datos_categorias = this.crear_data(this.data,3);

			console.log(this.datos_categorias)


			this.$refs.chart1.updateOptions({
			series : [
				{
				data: this.datos_gasto,
				name: 'Gasto Acumulado '+this.mes+' '+this.anio
			},
			{	
				data: this.datos_marco,
				name: 'Marco Evaluación '+this.mes+' '+this.anio
			}
			],
			xaxis:{
				categories: this.datos_categorias
			},
			title:{
				text: 'Ejecución presupuestaria regional',
				align: 'left',
				style: {
					fontSize:  '24px',
					fontWeight:  'light',
					fontFamily:  undefined,
					color:  '#263238'
				},
				
			},
			subtitle:{
				text:this.mes+' '+this.anio,
				align: 'left',
				style: {
					fontSize:  '18px',
					fontWeight:  'light',
					fontFamily:  undefined,
					color:  '#263238'
				},
				margin:10
      },
		})
			
		}
	},
	methods: {
		crear_data(data,tipo){
			var dato;
			var lista = [];
			for(dato of data){
				var valor;
				if(tipo == 1){
					valor = dato.gasto_acumulado;
					lista.push(valor);
				}
				else if(tipo == 2){
					valor = dato.marco_evaluacion - dato.gasto_acumulado;
					lista.push(valor);
				}
				else if(tipo == 3){
					valor = dato.region.nombre;
					lista.push(valor);
				}
			}
			return lista;
		},

		cargar_categorias(categorias){

			if(categorias.length < this.chartOptions.xaxis.categories.length){
				var ultimo = this.chartOptions.xaxis.categories.length-1;
				this.chartOptions.xaxis.categories.splice(ultimo,1);
			}	

			var region;
			for(region in categorias){
				if(!this.chartOptions.xaxis.categories.includes(categorias[region])){
					this.chartOptions.xaxis.categories.push(categorias[region])
				}
				
			}

			

			
		},
	}
}

</script>