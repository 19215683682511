<template>
<div id="View6Regional">
  <div id="head">
    <v-app-bar color="light-blue darken-2" class="rounded" flat dark>
        <h2 class="font-weight-medium">{{url_api.nombre_data}}</h2>
   
      <v-spacer/>
        <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
           
            dark
            v-bind="attrs"
            v-on="on"
          >
            mdi-information-outline
          </v-icon>
        </template>
        <span>Fuente: {{url_api.fuente}}</span>
      </v-tooltip>
    </v-app-bar>
  </div>
  <div id="filtros" class="mt-1">
    <v-card flat height="60" dense class="rounded" >
      <v-card-text>
        <v-row style="margin-top:-15px;">
          <v-col cols="2" class="mx-auto">
            <v-select
              v-model="anio"
              :items ="url_api.anios"
              label ="Año"
              item-text="id"
              hide-no-data
              hide-details
              dense
              outlined
              flat
              v-on:change="cambiarAnio"
            ></v-select>
          </v-col>
          <v-col cols="2" class="mx-auto">
            <v-select
              v-model="mes"
              :items ="url_api.meses"
              label ="Mes"
              dense
              outlined
              item-text="nombre"
              item-value="id"
              hide-no-data
              hide-details
              v-on:change="cambiarMes"
              ></v-select>
          </v-col>
          <v-col cols="2" class="mx-auto">
            <v-select
              v-model="tipo_valor"
              :items ="url_api.tipos_valor"
              label ="Tipo"
              item-text="nombre"
              item-value="id"
              hide-no-data
              hide-details
              dense
              outlined
              v-on:change="cambiarTipo"
              ></v-select>
          </v-col>
          <v-col cols="2" class="mx-auto" >
            <v-select
              v-model="tramo"
              :items ="url_api.tramos"
              label ="Tramo (M2)"
              item-text="nombre"
              item-value="id"
              hide-no-data
              hide-details
              dense
              outlined
              v-on:change="cambiarTramo"
              ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
</div>

<div id="body" style="margin-top:-5px;">
<v-row>
  <v-col>
    <v-card flat>
      <v-card-title class="justify-center">
        <h3 class="font-weight-light">
          Total {{tipo_valor}} - {{anio}}
        </h3>
      </v-card-title>
      <v-card-text class="text-center">
        <h2 class="font-weight-medium mt-2" style="display:inline;">{{total}} </h2>
        <h3 class="font-weight-medium mt-2" style="display:inline;">{{unidad}}</h3>
      </v-card-text>
    </v-card>
  </v-col>
  <v-col>
    <v-card flat>
      <v-card-title class="justify-center">
        <h3 class="font-weight-light">
          {{tipo_valor}} Tramo {{tramo}} m2
        </h3>
      </v-card-title>
      <v-card-text class="text-center">
        <h2 class="font-weight-medium mt-2" style="display:inline;">{{valor_tramo}} </h2>
        <h3 class="font-weight-medium mt-2" style="display:inline;">{{unidad}}</h3>
      </v-card-text>
    </v-card>
  </v-col>
  <v-col>
    <v-card flat>
      <v-card-title class="justify-center">
        <div v-if="tipo_valor==='Unidades'">
            <h3 class="font-weight-light">Tramo con más unidades construidas</h3>
        </div>
        <div v-else>
            <h3 class="font-weight-light">Tramo con mayor superficie construida</h3>
        </div>
      </v-card-title>
       <v-card-text class="text-center">
          <h2 class="font-weight-medium mt-2" style="display:inline;"> {{valor_tramo_mayor}}</h2>
          <h3 class="font-weight-medium mt-2" style="display:inline;"> Metros Cuadrados</h3>
      </v-card-text>
    </v-card>
  </v-col>
</v-row>
<v-row style="margin-top:-15px;">
  <v-col cols="6">
    <v-card height="100%" flat>
      
      <v-card-text>
        <LineChart :data="data_grafico_region['datos']" :labels="data_grafico_region.categorias" 
        :serie="serie2" :titulo="'Unidades por tramo - '+nombre_region" :subtitulo="mes+' '+anio" />
      </v-card-text>
    </v-card>
  </v-col>
  <v-col cols="6">
    <v-card height="100%" flat>
      
      <v-card-text>
        <ColumnChart :data="data_grafico_regiones['datos']" :categorias="data_grafico_regiones['categorias']" 
        :nombre_serie="serie" :tipo="2" :colors="colors" :tipo_dato="unidad" :subtitulo="serie" :titulo="'Comparación Regional'" :height="550"/>
      </v-card-text>
    </v-card>
  </v-col>
</v-row>
</div>




</div>
</template>
<script>
import axios from 'axios';
const API_URL = 'https://api.datari.net/datari/data/';
import ColumnChart from '../../components/charts/ColumnChart.vue';
import LineChart from '../../components/charts/Lchart.vue';

export default {
components: {
ColumnChart,
LineChart
},
name: 'View6Regional',
props: ['url_api','region'],
data: () => ({
colors: [
"#000099","#FBC02D","#CC3399",'#003333', '#546E7A', '#FF0033', '#999966', '#669933',
'#0000CC', '#6600CC',"#006633",'#f48024', '#CC0033',"#CCCC00","#66BB6A"
],
data_fuente: [],
data_fuente_region: [],
data_region: [],
data_grafico_region: {},
data_grafico_regiones:{},
mes: null,
anio: null,
tipo_valor: null,
tramo: null,
porcentaje: null,
anios: [],
unidad: null,
total: null,
valor_tramo: null,
valor_tramo_mayor: null,
serie: null,
serie2: null,
tipo_dato: null,
title1:null,
nombre_region:null
}),
created(){
  document.documentElement.style.overflow = 'hidden';
  this.mes = this.url_api.meses[0];
  this.anios = this.url_api.anios.sort((a, b) => b - a);
  this.anio = this.anios[0];
  this.tipo_valor = this.url_api.tipos_valor[0];
  this.tramo = this.url_api.tramos[0];
  this.obtenerDataRegion();  
  this.obtenerDataFuente(this.anio,this.mes);
  this.obtenerDataFuenteRegion(this.anio,this.mes);


},
computed: {
update(){
    return this.region;
}

},
watch: {
update(){
    this.obtenerDataRegion();
    this.obtenerDataFuenteRegion(this.anio,this.mes);
    
}
},
methods: {
obtenerDataFuente(anio, mes){
    axios.get(API_URL + this.url_api.url_api + '?anio='+anio+'&mes='+mes)
    .then(response => {
        this.data_fuente = response.data.results;
        this.data_grafico_regiones = this.obtenerDataRegiones_grafico(this.data_fuente,this.tramo);
        if(this.tipo_valor === 'Unidades'){
          this.serie = 'Cantidad unidades tramo '+this.tramo+ ' m2';
          this.tipo_dato = 'Unidades';
        }
        else{
          this.serie = 'Superficie construida tramo '+this.tramo + ' m2';
          this.tipo_dato = 'Metros Cuadrados';

        }
    }); 
},

obtenerValorTramoMayorUnidades(data){
    var lista_unidades = [data.u_1_35,data.u_36_50, data.u_51_70, data.u_71_100, data.u_101_140, data.u_140_mas];
    var maximo = Math.max(...lista_unidades);
    var index_tramo = lista_unidades.indexOf(maximo);
    
    var tramo;
    if(index_tramo == 0){
        tramo = '1-35';
    }else if(index_tramo == 1){
        tramo ='36-50';
    }else if(index_tramo == 2){
        tramo  = '51-70';
    }else if(index_tramo == 3){
        tramo = '71-100';
    }
    else if(index_tramo == 4){
        tramo = '101-140';
    }
    else if(index_tramo == 5){
        tramo = '140+';
    }

    return tramo;
},

obtenerValorTramoMayorSuperficie(data){
    var lista_superficies = [data.s_1_35,data.s_36_50, data.s_51_70, data.s_71_100, data.s_101_140, data.s_140_mas];
    var maximo = Math.max(...lista_superficies);
    var index_tramo = lista_superficies.indexOf(maximo);
    
    var tramo;
    if(index_tramo == 0){
        tramo = '1-35';
    }else if(index_tramo == 1){
        tramo ='36-50';
    }else if(index_tramo == 2){
        tramo  = '51-70';
    }else if(index_tramo == 3){
        tramo = '71-100';
    }
    else if(index_tramo == 4){
        tramo = '101-140';
    }
    else if(index_tramo == 5){
        tramo = '140+';
    }

    return tramo;
},

obtenerValorTramoUnidades(data,tramo){
    var valor;

    if(tramo === '1-35'){
        valor = data.u_1_35;
    }
    else if(tramo === '36-50'){
        valor = data.u_36_50;
    }
    else if(tramo === '51-70'){
        valor = data.u_51_70;
    }
    else if(tramo === '71-100'){
        valor = data.u_71_100;
    }
    else if(tramo === '101-140'){
        valor = data.u_101_140;
    }
    else{   
        valor = data.u_140_mas;

    }

    if(String(valor).length >= 4){
        return valor.toLocaleString().replace(',','.')
    }
    else{
        return valor
    }
},

obtenerValorTramoSuperficie(data,tramo){
    var valor;
    if(tramo === '1-35'){
        valor = data.s_1_35;
    }
    else if(tramo === '36-50'){
        valor = data.s_36_50;
    }
    else if(tramo === '51-70'){
        valor = data.s_51_70;
    }
    else if(tramo === '71-100'){
        valor = data.s_71_100;
    }
    else if(tramo === '101-140'){
        valor = data.s_101_140;
    }
    else{   
        valor = data.s_140_mas;
    }

    if(String(valor).length >= 4){
        return valor.toLocaleString().replace(',','.');
    }
    else{
        return valor;
    }      
},

obtenerDataRegion_Grafico(data){
  var lista = [];
  var categorias = ['1-35','36-50','51-70','71-100','101-140','140+'];
  var dato;
  for(dato of data){
    if(this.tipo_valor === 'Unidades'){
      lista.push(dato.u_1_35);
      lista.push(dato.u_36_50);
      lista.push(dato.u_51_70);
      lista.push(dato.u_71_100);
      lista.push(dato.u_101_140);
      lista.push(dato.u_140_mas);
    }
    else if(this.tipo_valor === 'Superficie'){
      lista.push(dato.s_1_35);
      lista.push(dato.s_36_50);
      lista.push(dato.s_51_70);
      lista.push(dato.s_71_100);
      lista.push(dato.s_101_140);
      lista.push(dato.s_140_mas);
    }
  }

  return {'datos':lista,'categorias':categorias};
},

obtenerDataRegiones_grafico(data,tramo){
  var categorias = [];
  var lista = [];
  var dato;
  for(dato of data){
    if(tramo === '1-35'){
      if(this.tipo_valor === 'Unidades'){
        lista.push(dato.u_1_35);
      }else{
        lista.push(dato.s_1_35);
      }
      
    }
    else if(tramo === '36-50'){
      if(this.tipo_valor === 'Unidades'){
        lista.push(dato.u_36_50);
      }else{
        lista.push(dato.s_36_50);
      }
      
    }
    else if(tramo === '51-70'){
      if(this.tipo_valor === 'Unidades'){
        lista.push(dato.u_51_70);
      }else{
        lista.push(dato.s_51_70);
      }
      
    }
    else if(tramo === '71-100'){
      if(this.tipo_valor === 'Unidades'){
        lista.push(dato.u_71_100);
      }else{
        lista.push(dato.s_71_100);
      }
      
    }
    else if(tramo === '101-140'){
      if(this.tipo_valor === 'Unidades'){
        lista.push(dato.u_101_140);
      }else{
        lista.push(dato.s_101_140);
      }
      
    }
    else{  
    if(this.tipo_valor === 'Unidades'){
        lista.push(dato.u_140_mas);
      }else{
        lista.push(dato.s_140_mas);
      } 
    }
    if(dato.region.nombre === 'Arica y Parinacota'){
      let temp = ['Arica y ','Parinacota'];
      categorias.push(temp);
    }
    else{
      categorias.push(dato.region.nombre);
    }
    
    
  }

  return {'datos': lista,'categorias': categorias};

},


obtenerDataFuenteRegion(anio,mes){
    axios.get(API_URL + this.url_api.url_api + '?anio='+anio+'&mes='+mes+'&region='+this.region)
    .then(response => {
        this.data_fuente_region = response.data.results;
        if(this.tipo_valor === 'Unidades'){
            var total = this.data_fuente_region[0].u_total;
            if(String(total).length >= 4){
                    total = total.toLocaleString().replace(',','.');
                    
            }
            this.total = total;
            this.unidad = 'unidades';
            this.valor_tramo = this.obtenerValorTramoUnidades(this.data_fuente_region[0],this.tramo);
            this.valor_tramo_mayor = this.obtenerValorTramoMayorUnidades(this.data_fuente_region[0]);
            this.serie2 = "Cantidad de unidades";

        }
        else if(this.tipo_valor === 'Superficie'){
            var total2 = this.data_fuente_region[0].s_total;
            if(String(total2).length >= 4){
                    total2 = total2.toLocaleString().replace(',','.');
                    
            }
            this.total = total2;
            this.unidad = 'metros cuadrados';
            this.valor_tramo = this.obtenerValorTramoSuperficie(this.data_fuente_region[0],this.tramo);
            this.valor_tramo_mayor = this.obtenerValorTramoMayorSuperficie(this.data_fuente_region[0]);
            this.serie2 = "Superficie construida (m2)";

        }
        this.nombre_region = this.data_fuente_region[0].region.nombre;
        this.data_grafico_region = this.obtenerDataRegion_Grafico(this.data_fuente_region);


        
        
        
    });
},

obtenerDataRegion(){
    axios.get(API_URL+'regiones/'+this.region)
    .then(response =>{
        this.data_region = response.data;
          
        });
},

cambiarAnio(anio){
    console.log(anio);
    this.anio = anio;
    this.mes = this.url_api.meses[0];
    this.obtenerDataFuente(this.anio,this.mes);
    this.obtenerDataFuenteRegion(this.anio,this.mes);
},

cambiarMes(mes){
    this.mes = mes;
    this.obtenerDataFuente(this.anio,this.mes);
    this.obtenerDataFuenteRegion(this.anio,this.mes);
},

cambiarTipo(tipo){
    this.tipo_valor = tipo;
    if(this.tipo_valor === 'Unidades'){
            this.unidad = 'unidades';
            this.valor_tramo = this.obtenerValorTramoUnidades(this.data_fuente_region[0],this.tramo);
            this.serie = 'Cantidad unidades tramo '+this.tramo+ ' m2';
            this.serie2 = "Cantidad de unidades";
            var total = this.data_fuente_region[0].u_total;
            if(String(total).length >= 4){
                    total = total.toLocaleString().replace(',','.');
                    
            }
            this.total = total;
        }
        else if(this.tipo_valor === 'Superficie'){
            var total2 = this.data_fuente_region[0].s_total;
            if(String(total2).length >= 4){
                    total2 = total2.toLocaleString().replace(',','.');
                    
            }
            this.total = total2;
            this.unidad = 'metros cuadrados';
            this.valor_tramo = this.obtenerValorTramoSuperficie(this.data_fuente_region[0],this.tramo);
            this.serie = 'Superficie construida tramo ' +this.tramo + ' m2';
            this.serie2 = "Superficie construida (m2)";

    }
    this.data_grafico_region = this.obtenerDataRegion_Grafico(this.data_fuente_region);
    this.data_grafico_regiones = this.obtenerDataRegiones_grafico(this.data_fuente,this.tramo);


},
cambiarTramo(tramo){
    this.tramo = tramo
    if(this.tipo_valor === 'Unidades'){
        this.valor_tramo = this.obtenerValorTramoUnidades(this.data_fuente_region[0],this.tramo);
        this.serie = 'Cantidad unidades tramo '+this.tramo+ ' m2';

    }else if(this.tipo_valor === 'Superficie'){
        this.valor_tramo = this.obtenerValorTramoSuperficie(this.data_fuente_region[0],this.tramo);
        this.serie = 'Superficie construida tramo '+this.tramo + ' m2';
    }
    this.data_grafico_regiones = this.obtenerDataRegiones_grafico(this.data_fuente,this.tramo);
}

}
}

</script>