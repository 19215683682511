<template>
	<div id="View1">
		<div id="header">
		  <v-app-bar color="light-blue darken-2" class="rounded" flat dark>
        <h2 class="font-weight-medium">{{url_api.nombre_data}}</h2>
        <v-col cols=2>
          <v-select
            v-model="anio"
            :items ="url_api.anios"
            label ="Año"
            hide-no-data
            hide-details
            class="mx-2"
            flat
            solo-inverted
            dense
            v-on:change="cambiarAnio"
            >
          </v-select>
        </v-col>
        <v-spacer/>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
           
              dark
              v-bind="attrs"
              v-on="on"
            >
              mdi-information-outline
            </v-icon>
          </template>
          <span>Fuente: {{url_api.fuente}}</span>
        </v-tooltip>
      </v-app-bar>
		</div>
    <div id="body" style="margin-top:-5px;">
      <v-row>
        <v-col cols=4>
          <v-card flat >
            <v-card-title class="justify-center">
              <h3 class="font-weight-medium">{{nombre_region}}</h3>
            </v-card-title>
            <v-card-text class="text-center" style="margin-top: -5px;">
<!--               <h3 class="font-weight-medium"> {{anio}}</h3>
 -->              <h1 class="font-weight-medium mt-2">
                {{total_region}}
              </h1>
              <h2 class="font-weight-light mt-2">
                Vehículos en circulación
              </h2>
            </v-card-text>
          </v-card>
          <v-card flat class="mt-2">
            <v-card-title class="justify-center">
              <h3 class="font-weight-medium">{{nombre_comuna}}</h3>
            </v-card-title>
            <v-card-text class="text-center" style="margin-top: -5px;">
<!--                <h3 class="font-weight-medium"> {{anio}}</h3>
 -->              <h1 class="font-weight-medium mt-2">
                {{total_comuna}}
              </h1>
              <h2 class="font-weight-light mt-2">
                Vehículos en circulación
              </h2>
              <v-col cols="8" class="mx-auto mt-2">
                <h2 class="font-weight-medium">Porcentaje a nivel regional</h2>
              <v-progress-linear v-model="porcentaje" height="25" color="blue-grey" class="mt-3">
                <strong>{{porcentaje}}%</strong>
              </v-progress-linear>
            </v-col>
            </v-card-text>
          </v-card>
            <v-card flat class="mt-2">
          <v-card-text>
             <AreaChart :data="data_graph2" :labels="labels_graph2" :colors="colors_graph2"
            :tipo_data="'sad'" :nombre_serie="'Vehículos en circulación'" :titulo="'Vehículos en circulación - '+nombre_comuna" 
            :subtitulo="'Variación Anual 2008-2019'" :height="355" :tipo="0" />
          </v-card-text>
        </v-card>
        </v-col>
        <v-col cols=8>
          <v-card flat height=780>
          <!--   <v-card-title class="justify-center">
              <h3 class="font-weight-light">
                Distribución de permisos según comuna
              </h3>
            </v-card-title> -->
            <v-card-text  class="text-center">
              <Sankey style="margin-top: -10px;"
              :nodes="nodes" :links="links"
              />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
	</div>
</template>

<script>
import axios from 'axios';
const API_URL = 'https://api.datari.net/datari/data/';
import MultiColumnChart from '../../components/chartsV2/MultiColumnChart.vue';
import ArcPie from '../../components/chartsV2/ArcPie.vue';
import Sankey from '../../components/chartsV2/SankeyComuna.vue';
import AreaChart from '../../components/chartsV2/AreaChart';



export default {

  name: 'View1Comuna',
  props: [
    'url_api','comuna','region'
  ],
  components: {
    MultiColumnChart,
    ArcPie,
    Sankey,
    AreaChart
  },

  data () {
    return {
      anio: this.url_api.anios[0],
      total_region:0,
      nombre_region:null,
      total_comuna:0,
      nombre_comuna:null,
      total_pais: 0,
      data_graph2:[],
      labels_graph2:[],
      colors_graph2:[],
      nodes: [],
      links: [],
      porcentaje:0
    }
  },
  mounted(){
    document.documentElement.style.overflow = 'hidden';
    this.get_data_comuna_anio();
    this.get_data_comuna();
    this.get_data_region();

  },
  computed: {
    updateComuna(){
      return this.comuna;
    }
  },  
  watch: {
    updateComuna(){

      this.get_data_comuna_anio();
      this.get_data_comuna();
      this.get_data_region();

    }
  },
  methods: {



    async get_data_comuna_anio(){
      let response = await axios.get(
        API_URL+this.url_api.url_apis[1]+'?comuna='+this.comuna+'&anio_data='+this.anio
      );

      let data = response.data.results[0];
      console.log(data)
      this.total_region = this.formatCL(data.total_region);
      this.total_comuna = this.formatCL(data.total);
      this.porcentaje = ((data.total / data.total_region)*100).toFixed(2);
      this.nombre_region = data.region.nombre;
      this.nombre_comuna = data.comuna.nombre;
    },

    async get_data_comuna(){
      let response = await axios.get(
        API_URL+this.url_api.url_apis[1]+'?comuna='+this.comuna
      );

      let data = response.data.results;
      let data_grafico = this.getGraphicData(data,3);
      this.data_graph2 = data_grafico.series;
      this.labels_graph2 = data_grafico.categorias;
      this.colors_graph2 = data_grafico.colors;
      console.log(data_grafico)
    },


    async get_data_region(){
   
      let response = await axios.get(
        API_URL+this.url_api.url_apis[1]+'?anio_data='+this.anio+'&region='+this.region+'&comuna='+this.comuna
      );

      let data = response.data;
      
      
      this.nodes = data.nodes_sankey;
      this.links = data.links_sankey;
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    formatCL(val){
      var valor = val.toLocaleString();
      var largo_string = valor.length
      var i;
      var stringFormat = ''; 
      for(i of valor){
        if( i === '.' ){
          i = ',';
          stringFormat+=i;
        }
        else if(i ===','){
          i = '.';
          stringFormat+=i;
        }
        else{
          stringFormat+=i;
        }
      }
      return stringFormat;
    },

    getGraphicData(data,labelType){
      // Tipo 1 -> Años, Tipo 2 -> Regiones
      var element;
      var labels = [];
      var series = [];
      var series2= [];
      var colors = [];

      //Extraer data de una región
      if(labelType == 1){

        labels = ['Bencina','Diesel','Gas','Eléctrico'];
        series = [data.bencina,data.diesel,data.gas,data.electrico];
        colors = ["#689F38",'#263238','#FBC02D','#304FFE'];
      }

      else if(labelType == 2){
        for(element of data){
          let catalitico = element.catalitico;
          let no_catalitico = element.no_catalitico;
          let anio = element.anio_data;
          series.push(catalitico);
          series2.push(no_catalitico);
          labels.push(anio);
        }

      }

      else if(labelType == 3){
        for(element of data){
          let anio = element.anio_data;
          let valor = element.total;

          series.push(valor);
          labels.push(anio);
          colors = ['#2980B9'];
        }


      }

    
      return {
              'categorias': labels,
              'series': series,
              'colors': colors,
              'series2':series2
             }
    },



    cambiarAnio(anio){
      this.anio = anio;
      this.get_data_comuna_anio();
      this.get_data_region();

    }
  }
}
</script>

<style lang="css" scoped>
</style>