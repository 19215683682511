<template>
  <div>
    <div id="chart">
      <apexchart type="bar" height="300" ref="chart1" width="100%" :options="chartOptions" :series="series" class="chart-wrapper"></apexchart>
    </div>
  </div>
</template>

<script>
export default {

  name: 'BarChart',
  props: [
    'data','labels','titulo','tipo_data','nombre_serie','subtitulo'
  ],

  data () {
    return {
      series: [{
        data: []
      }],
      chartOptions: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            borderRadius: 3,
            horizontal: true,
            distributed: false,
            dataLabels: {
              position: 'top'
            }
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: [],
          max:100,
          
        },
        yaxis:{
          labels:{
            show:true,
            style:{
              colors: [],
              fontSize: '14px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 500,
              cssClass: 'apexcharts-xaxis-label',
            }
          }
        }
      },
    }
  },
  computed: {
    updateData(){
      return this.data;
    }
  },
  watch: {
    updateData(){
      this.$refs.chart1.updateOptions({
        series: [{
          name:this.nombre_serie,
          data:this.data
        }],
        xaxis:{
          categories: this.labels,
          max:100,

          
        },
        title: {
          text: this.titulo,
          align: 'left',
          style: {
            fontSize:  '22px',
            fontWeight:  'light',
            fontFamily:  undefined,
            color:  '#263238'
          },
          margin:-15,
          offsetY:5
        },
        colors:[
          '#DD2C00'
        ],
        subtitle: {
          text:this.subtitulo,
          align: 'left',
          style: {
            fontSize:  '16px',
            fontWeight:  'light',
            fontFamily:  undefined,
            color:  '#263238'
          },
        },
        legend: {
          show:false
        },
        dataLabels: {
          enabled: true,
          offsetY: 0,
          offsetX: 50,
          style: {
            fontSize: '16px',
            colors: ["#304758"]
          },
          formatter: function (val, opts) {
              return val+'%'
          },
        },
        tooltip:{
          theme:'dark',
           y: {
            formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
              return value+'%'
            }
          },
          style: {
            fontSize: '15px',
            fontFamily: undefined
          },
        }
      });
    }
  }
}
</script>

<style scoped>
div.chart-wrapper {
  display: flex;
  align-items: center;
  justify-content: center
}
</style>