<template>
	<div id="View7Region">
    <div id="header">
      <v-app-bar color="light-blue darken-2" class="rounded" flat dark>
          <h2 class="font-weight-medium">{{url_api.nombre_data}}</h2>
   
        <v-col cols="2">
          <v-select
            v-model="anio"
            :items ="url_api.anios"
            label ="Año"
            item-text="id"
            flat
            solo-inverted
            dense
            hide-no-data
            class="mx-2"
            hide-details
            v-on:change="cambiarAnio">
          </v-select>
        </v-col>
        <v-spacer/>
        <v-tooltip left>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
           
            dark
            v-bind="attrs"
            v-on="on"
          >
            mdi-information-outline
          </v-icon>
        </template>
        <span>Fuente: {{url_api.fuente}}</span>
      </v-tooltip>
      </v-app-bar>
    </div>
    <div id="body" style="margin-top:-5px;">
      <v-row>
        <v-col cols="4">
          <v-card height="125" flat>
            <v-card-title class="justify-center">
              <h2 class="font-weight-medium"><small>País</small></h2>
            </v-card-title>
            <v-card-text class="text-center">
              <h2 style="display:inline" class="display-1">{{superficie_pais}}</h2>
              <h3 style="display:inline" class="mt-2 ml-2">{{tipo_dato}}</h3>
            </v-card-text>
          </v-card>
          <v-card flat height="125" class="mt-2">
            <v-card-title class="justify-center">
              <h2 class="font-weight-medium"><small>{{nombre_region}}</small></h2>
            </v-card-title>
            <v-card-text class="text-center">
              <h2 style="display:inline" class="display-1">{{superficie_region}}</h2>
              <h3 style="display:inline" class="mt-2 ml-2">{{tipo_dato}}</h3>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="8">
          <v-card flat>
            <v-card-text>
              <lineChart :data="graph1Data" :labels="graph1Labels"
              :serie="'Superficie :'"
              :titulo="'Variación Anual'"
              :height="210"
              :subtitulo="url_api.anios[url_api.anios.length-1]+'-'+url_api.anios[0]" />
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row style="margin-top:-15px;">
        <v-col>
          <v-card flat height=530>
            <v-card-text>
              <TreeMap :data="graph2Data" :labels="graph2Labels" :nombre_serie="'Superficie'"
              :tipo_dato="'Hectareas'" :colors="graph2Colors"/>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
	</div>
</template>

<script>
import axios from 'axios';
const API_URL = 'https://api.datari.net/datari/data/';
import lineChart from '../../components/chartsV2/LineChart2.vue';
import TreeMap from '../../components/charts/TreeMapRegion.vue';

export default {
  components: {
    lineChart,
    TreeMap
  },
  name: 'View7Region',
  props: ['url_api','region'],
  data: () => ({
    anio: null,
    superficie_region: null,
    superficie_pais: null,
    participacion_region: null,
    tipo_dato: null,
    nombre_region: null,
    data_anual: [],
    data_region: [],
    graph1Labels: [],
    graph1Data: [],
    graph2Colors:[],
    graph2Labels:[],
    graph2Data: [],
    data_fuente: [],
    loading: true

  }),
  created(){
    document.documentElement.style.overflow = 'hidden';
    this.anio = this.url_api.anios[0];
    this.crearDataRegion();
    this.crearDataAnual();
    this.sleep(1500).then(() => {this.loading=false;});
  },
  computed: {
    updateItem(){
      return this.region,this.url_api
    }
  },
  watch: {
    updateItem(){
      this.loading = true;
      this.crearDataRegion();
      this.crearDataAnual();
       this.sleep(1500).then(() => {this.loading=false;});
      
    }
  },
  methods: {
    cambiarAnio(anio){
      this.anio = anio;
      this.crearDataRegion();
      this.crearDataAnual();
    },

    sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    },

    formatCL(val){
      var valor = val.toLocaleString();
      var largo_string = valor.length
      var i;
      var stringFormat = ''; 
      for(i of valor){
        if( i === '.' ){
          i = ',';
          stringFormat+=i;
        }
        else if(i ===','){
          i = '.';
          stringFormat+=i;
        }
        else{
          stringFormat+=i;
        }
      }
      return stringFormat;
    },

    getTipoRegion(region){
      let result;
      if(region === 'Metropolitana'){
        result = 'Región '+region;
      }
      else if(region === 'Biobío' || region === 'Ñuble' || region == 'Maule'){
        result = 'Región del '+region;
      }
      else{
        result = 'Región de '+region;
      }
      return result;
    },

    crearDataRegion(){
      axios.get(API_URL+this.url_api.url_api+'?region='+this.region)
      .then(response => {
        if(response.data.count != 0){
          this.data_region = response.data.results;
          this.graph1Labels = this.getGraphicData(this.data_region,1)['categorias'];
          this.graph1Data = this.getGraphicData(this.data_region,1)['series'];
        }
        else{
          this.superficie_region = 0;
          
        }
        
      });

      axios.get(API_URL+this.url_api.url_api+'?region='+this.region+'&anio='+this.anio)
      .then(response => {
        if(response.data.count != 0){
          this.data_fuente = 1;
          this.superficie_region = this.formatCL(response.data.results[0].superficie);
          this.participacion_region = response.data.results[0].participacion;
          this.tipo_dato = response.data.results[0].tipo_dato;
          this.nombre_region = this.getTipoRegion(response.data.results[0].region.nombre);
          this.superficie_pais = this.formatCL(response.data.totalAnual);
        }
        else{
          this.data_fuente = 0;
          
        }
        
      });
      
    }, 

    getGraphicData(data,labelType){
      // Tipo 1 -> Años, Tipo 2 -> Regiones
      var element;
      var labels = [];
      var series = [];
      var colors =[];
     

      if(labelType == 1){
        for(element of data){
          if(!labels.includes(element.anio)){
            labels.push(element.anio);
          }
          series.push(element.superficie);
        }
      }
      else if(labelType == 2){
        for(element of data){
          if(!labels.includes(element.region.nombre)){
            labels.push(element.region.nombre);
            colors.push(element.region.color);
            
          } 

          series.push(element.superficie);
        }
      }
      return {
              'categorias': labels,
              'series': series,
              'colors': colors
             }
    },

    crearDataAnual(){
  
      // Data Anual para todas las regiones
      axios.get(API_URL+this.url_api.url_api+'?anio='+this.anio)
      .then(response => {
        this.data_anual = response.data.results;
        this.graph2Data = this.getGraphicData(this.data_anual,2)['series'];
        this.graph2Labels = this.getGraphicData(this.data_anual,2)['categorias'];
        this.graph2Colors = this.getGraphicData(this.data_anual,2)['colors'];
      });

      
    }
  }
}
	
</script>